import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getNotifications = async (data) => {
  const response = await $authApi.get(apiRoutes.NOTIFICATIONS, {
    params: data,
  });
  return response?.data;
};

const getNotification = async (id) => {
  const response = await $authApi.get(apiRoutes.NOTIFICATION, {
    params: {
      id,
    },
  });

  return response?.data;
};

const createNotification = async (data) => {
  const response = await $authApi.post(apiRoutes.NOTIFICATIONS, data);
  return response?.data;
};

const editNotification = async (data) => {
  const response = await $authApi.put(apiRoutes.NOTIFICATIONS, data);
  return response?.data;
};

const deleteNotification = async (ids) => {
  const response = await $authApi.delete(apiRoutes.NOTIFICATIONS, {
    data: { ids },
  });
  return response?.data;
};

export {
  getNotifications,
  getNotification,
  createNotification,
  editNotification,
  deleteNotification,
};
