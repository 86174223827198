import { useTranslation } from "react-i18next";
const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="text-center fs-08 text-muted mt-4">
      ©{" "}
      <a href="https://plaix.ru" target="_blank">
        Plaix
      </a>{" "}
      2023г. {t("Все права защищены")}.
    </div>
  );
};

export default Footer;
