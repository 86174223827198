import React, {
  createRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Badge, Col, Row } from "react-bootstrap";
import {
  IoAdd,
  IoCloseOutline,
  IoCreateOutline,
  IoSearchOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import CustomModal from "../../components/utils/CustomModal";
import { deletePromo, getPromos } from "../../services/promo";

const Promos = () => {
  const brand = useSelector((state) => state.brand.active);
  const [searchParams, setSearchParams] = useSearchParams();
  const inputRef = createRef();
  const [promos, setPromos] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });

  const сolumns = [
    {
      name: "Название",
      selector: "title",
    },
    {
      name: "Описание",
      selector: "desc",
    },
    {
      width: "100px",
      name: "Статус",
      selector: "status",
      cell: (row) =>
        row.status === 0 ? (
          <Badge bg="secondary">Архив</Badge>
        ) : (
          <Badge bg="success">Активно</Badge>
        ),
    },
    {
      width: "100px",
      selector: "action",
      align: "right",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <Link to={"/sales/promo/" + row.id} className="me-3">
            <IoCreateOutline size={22} />
          </Link>
          <a
            onClick={() =>
              setModalDelete({ show: !modalDelete.show, id: row.id })
            }
            className="me-2"
          >
            <IoTrashOutline size={20} className="text-danger" />
          </a>
        </div>
      ),
    },
  ];

  const getData = useCallback(async () => {
    getPromos(searchParams)
      .then(
        (res) =>
          res &&
          setPromos((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }))
      )
      .finally(() => setPromos((prev) => ({ ...prev, loading: false })));
  }, [searchParams, brand]);

  const onDelete = useCallback((id) => {
    deletePromo(id)
      .then(() => {
        getData();
        NotificationManager.success("Промокод успешно удален");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, []);

  const onSearch = useCallback(() => {
    getData();
  }, [searchParams]);

  const header = useMemo(() => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h5 className="fw-7">
              {selected.length > 0 ? `Выбрано ${selected.length}` : "Промокоды"}
            </h5>
          </div>
          <div className="d-flex align-items-center">
            <Link
              to="/sales/promos/create"
              className="btn-primary-outline me-3"
            >
              <IoAdd size={18} />
            </Link>
            <Button
              disabled={selected.length === 0}
              className="btn-light"
              onClick={() => setModalDelete({ show: true, id: false })}
            >
              <IoTrashOutline size={18} />
            </Button>
          </div>
        </div>
        <div>
          <Row>
            <Col md={12}>
              <Input
                ref={inputRef}
                placeholder="Найти"
                className="w-100"
                onChange={(e) => {
                  searchParams.set("text", e);
                  setSearchParams(searchParams);
                }}
                rightIcon={() => <IoSearchOutline size={22} />}
                defaultValue={searchParams.get("text")}
                rightIconClick={() => onSearch()}
                onKeyDown={(e) => e === "Enter" && onSearch()}
              />
              {searchParams.get("text")?.length > 0 && (
                <Button
                  className="btn-light ms-3"
                  onClick={() => {
                    searchParams.delete("text");
                    setSearchParams(searchParams);
                    onSearch();
                    if (inputRef.current) {
                      inputRef.current.value = "";
                    }
                  }}
                >
                  <IoCloseOutline size={22} />
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  }, [selected, searchParams, modalDelete]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page"), brand]);

  const onDeleteSelected = useCallback(() => {
    deletePromo(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        getData();
        NotificationManager.success("Выбранные пользователи успешно удалены");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, [selected]);

  if (promos.loading) {
    return <Loader full />;
  }

  return (
    <>
      <Meta title="Промокоды" />
      <DataTable
        columns={сolumns}
        onChange={(items) => setSelected(items)}
        data={promos.items}
        header={header}
        selectable
        pagination={promos.pagination}
      />
      <CustomModal
        title={`Удаление ${modalDelete.id ? "#" + modalDelete.id : ""}`}
        show={modalDelete.show}
        setShow={(e) => setModalDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={(e) =>
                setModalDelete({ show: !modalDelete.show, id: false })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              onClick={() =>
                selected.length > 0
                  ? onDeleteSelected()
                  : modalDelete.id && onDelete(modalDelete.id)
              }
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить пользователя?
      </CustomModal>
    </>
  );
};

export default Promos;
