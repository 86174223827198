import React, { useCallback, useLayoutEffect, useState } from "react";
import { Badge, Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import {
  IoChevronBackOutline,
  IoCreateOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useParams } from "react-router-dom";
import ImageCropper from "../../components/Cropper";
import DragDropFile from "../../components/DragDropFile";
import Meta from "../../components/Meta";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import Textarea from "../../components/UI/Textarea";
import { getImageURL } from "../../helpers/image";
import { editParam, getParam, createParamChild } from "../../services/param";

const EditParam = () => {
  const navigate = useNavigate();
  const { paramId } = useParams();
  const [loading, setLoading] = useState(true);
  const [editImageParam, setEditImageParam] = useState({
    show: false,
    data: [],
  });

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const {
    control: controlChild,
    register: registerChild,
    formState: { errors: errorsChild, isValid: isValidChild },
    handleSubmit: handleSubmitChild,
    reset: resetChild,
    setValue: setValueChild,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {
      id: paramId,
      status: true,
    },
  });

  const data = useWatch({ control });
  const dataChild = useWatch({ control: controlChild });

  const getPage = useCallback(() => {
    getParam(paramId)
      .then((res) => {
        reset(res);
        resetChild({ id: res.id, status: true, value: null, priority: null });
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [paramId]);

  useLayoutEffect(() => {
    getPage();
  }, []);

  const onSubmit = useCallback((data) => {
    editParam(data)
      .then(() => {
        NotificationManager.success("Каталог успешно обновлен");
        navigate(-1);
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      });
  }, []);

  const onSubmitChild = useCallback((data) => {
    createParamChild(data)
      .then(() => {
        NotificationManager.success("Опция успешно добавлена");
        getPage();
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      });
  }, []);

  if (loading) {
    return <Loader full />;
  }

  return (
    <>
      <Meta title="Создать параметр" />
      <div>
        <Link
          to="/catalog/params"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h3>Редактировать параметр</h3>
        <Button disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить
        </Button>
      </div>
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <DragDropFile
                file={data.file}
                media={getImageURL({
                  path: data.media,
                  size: "full",
                  type: "param",
                })}
                onChange={(e) =>
                  setEditImageParam((info) => ({
                    show: !info.show,
                    data: e,
                  }))
                }
              />
            </Col>
            <Col md={8}>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Input
                      defaultValue={data.title}
                      label="Название"
                      name="title"
                      errors={errors}
                      register={register}
                      validation={{
                        required: "Обязательное поле",
                      }}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <Textarea
                      defaultValue={data.desc ?? ""}
                      label="Описание"
                      name="desc"
                      rows={4}
                      onChange={(e) => setValue("desc", e)}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Select
                      label="Тип"
                      value={data.type ?? "checkbox"}
                      data={[
                        { title: "Флажок", value: "checkbox" },
                        { title: "Выпадающий список", value: "select" },
                        { title: "Переключатель", value: "radio" },
                        { title: "Тег", value: "tag" },
                      ]}
                      onClick={(e) => setValue("type", e.value)}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Input
                      defaultValue={data.priority}
                      label="Порядок"
                      name="priority"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <Form.Check className="my-2">
                    <Form.Check.Input
                      type="checkbox"
                      name="status"
                      id="status"
                      defaultChecked={data.status}
                      {...register("status")}
                    />
                    <Form.Check.Label htmlFor="status" className="ms-2">
                      Показать
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <ImageCropper
        file={editImageParam.data[0]}
        show={editImageParam.show}
        setShow={(e) => setEditImageParam((info) => ({ ...info, show: e }))}
        onComplete={(e) => {
          e && setValue("file", e.file);
          e && setValue("media", e.blob);
        }}
      />
      {data?.params?.length > 0 &&
        data.params.map((e) => (
          <Card className="mt-3">
            <Card.Body className="d-flex flex-row justify-content-between align-items-center">
              <div>
                {e.priority ? (
                  <span className="text-muted me-3">{e.priority}</span>
                ) : null}
                {e.value}
              </div>
              <div className="d-flex flex-row justify-content-between align-items-center">
                <a className="me-3">
                  {e.status ? (
                    <Badge bg="success">Активно</Badge>
                  ) : (
                    <Badge bg="secondary">Архив</Badge>
                  )}
                </a>
                <a className="me-3">
                  <IoCreateOutline size={20} />
                </a>
                <a>
                  <IoTrashOutline size={20} className="text-danger" />
                </a>
              </div>
            </Card.Body>
          </Card>
        ))}
      <Card className="mt-3">
        <Card.Body>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Input
                  label="Значение"
                  name="value"
                  value={dataChild.value}
                  errors={errorsChild}
                  register={registerChild}
                  validation={{
                    required: "Обязательное поле",
                  }}
                />
              </div>
            </Col>
            <Col md={2}>
              <div className="mb-3">
                <Input
                  label="Порядок"
                  name="priority"
                  value={dataChild.priority}
                  errors={errorsChild}
                  register={registerChild}
                />
              </div>
            </Col>
            <Col md={2}>
              <Form.Check className="my-2">
                <Form.Check.Input
                  type="checkbox"
                  name="status"
                  id="status-child"
                  value={dataChild.status}
                  defaultChecked={true}
                  {...registerChild("status")}
                />
                <Form.Check.Label htmlFor="status-child" className="ms-2">
                  Показать
                </Form.Check.Label>
              </Form.Check>
            </Col>
            <Col md={2}>
              <Button
                disabled={!isValidChild}
                className="w-100"
                onClick={handleSubmitChild(onSubmitChild)}
              >
                Добавить
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default EditParam;
