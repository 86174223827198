import React, { useCallback } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate } from "react-router-dom";
import DragDropFile from "../../components/DragDropFile";
import Meta from "../../components/Meta";
import Input from "../../components/UI/Input";
import { createStory } from "../../services/story";

const StoryCreate = () => {
  const navigate = useNavigate();

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const data = useWatch({ control });

  const onSubmit = useCallback((data) => {
    var formData = new FormData();

    formData.append("dataParse", JSON.stringify(data));

    if (data?.file) {
      for (let file of data?.file) {
        formData.append("file", file);
      }
    }

    createStory(formData)
      .then(() => {
        NotificationManager.success("История успешно создана");
        navigate(-1);
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      });
  }, []);

  return (
    <>
      <Meta title="Создать историю" />
      <div>
        <Link
          to="/content/stories"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h3>Создать историю</h3>
        <Button disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить
        </Button>
      </div>
      <Card>
        <Card.Body>
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <DragDropFile
                  maxAspect={450 / 800}
                  file={data.file}
                  onChange={(e) => setValue("file", e)}
                  onDelete={(e) => setValue("file", e.file)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Input
                  defaultValue={0}
                  label="Порядок"
                  name="priority"
                  errors={errors}
                  register={register}
                />
              </div>
            </Col>
            <Col md={6}>
              <Form.Check className="my-2">
                <Form.Check.Input
                  type="checkbox"
                  name="status"
                  id="status"
                  defaultChecked={true}
                  {...register("status")}
                />
                <Form.Check.Label htmlFor="status" className="ms-2">
                  Показать\Скрыть
                </Form.Check.Label>
              </Form.Check>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default StoryCreate;
