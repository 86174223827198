import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getEprCategory = async (data) => {
  const response = await $authApi.post(apiRoutes.EPR_CATEGORY, data);
  return response?.data;
};
const getEprProduct = async (data) => {
  const response = await $authApi.post(apiRoutes.EPR_PRODUCT, data);
  return response?.data;
};
const getEprPrice = async (data) => {
  const response = await $authApi.post(apiRoutes.EPR_PRICE, data);
  return response?.data;
};
const getEprModifier = async (data) => {
  const response = await $authApi.post(apiRoutes.EPR_MODIFIER, data);
  return response?.data;
};
const getEprTable = async (data) => {
  const response = await $authApi.post(apiRoutes.EPR_TABLE, data);
  return response?.data;
};
const getEprOrganizations = async (data) => {
  const response = await $authApi.post(apiRoutes.EPR_ORGANIZATION, data);
  return response?.data;
};
const getEprMenu = async (data) => {
  const response = await $authApi.post(apiRoutes.EPR_MENU, data);
  return response?.data;
};
const getEprDiscounts = async (data) => {
  const response = await $authApi.post(apiRoutes.EPR_DISCOUNT, data);
  return response?.data;
};
const getEprCoupons = async (data) => {
  const response = await $authApi.post(apiRoutes.EPR_COUPONS, data);
  return response?.data;
};
const getEprTypesDelivery = async (data) => {
  const response = await $authApi.post(apiRoutes.EPR_TEPES_DELIVERY, data);
  return response?.data;
};
const getEprTypesPayment = async (data) => {
  const response = await $authApi.post(apiRoutes.EPR_TYPES_PAYMENT, data);
  return response?.data;
};
const getEprTerminal = async (data) => {
  const response = await $authApi.post(apiRoutes.EPR_TERMINAL, data);
  return response?.data;
};
const getEprAll = async (data) => {
  const response = await $authApi.post(apiRoutes.EPR_ALL, data);
  return response?.data;
};
const getUploadCategories = async (data) => {
  const response = await $authApi.get(apiRoutes.UPLOAD_CATEGORIES, {
    params: data,
  });
  return response?.data;
};
const getUploadProducts = async (data) => {
  const response = await $authApi.get(apiRoutes.UPLOAD_PRODUCTS, {
    params: data,
  });
  return response?.data;
};
const getUploadModifiers = async (data) => {
  const response = await $authApi.get(apiRoutes.UPLOAD_MODIFIERS, {
    params: data,
  });
  return response?.data;
};

const updateUploadCategory = async (data) => {
  const response = await $authApi.put(apiRoutes.UPLOAD_CATEGORY, data);
  return response?.data;
};

const deleteUploadCategory = async (ids) => {
  const response = await $authApi.delete(apiRoutes.UPLOAD_CATEGORY, {
    data: { ids },
  });
  return response?.data;
};

const updateUploadModifier = async (data) => {
  const response = await $authApi.put(apiRoutes.UPLOAD_MODIFIER, {
    apiIds: data,
  });
  return response?.data;
};

const deleteUploadModifier = async (ids) => {
  const response = await $authApi.delete(apiRoutes.UPLOAD_MODIFIER, {
    data: { ids },
  });
  return response?.data;
};

const updateUploadProduct = async (data) => {
  const response = await $authApi.put(apiRoutes.UPLOAD_PRODUCT, {
    apiIds: data,
  });
  return response?.data;
};

const deleteUploadProduct = async (ids) => {
  const response = await $authApi.delete(apiRoutes.UPLOAD_PRODUCT, {
    data: { ids },
  });
  return response?.data;
};

const getEprParam = async (data) => {
  const response = await $authApi.post(apiRoutes.EPR_PARAM, data);
  return response?.data;
};
export {
  getEprAll,
  getEprMenu,
  getEprCategory,
  getEprProduct,
  getEprPrice,
  getEprModifier,
  getEprParam,
  getEprOrganizations,
  getEprDiscounts,
  getEprTypesDelivery,
  getEprTypesPayment,
  getEprTerminal,
  getUploadCategories,
  getUploadProducts,
  getUploadModifiers,
  deleteUploadCategory,
  deleteUploadProduct,
  deleteUploadModifier,
  updateUploadCategory,
  updateUploadProduct,
  updateUploadModifier,
  getEprCoupons,
  getEprTable
};
