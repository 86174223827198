import React from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import Button from "../../../components/UI/Button";
import Input from "../../../components/UI/Input";

const systemData = [
  {
    id: 1,
    title: "YooApp",
    value: "",
    image: "/images/modules/yooapp.png",
    main: true,
  },
  {
    id: 2,
    title: "Iiko Card",
    value: "iiko",
    image: "/images/modules/mini/iiko.webp",
    main: false,
  },
];

const PointForm = ({ data, onSubmit }) => {
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: data ?? { type: "" },
  });

  const form = useWatch({ control });

  return (
    <>
      <Row>
        <Col md={12}>
          <Card body className="mb-4">
            <Row xs={2} sm={3} md={3} className="gx-2 gy-4">
              {systemData.map((e, index) => (
                <Col>
                  <a
                    className={
                      "radio-custom" +
                      ((!form?.relationModule?.options?.type && index === 0) ||
                      e.value === form?.relationModule?.options?.type
                        ? " active"
                        : "")
                    }
                    onClick={() =>
                      setValue("relationModule.options.type", e.value)
                    }
                  >
                    <img
                      src={e.image}
                      alt={e.title}
                      className="radio-custom-logo"
                    />
                    <div className="radio-custom-num">
                      <span>{e.title}</span>
                    </div>
                  </a>
                </Col>
              ))}
            </Row>
            {form?.relationModule?.options?.type == "iiko" && (
              <p className="mt-2 text-danger fs-09">
                Бонусная система YooApp будет отключена
              </p>
            )}
          </Card>
        </Col>
        {form?.relationModule?.options?.type != "iiko" && (
          <Col md={6}>
            <Card body className="mb-4">
              <h5 className="mb-4 fw-7">Настройки начисления</h5>
              <div className="mb-3">
                <Input
                  label="Процент начисления"
                  placeholder="Введите процент"
                  name="relationModule.options.accrual.value"
                  errors={errors}
                  defaultValue={form?.relationModule?.options?.accrual?.value}
                  register={register}
                />
              </div>

              <Row>
                <Col md={6}>
                  <h5 className="mb-3 h6 fw-7">Тип доставки</h5>
                  <Form.Check className="mb-3">
                    <Form.Check.Input
                      type="checkbox"
                      name="relationModule.options.accrual.delivery"
                      id="delivery"
                      defaultChecked={
                        !!data?.relationModule?.options?.accrual?.delivery
                      }
                      {...register("relationModule.options.accrual.delivery")}
                    />
                    <Form.Check.Label htmlFor="delivery" className="ms-2">
                      Доставка
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check className="mb-3">
                    <Form.Check.Input
                      type="checkbox"
                      name="relationModule.options.accrual.pickup"
                      id="pickup"
                      defaultChecked={
                        !!data?.relationModule?.options?.accrual?.pickup
                      }
                      {...register("relationModule.options.accrual.pickup")}
                    />
                    <Form.Check.Label htmlFor="pickup" className="ms-2">
                      Самовывоз
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
                <Col md={6}>
                  <h5 className="mb-3 h6 fw-7">Тип оплаты</h5>
                  <Form.Check className="mb-3">
                    <Form.Check.Input
                      type="checkbox"
                      name="relationModule.options.accrual.cash"
                      id="cash"
                      defaultChecked={
                        !!data?.relationModule?.options?.accrual?.cash
                      }
                      {...register("relationModule.options.accrual.cash")}
                    />
                    <Form.Check.Label htmlFor="cash" className="ms-2">
                      Наличными
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check className="mb-3">
                    <Form.Check.Input
                      type="checkbox"
                      name="relationModule.options.accrual.card"
                      id="card"
                      defaultChecked={
                        !!data?.relationModule?.options?.accrual?.card
                      }
                      {...register("relationModule.options.accrual.card")}
                    />
                    <Form.Check.Label htmlFor="card" className="ms-2">
                      Банковской картой
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check className="mb-3">
                    <Form.Check.Input
                      type="checkbox"
                      name="relationModule.options.accrual.online"
                      id="online"
                      defaultChecked={
                        !!data?.relationModule?.options?.accrual?.online
                      }
                      {...register("relationModule.options.accrual.online")}
                    />
                    <Form.Check.Label htmlFor="online" className="ms-2">
                      Онлайн
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
              </Row>
            </Card>
          </Col>
        )}
        <Col md={6}>
          <Card body className="mb-4">
            <h5 className="mb-4 fw-7">Настройки списания</h5>
            <div className="mb-3">
              <Input
                label="Процент списания"
                placeholder="Введите процент"
                name="relationModule.options.writing.value"
                errors={errors}
                defaultValue={form?.relationModule?.options?.writing?.value}
                register={register}
              />
            </div>

            <Row>
              <Col md={6}>
                <h5 className="mb-3 h6 fw-7">Тип доставки</h5>
                <Form.Check className="mb-3">
                  <Form.Check.Input
                    type="checkbox"
                    name="relationModule.options.writing.delivery"
                    id="delivery-writing"
                    defaultChecked={
                      !!data?.relationModule?.options?.writing?.delivery
                    }
                    {...register("relationModule.options.writing.delivery")}
                  />
                  <Form.Check.Label htmlFor="delivery-writing" className="ms-2">
                    Доставка
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check className="mb-3">
                  <Form.Check.Input
                    type="checkbox"
                    name="relationModule.options.writing.pickup"
                    id="pickup-writing"
                    defaultChecked={
                      !!data?.relationModule?.options?.writing?.pickup
                    }
                    {...register("relationModule.options.writing.pickup")}
                  />
                  <Form.Check.Label htmlFor="pickup-writing" className="ms-2">
                    Самовывоз
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check className="mb-3">
                  <Form.Check.Input
                    type="checkbox"
                    name="relationModule.options.writing.pickup"
                    id="pickup-writing"
                    defaultChecked={
                      !!data?.relationModule?.options?.writing?.pickup
                    }
                    {...register("relationModule.options.writing.hall")}
                  />
                  <Form.Check.Label htmlFor="pickup-writing" className="ms-2">
                    В зале
                  </Form.Check.Label>
                </Form.Check>
              </Col>
              <Col md={6}>
                <h5 className="mb-3 h6 fw-7">Тип оплаты</h5>
                <Form.Check className="mb-3">
                  <Form.Check.Input
                    type="checkbox"
                    name="relationModule.options.writing.cash"
                    id="cash-writing"
                    defaultChecked={
                      !!data?.relationModule?.options?.writing?.cash
                    }
                    {...register("relationModule.options.writing.cash")}
                  />
                  <Form.Check.Label htmlFor="cash-writing" className="ms-2">
                    Наличными
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check className="mb-3">
                  <Form.Check.Input
                    type="checkbox"
                    name="relationModule.options.writing.card"
                    id="card-writing"
                    defaultChecked={
                      !!data?.relationModule?.options?.writing?.card
                    }
                    {...register("relationModule.options.writing.card")}
                  />
                  <Form.Check.Label htmlFor="card-writing" className="ms-2">
                    Банковской картой
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check className="mb-3">
                  <Form.Check.Input
                    type="checkbox"
                    name="relationModule.options.writing.online"
                    id="online-writing"
                    defaultChecked={
                      !!data?.relationModule?.options?.writing?.online
                    }
                    {...register("relationModule.options.writing.online")}
                  />
                  <Form.Check.Label htmlFor="online-writing" className="ms-2">
                    Онлайн
                  </Form.Check.Label>
                </Form.Check>
              </Col>
            </Row>
          </Card>
        </Col>
        {form?.relationModule?.options?.type != "iiko" && (
          <Col md={6}>
            <Card body className="mb-4">
              <h5 className="mb-4 fw-7">Начисление при регистрации</h5>
              <div className="mb-4">
                <Input
                  type="number"
                  label="Кол-во баллов"
                  placeholder="0"
                  name="relationModule.options.regPoint"
                  errors={errors}
                  defaultValue={form?.relationModule?.options?.regPoint}
                  register={register}
                />
              </div>
              <Input
                label="Описание"
                placeholder="Введите описание (Необязательно)"
                name="relationModule.options.regPointDesc"
                errors={errors}
                defaultValue={form?.relationModule?.options?.regPointDesc}
                register={register}
              />
            </Card>
          </Col>
        )}
      </Row>
      <div className="d-flex justify-content-end">
        <Button isValid={isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить изменения
        </Button>
      </div>
    </>
  );
};
export default PointForm;
