import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getPortfolio = async (data) => {
  const response = await $authApi.get(apiRoutes.PORTFOLIO, {
    params: data,
  });

  return response?.data;
};

const getPortfolioOne = async (id) => {
  const response = await $authApi.get(apiRoutes.PORTFOLIO_ONE, {
    params: {
      id,
    },
  });

  return response?.data;
};

const createPortfolio = async (data) => {
  const response = await $authApi.post(apiRoutes.PORTFOLIO, data);
  return response?.data;
};

const editPortfolio = async (data) => {
  const response = await $authApi.post(apiRoutes.PORTFOLIO_EDIT, data);
  return response?.data;
};

const deletePortfolio = async (ids) => {
  const response = await $authApi.delete(apiRoutes.PORTFOLIO, {
    data: { ids },
  });
  return response?.data;
};

export { getPortfolio, getPortfolioOne, createPortfolio, editPortfolio, deletePortfolio };
