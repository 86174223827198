import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getMembers = async (data) => {
  const response = await $authApi.get(apiRoutes.MEMBERS_GET, {
    params: data,
  });

  return response?.data;
};

const getMember = async (id) => {
  const response = await $authApi.get(apiRoutes.MEMBER_GET, {
    params: {
      id,
    },
  });

  return response?.data;
};

const editMember = async (data) => {
  const response = await $authApi.post(apiRoutes.MEMBERS_GET, data);
  return response?.data;
};

const editMemberLang = async (lang) => {
  const response = await $authApi.put(apiRoutes.MEMBER_LANG, { lang });
  return response?.data;
};

const createMember = async (data) => {
  const response = await $authApi.post(apiRoutes.MEMBER_CREATE, data);
  return response?.data;
};

const deleteMember = async (ids) => {
  const response = await $authApi.delete(apiRoutes.MEMBERS_GET, {
    data: { ids },
  });
  return response?.data;
};

export { getMembers, getMember, editMemberLang, editMember, deleteMember, createMember };
