import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getTables = async (data) => {
  const response = await $authApi.get(apiRoutes.TABLES, {
    params: data,
  });
  return response?.data;
};

const getTable = async (id) => {
  const response = await $authApi.get(apiRoutes.TABLE, {
    params: {
      id,
    },
  });
  return response?.data;
};

const editTable = async (data) => {
  const response = await $authApi.put(apiRoutes.TABLES, data);
  return response?.data;
};

const deleteTable = async (id) => {
  const response = await $authApi.delete(apiRoutes.TABLES, {
    data: { id },
  });
  return response?.data;
};

const createTable = async (data) => {
  const response = await $authApi.post(apiRoutes.TABLES, data);
  return response?.data;
};

export {
  getTables,
  getTable,
  editTable,
  deleteTable,
  createTable,
};

