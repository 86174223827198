import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getParams = async (data) => {
  const response = await $authApi.get(apiRoutes.PARAMS, {
    params: data,
  });
  return response?.data;
};

const getParam = async (id) => {
  const response = await $authApi.get(apiRoutes.PARAM, {
    params: {
      id,
    },
  });

  return response?.data;
};

const createParam = async (data) => {
  const response = await $authApi.postForm(apiRoutes.PARAMS, data);
  return response?.data;
};

const createParamChild = async (data) => {
  const response = await $authApi.postForm(apiRoutes.PARAM_CHILD_CREATE, data);
  return response?.data;
};

const editParam = async (data) => {
  const response = await $authApi.postForm(apiRoutes.PARAM_EDIT, data);
  return response?.data;
};

const deleteParam = async (ids) => {
  const response = await $authApi.delete(apiRoutes.PARAMS, {
    data: { ids },
  });
  return response?.data;
};

const deleteParamAll = async () => {
  const response = await $authApi.delete(apiRoutes.PARAMS_DELETE_ALL);
  return response?.data;
};

export {
  getParams,
  getParam,
  createParam,
  createParamChild,
  editParam,
  deleteParam,
  deleteParamAll
};
