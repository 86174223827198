import React, { useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Select from "../../components/UI/Select";
import { createUser } from "../../services/user";

const UserCreate = () => {
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });
  const form = useWatch({ control });

  const onSubmit = useCallback((data) => {
    createUser(data)
      .then(() => NotificationManager.success("Клиент успешно создан"))
      .catch((error) =>
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        )
      );
  }, []);

  return (
    <>
      <Meta title="Добавление клиента" />
      <div className="d-flex justify-content-between align-items-center">
        <Link
          to="/users"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
        <Button disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить изменения
        </Button>
      </div>
      <h3 className="mb-4">Добавление клиента</h3>
      <Row>
        <Col md={4}>
          <div className="mb-4">
            <Input
              label="Email"
              name="email"
              placeholder="Введите email"
              errors={errors}
              register={register}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-4">
            <Input
              label="Номер телефона"
              name="phone"
              placeholder="+7(000)000-00-00"
              mask="+7(999)999-99-99"
              errors={errors}
              register={register}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-4">
            <Input
              type="date"
              label="День рождения"
              placeholder="Введите день рождения (Необязательно)"
              name="brithday"
              errors={errors}
              register={register}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-4">
            <Input
              label="Фамилия"
              name="lastName"
              placeholder="Введите фамилию (Необязательно)"
              errors={errors}
              register={register}
              validation={{
                maxLength: {
                  value: 50,
                  message: "Максимально 50 символов",
                },
              }}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-4">
            <Input
              label="Имя"
              name="firstName"
              errors={errors}
              register={register}
              placeholder="Введите имя"
              validation={{
                required: "Введите имя",
                maxLength: {
                  value: 20,
                  message: "Максимально 20 символов",
                },
              }}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-4">
            <Input
              label="Отчество"
              name="patronymic"
              errors={errors}
              register={register}
              placeholder="Введите отчество (Необязательно)"
              validation={{
                maxLength: {
                  value: 50,
                  message: "Максимально 50 символов",
                },
              }}
            />
          </div>
        </Col>
        <Col md={4}>
          <Select
            className="mb-4"
            label="Пол"
            onClick={(e) => setValue("sex", e.value)}
            value={form.sex ?? ""}
            data={[
              { title: "Не указано", value: "" },
              { title: "Мужской", value: "man" },
              { title: "Женский", value: "woman" },
            ]}
          />
        </Col>
        <Col md={4}>
          <div className="mb-4">
            <Input
              label="Пароль"
              name="password"
              type="password"
              errors={errors}
              placeholder="Введите пароль"
              register={register}
              validation={{
                maxLength: {
                  value: 200,
                  message: "Максимально 200 символов",
                },
              }}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-4">
            <Input
              label="Баллы"
              name="point"
              placeholder="Кол-во баллов (Необязательно)"
              errors={errors}
              register={register}
              validation={{
                max: {
                  value: 100000,
                  message: "Максимально 100 000 баллов",
                },
              }}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default UserCreate;
