import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { Badge } from "react-bootstrap";
import { IoAdd, IoCreateOutline, IoTrashOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import { getImageURL } from "../../helpers/image";
import { deleteStory, getStories } from "../../services/story";

const Stories = () => {
  const brand = useSelector((state) => state.brand.active);
  const [searchParams, setSearchParams] = useSearchParams();
  const [stories, setStories] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });

  const storyColumns = [
    {
      name: "Фото",
      width: "60px",
      selector: "media",
      cell: (row) => (
        <img
          src={getImageURL({ path: row?.medias, type: "story" })}
          width={30}
          height={30}
          className="mini-img"
        />
      ),
    },
    {
      name: "Порядок",
      selector: "priority",
      align: "center",
    },
    {
      name: "Статус",
      selector: "status",
      align: "center",
      cell: (row) =>
        row.status === 0 ? (
          <Badge bg="secondary">Архив</Badge>
        ) : (
          <Badge bg="success">Активно</Badge>
        ),
    },
    {
      width: "80px",
      selector: "action",
      align: "right",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <Link to={"/content/story/" + row.id} className="me-3">
            <IoCreateOutline size={22} />
          </Link>
          <a
            onClick={() =>
              setModalDelete({ show: !modalDelete.show, id: row.id })
            }
            className="me-2"
          >
            <IoTrashOutline size={20} className="text-danger" />
          </a>
        </div>
      ),
    },
  ];
  const header = useMemo(() => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <h5 className="fw-7">
            {selected.length > 0 ? `Выбрано ${selected.length}` : "Истории"}
          </h5>
        </div>
        <div className="d-flex align-items-center">
          <Link
            to="/content/stories/create"
            className="btn-primary-outline me-3"
          >
            <IoAdd size={18} />
          </Link>
          <Button
            disabled={selected.length === 0}
            className="btn-light"
            onClick={() => setModalDelete({ show: true, id: false })}
          >
            <IoTrashOutline size={18} />
          </Button>
        </div>
      </div>
    );
  }, [selected, searchParams, modalDelete]);

  const getData = useCallback(async () => {
    getStories(searchParams)
      .then(
        (res) =>
          res &&
          setStories((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }))
      )
      .finally(() => setStories((prev) => ({ ...prev, loading: false })));
  }, [searchParams, brand]);

  const onDelete = useCallback((id) => {
    deleteStory(id)
      .then(() => {
        getData();
        NotificationManager.success("Истории успешно удалена");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, []);

  const onDeleteSelected = useCallback(() => {
    deleteStory(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        getData();
        NotificationManager.success("Выбранные истории успешно удалены");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, [selected]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page"), brand]);

  if (stories.loading) {
    return <Loader full />;
  }

  return (
    <>
      <Meta title="Истории" />
      <DataTable
        columns={storyColumns}
        onChange={(items) => setSelected(items)}
        data={stories.items}
        header={header}
        selectable
        linkPagination="/stories/"
        statusBackground="story"
        pagination={stories.pagination}
      />
      <CustomModal
        title={
          selected.length > 0
            ? `Удаление ${selected.length} элементов`
            : "Удаление элемента"
        }
        show={modalDelete.show}
        setShow={(e) => setModalDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className="me-3"
              onClick={() =>
                setModalDelete({ show: !modalDelete.show, id: false })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-danger"
              onClick={() =>
                selected.length > 0
                  ? onDeleteSelected()
                  : modalDelete.id && onDelete(modalDelete.id)
              }
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить истории?
      </CustomModal>
    </>
  );
};

export default Stories;
