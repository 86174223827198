import React, {
  createRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Badge, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  IoAdd,
  IoCloseOutline,
  IoCreateOutline,
  IoSearchOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import CustomModal from "../../components/utils/CustomModal";
import { getImageURL } from "../../helpers/image";
import { customPrice } from "../../helpers/product";
import { deleteAddition, getAdditions } from "../../services/addition";

const Additions = () => {
  const { t } = useTranslation();
  const inputRef = createRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const brand = useSelector((state) => state.brand.active);

  const [additions, setAdditions] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });

  const additionColumns = [
    {
      name: "Фото",
      width: "60px",
      selector: "media",
      cell: (row) => (
        <img
          src={getImageURL({ path: row?.media, type: "addition" })}
          width={30}
          height={30}
          className="mini-img"
        />
      ),
    },
    {
      name: "Название",
      selector: "title",
    },
    {
      name: "Вес",
      width: "100px",
      cell: (row) => row?.energy?.weight ?? null,
    },
    {
      name: "Артикул",
      selector: "code",
      cell: (row) => row?.code ?? "-",
      width: "120px",
    },
    {
      name: "Стоимость",
      selector: "price",
      align: "right",
      cell: (row) =>
        row?.modifiers?.length > 0
          ? `${t("от")} ` +
            customPrice(
              Math.min.apply(
                null,
                row.modifiers.map((e) => e.price)
              )
            )
          : customPrice(row.price),
      width: "120px",
    },
    {
      width: "120px",
      name: "Статус",
      align: "center",
      selector: "status",
      cell: (row) =>
        row.status === 0 ? (
          <Badge bg="secondary">{t("Архив")}</Badge>
        ) : (
          <Badge bg="success">{t("Активно")}</Badge>
        ),
    },
    {
      width: "80px",
      selector: "action",
      align: "right",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <Link
            disabled={selected.length > 0}
            to={"/catalog/addition/" + row.id}
            className="me-3"
          >
            <IoCreateOutline size={22} />
          </Link>
          <a
            disabled={selected.length > 0}
            onClick={() =>
              setModalDelete({ show: !modalDelete.show, id: row.id })
            }
            className="me-2"
          >
            <IoTrashOutline size={20} className="text-danger" />
          </a>
        </div>
      ),
    },
  ];

  const header = useMemo(() => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h5 className="fw-7">
              {selected.length > 0
                ? `${t("Выбрано")} ${selected.length}`
                : t("Добавки")}
            </h5>
          </div>
          <div className="d-flex align-items-center">
            <Link
              to="/catalog/additions/create"
              className="btn-primary-outline me-3"
            >
              <IoAdd size={18} />
            </Link>
            <Button
              className="btn-light"
              onClick={() =>
                selected.length > 0
                  ? setModalDelete({ show: true, id: false })
                  : setModalDeleteAll(true)
              }
            >
              <IoTrashOutline size={18} />
            </Button>
          </div>
        </div>
        <div>
          <Row>
            <Col md={4}>
              <Select
                classNameContainer="w-100"
                label="Статус"
                data={[
                  { title: "Все", value: "" },
                  { title: "Скрытые из каталога", value: "noview" },
                  { title: "Без фото", value: "nomedia" },
                  { title: "Без суммы", value: "noprice" },
                ]}
                value={searchParams.get("status") ?? ""}
                onClick={(e) => {
                  searchParams.set("status", e.value);
                  setSearchParams(searchParams);
                  onSearch();
                }}
              />
            </Col>
            <Col md={4}>
              <Select
                classNameContainer="w-100"
                label="Сортировка"
                data={[
                  { title: "По дате добавления: новые", value: "createnew" },
                  { title: "По дате добавления: старые", value: "createold" },
                  { title: "По дате обновления: новые", value: "updatenew" },
                  { title: "По дате обновления: старые", value: "updateold" },
                  { title: "Стоимость: больше", value: "pricemore" },
                  { title: "Стоимость: меньше", value: "priceless" },
                ]}
                value={searchParams.get("sort") ?? "createnew"}
                onClick={(e) => {
                  searchParams.set("sort", e.value);
                  setSearchParams(searchParams);
                  onSearch();
                }}
              />
            </Col>
            <Col md={4}>
              <Input
                ref={inputRef}
                placeholder={t("Найти")}
                className="w-100"
                onChange={(e) => {
                  searchParams.set("text", e);
                  setSearchParams(searchParams);
                }}
                rightIcon={() => <IoSearchOutline size={22} />}
                value={searchParams.get("text") ?? ""}
                rightIconClick={() => onSearch()}
                onKeyDown={(e) => e === "Enter" && onSearch()}
              />
              {searchParams.get("text")?.length > 0 && (
                <Button
                  className="btn-light ms-3"
                  onClick={() => {
                    searchParams.delete("text");
                    setSearchParams(searchParams);
                    onSearch();
                    if (inputRef.current) {
                      inputRef.current.value = "";
                    }
                  }}
                >
                  <IoCloseOutline size={22} />
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  }, [selected, searchParams, modalDelete, t]);

  const getData = useCallback(async () => {
    getAdditions(searchParams)
      .then(
        (res) =>
          res &&
          setAdditions((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }))
      )
      .finally(() => setAdditions((prev) => ({ ...prev, loading: false })));
  }, [searchParams, brand]);

  const onDelete = useCallback((id) => {
    deleteAddition(id)
      .then(() => {
        getData();
        NotificationManager.success(t("Добавка успешно удалена"));
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error(t("Ошибка при запросе")));
  }, []);

  const onDeleteSelected = useCallback(() => {
    deleteAddition(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        getData();
        NotificationManager.success(t("Выбранные добавки успешно удалены"));
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error(t("Ошибка при запросе")));
  }, [selected]);

  const onSearch = useCallback(() => {
    getData();
  }, [searchParams.get("text")]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page"), brand]);

  if (additions.loading) {
    return <Loader full />;
  }

  return (
    <>
      <Meta title={t("Добавки")} />
      <DataTable
        columns={additionColumns}
        onChange={(items) => setSelected(items)}
        data={additions.items}
        header={header}
        selectable
        linkPagination="/catalog/additions/"
        statusBackground="addition"
        pagination={additions.pagination}
      />
      <CustomModal
        title={
          selected.length > 0
            ? `Удаление ${selected.length} элементов`
            : t("Удаление элемента")
        }
        show={modalDelete.show}
        setShow={(e) => setModalDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className="me-3"
              onClick={() =>
                setModalDelete({ show: !modalDelete.show, id: false })
              }
            >
              {t("Отмена")}
            </Button>
            <Button
              className="btn-danger"
              onClick={() =>
                selected.length > 0
                  ? onDeleteSelected()
                  : modalDelete.id && onDelete(modalDelete.id)
              }
            >
              {t("Удалить")}
            </Button>
          </>
        }
      >
        {t("Вы точно хотите удалить добавку(-и)?")}
      </CustomModal>
    </>
  );
};

export default Additions;
