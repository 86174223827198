import React, { useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Meta from "../components/Meta";
import Loader from "../components/UI/Loader";
import { getDocumentView } from "../services/document";

const DocsView = () => {
  const { brandId, action } = useParams();
  const [document, setDocument] = useState({ loading: true, data: false });

  useLayoutEffect(() => {
    getDocumentView({ brandId, action })
      .then((res) => setDocument({ loading: false, data: res }))
      .catch(() => setDocument({ loading: false }));
  }, []);

  if (document?.loading) {
    return <Loader full />;
  }

  return (
    <main className="p-4">
      <Meta title={document?.data?.title ?? "Документы"} />
      {document?.data && (
        <div dangerouslySetInnerHTML={{ __html: document.data.content }}></div>
      )}
    </main>
  );
};

export default DocsView;
