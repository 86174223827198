import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const MenuSale = () => {
  const { t } = useTranslation();
  return (
    <div className="w-100 account-menu-sub-bg">
      <nav className="account-menu-sub">
        <ul>
          <li>
            <NavLink to="all" end>
              {t("Акции")}
            </NavLink>
          </li>
          <li>
            <NavLink to="promos">{t("Промокоды")}</NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default MenuSale;
