import React, { useCallback, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Select from "../../components/UI/Select";
import { getRoleData, roles } from "../../helpers/member";
import { createMember } from "../../services/member";

const MemberCreate = () => {
  const affiliate = useSelector((state) => state.affiliate);
  const [btnLoading, setBtnLoading] = useState(false);
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "all",
    reValidateMode: "onSubmit",
    defaultValues: {
      groupRole: 1,
      role: 1,
      affiliateId: "",
    },
  });
  const form = useWatch({ control });

  const onSubmit = useCallback((data) => {
    setBtnLoading(true);
    createMember(data)
      .then(() => NotificationManager.success("Данные успешно обновлены"))
      .catch((error) =>
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        )
      )
      .finally(() => setBtnLoading(false));
  }, []);

  return (
    <>
      <Meta title="Добавление сотрудника" />
      <div className="d-flex justify-content-between align-items-center">
        <Link
          to="/options/members"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
        <Button
          disabled={!isValid}
          isLoading={btnLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Сохранить изменения
        </Button>
      </div>
      <h3 className="mb-4">Добавление сотрудника</h3>
      <Row>
        <Col md={4}>
          <div className="mb-4">
            <Input
              autocomplete="off"
              label="Номер телефона"
              name="phone"
              placeholder="+7(000)000-00-00"
              mask="+7(999)999-99-99"
              errors={errors}
              register={register}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-4">
            <Input
              autocomplete="off"
              type="date"
              label="День рождения"
              placeholder="Введите день рождения (Необязательно)"
              name="brithday"
              errors={errors}
              register={register}
            />
          </div>
        </Col>
        <Col md={4}>
          <Select
            className="mb-4"
            label="Пол"
            onClick={(e) => setValue("sex", e.value)}
            value={form.sex ?? ""}
            data={[
              { title: "Не указано", value: "" },
              { title: "Мужской", value: "man" },
              { title: "Женский", value: "woman" },
            ]}
          />
        </Col>
        <Col md={4}>
          <div className="mb-4">
            <Input
              autocomplete="off"
              label="Фамилия"
              name="lastName"
              placeholder="Введите фамилию (Необязательно)"
              errors={errors}
              register={register}
              validation={{
                maxLength: {
                  value: 50,
                  message: "Максимально 50 символов",
                },
              }}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-4">
            <Input
              autocomplete="off"
              label="Имя"
              name="firstName"
              errors={errors}
              register={register}
              placeholder="Введите имя"
              validation={{
                required: "Введите имя",
                maxLength: {
                  value: 20,
                  message: "Максимально 20 символов",
                },
              }}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-4">
            <Input
              label="Отчество"
              name="patronymic"
              autocomplete="off"
              errors={errors}
              register={register}
              placeholder="Введите отчество (Необязательно)"
              validation={{
                maxLength: {
                  value: 50,
                  message: "Максимально 50 символов",
                },
              }}
            />
          </div>
        </Col>
      </Row>
      <h5 className="mb-4">Настройки</h5>
      <Row>
        <Col md={4}>
          <Select
            className="mb-4"
            label="Роль"
            onClick={(e) => setValue("groupRole", e.value)}
            value={form.groupRole}
            data={roles}
          />
        </Col>
        <Col md={4}>
          <Select
            className="mb-4"
            label="Должность"
            onClick={(e) => setValue("role", e.value)}
            value={form.role}
            data={getRoleData(form.groupRole)}
          />
        </Col>
        <Col md={4}>
          <Select
            className="mb-4"
            label="Филиал"
            onClick={(e) => setValue("affiliateId", e.value)}
            value={form.affiliateId}
            data={affiliate.data.map((e) => ({
              title: e.title ? e.title : e.full,
              subTitle: e.title != e.full && e.full ? e.full : null,
              value: e.id,
              main: e.main,
            }))}
          />
        </Col>
      </Row>
      <h5 className="mb-4">Данные для входа</h5>
      <Row>
        <Col md={4}>
          <div className="mb-4">
            <Input
              autocomplete="off"
              label="Email"
              name="email"
              placeholder="Введите email"
              errors={errors}
              register={register}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-4">
            <Input
              autocomplete="off"
              label="Пароль"
              name="password"
              type="password"
              errors={errors}
              placeholder="Введите пароль"
              register={register}
              validation={{
                required: "Введите пароль",
                maxLength: {
                  value: 200,
                  message: "Максимально 200 символов",
                },
              }}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-4">
            <Input
              autocomplete="off"
              label="Повторный пароль"
              name="passwordConfirm"
              type="password"
              errors={errors}
              placeholder="Введите повторно пароль"
              register={register}
              validation={{
                required: "Введите повторно пароль",
                maxLength: {
                  value: 200,
                  message: "Максимально 200 символов",
                },
              }}
            />
          </div>
        </Col>
      </Row>
      <h5 className="mb-4">Паспортные данные</h5>
      <Row>
        <Col md={4}>
          <div className="mb-4">
            <Input
              autocomplete="off"
              label="Серия и номер"
              name="passport.number"
              errors={errors}
              register={register}
              placeholder="Введите серию и номер"
              validation={{
                maxLength: {
                  value: 20,
                  message: "Максимально 20 символов",
                },
              }}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-4">
            <Input
              autocomplete="off"
              type="date"
              label="Дата регистрации"
              placeholder="Введите дату регистрации"
              name="passport.reg"
              errors={errors}
              register={register}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className="mb-4">
            <Input
              autocomplete="off"
              label="Место регистрации"
              name="passport.place"
              errors={errors}
              register={register}
              placeholder="Введите место регистрации"
              validation={{
                maxLength: {
                  value: 250,
                  message: "Максимально 250 символов",
                },
              }}
            />
          </div>
        </Col>
      </Row>
      <div className="d-flex justify-content-end">
        <Button
          disabled={!isValid}
          isLoading={btnLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Сохранить изменения
        </Button>
      </div>
    </>
  );
};

export default MemberCreate;
