import moment from "moment/moment";
import Howler from "howler";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import {
  IoCreateOutline,
  IoKeyOutline,
  IoPause,
  IoPauseCircle,
  IoPauseCircleOutline,
  IoPlay,
  IoPlayCircle,
  IoPlayCircleOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Textarea from "../../components/UI/Textarea";
import CustomModal from "../../components/utils/CustomModal";
import { editMember } from "../../services/member";
import { setUser } from "../../store/reducers/authSlice";
import { isRole } from "../../helpers/member";
import { convertColor } from "../../helpers/convertColor";
import { audioList } from "../../helpers/all";

const Account = () => {
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: user,
  });
  const form = useWatch({ control });

  const dispatch = useDispatch();
  const [addOrganizationModal, setAddOrganizationModal] = useState(false);

  const role = isRole(user);
  const [audio, setAudio] = useState(false);

  const handlePlay = (index) => {
    if (audio?.data) {
      audio.data.stop();
    }
    let audioClick = new Howler.Howl({
      src: [audioList[index].src],
      loop: false,
      volume: 1,
      onend: () => {
        setAudio(false);
      },
    });
    audioClick.play();
    setAudio({ data: audioClick, index });
  };

  const handleStop = () => {
    if (audio?.data) {
      audio.data.stop();
      setAudio(false);
    }
  };

  const onSubmit = useCallback((data) => {
    setLoading(true);
    editMember(data)
      .then(() => {
        dispatch(setUser(data));
        NotificationManager.success("Данные успешно обновлены");
      })
      .catch((error) =>
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        )
      )
      .finally(() => setLoading(false));
  }, []);

  if (!user) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Нет информации о пользователе</h3>
      </Info>
    );
  }

  return (
    <>
      <Meta title="Профиль" />
      <h3 className="mb-4">Профиль</h3>
      <Row>
        <Col md={4}>
          <Card body>
            <div className="d-flex align-items-center justify-content-between">
              <div
                className="role"
                style={
                  role?.group?.color && {
                    color: role.group.color,
                    backgroundColor: convertColor(role.group.color, 0.08),
                  }
                }
              >
                {role?.group?.title ?? "Отдел не указан"}
              </div>
              <div>
                <IoKeyOutline size={22} className="text-muted" />
              </div>
            </div>
            <div className="mb-4 d-flex flex-column align-items-center position-relative">
              <img src="/images/avatar-full.png" height={110} />

              <p className="mt-2 fw-6 fs-08">
                {role?.role?.title ?? "Сотрудник"}{" "}
                <span className="fw-4 text-muted">
                  c {moment(user.createdAt).format("DD.MM.YYYY")}
                </span>
              </p>
            </div>
            <div className="mb-4">
              <Input
                label="Email"
                name="email"
                errors={errors}
                defaultValue={form.email}
                register={register}
              />
            </div>
            <div className="mb-4">
              <Input
                readOnly={false}
                label="Номер телефона"
                name="phone"
                mask="+7(999)999-99-99"
                errors={errors}
                defaultValue={form.phone}
                register={register}
                validation={{ required: "Обязательное поле" }}
              />
            </div>
            <Button
              className="btn btn-primary w-100"
              isValid={isValid}
              isLoading={loading}
              disabled={loading}
              onClick={handleSubmit(onSubmit)}
            >
              Сохранить изменения
            </Button>
          </Card>
        </Col>
        <Col md={8}>
          <Card body className="mb-4">
            <h5 className="mb-2 fw-6 h6">Паспортные данные</h5>
            <Row>
              <Col md={4}>
                <div className="mt-4">
                  <Input
                    label="Фамилия"
                    name="lastName"
                    errors={errors}
                    register={register}
                    validation={{
                      maxLength: {
                        value: 50,
                        message: "Максимально 50 символов",
                      },
                    }}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-4">
                  <Input
                    label="Имя"
                    name="firstName"
                    errors={errors}
                    register={register}
                    validation={{
                      required: "Введите имя",
                      maxLength: {
                        value: 20,
                        message: "Максимально 20 символов",
                      },
                    }}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mt-4">
                  <Input
                    label="Отчество"
                    name="patronymic"
                    errors={errors}
                    register={register}
                    validation={{
                      maxLength: {
                        value: 50,
                        message: "Максимально 50 символов",
                      },
                    }}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mt-4">
                  <Input
                    type="date"
                    label="День рождения"
                    name="brithday"
                    errors={errors}
                    register={register}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mt-4">
                  <Input
                    type="text"
                    label="Номер паспорта"
                    name="passport.number"
                    errors={errors}
                    register={register}
                    validation={{
                      maxLength: {
                        value: 20,
                        message: "Максимально 20 символов",
                      },
                    }}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mt-4">
                  <Input
                    type="date"
                    label="Дата выдачи"
                    name="passport.outputDate"
                    errors={errors}
                    register={register}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mt-4">
                  <Input
                    type="text"
                    label="Код подразделения"
                    name="passport.code"
                    errors={errors}
                    register={register}
                    validation={{
                      maxLength: {
                        value: 20,
                        message: "Максимально 20 символов",
                      },
                    }}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mt-4">
                  <Textarea
                    label="Кем выдан"
                    name="passport.outputBy"
                    rows={1}
                    errors={errors}
                    register={register}
                    validation={{
                      maxLength: {
                        value: 500,
                        message: "Максимально 500 символов",
                      },
                    }}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mt-4">
                  <Textarea
                    label="Регистрация"
                    name="passport.addressRegistration"
                    rows={1}
                    errors={errors}
                    register={register}
                    validation={{
                      maxLength: {
                        value: 500,
                        message: "Максимально 500 символов",
                      },
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Card>
          <Card body className="mb-4">
            <h5 className="mb-3 fw-6 h6">Звук и уведомления</h5>

            <div>
              {audioList.map((e) => (
                <div
                  key={e.id}
                  className="d-flex flex-row align-items-center mb-2"
                >
                  <Form.Check>
                    <Form.Check.Input
                      type="checkbox"
                      onChange={() => setValue("options.audio", e.id)}
                      checked={form.options?.audio === e.id}
                    />
                  </Form.Check>
                  <div className="d-flex w-100 flex-row justify-content-between align-items-center">
                    <a
                      className="ms-2 fs-09"
                      onClick={() => setValue("options.audio", e.id)}
                    >
                      {e.title}
                    </a>
                    {e.src && (
                      <a
                        onClick={() =>
                          audio?.index === e.id
                            ? handleStop(e.id)
                            : handlePlay(e.id)
                        }
                      >
                        {audio?.index === e.id ? (
                          <IoPauseCircleOutline
                            className="text-success"
                            size={26}
                          />
                        ) : (
                          <IoPlayCircleOutline
                            className="text-success"
                            size={26}
                          />
                        )}
                      </a>
                    )}
                  </div>
                </div>
              ))}
              <hr />
              <div className="d-flex flex-row align-items-center mb-2">
                <Form.Check>
                  <Form.Check.Input
                    type="checkbox"
                    onChange={() =>
                      setValue("options.audioLoop", !form.options?.audioLoop)
                    }
                    checked={form.options?.audioLoop}
                  />
                </Form.Check>
                <div className="d-flex w-100 flex-row align-items-center">
                  <a
                    className="ms-2 fs-09"
                    onClick={() =>
                      setValue("options.audioLoop", !form.options?.audioLoop)
                    }
                  >
                    Зацикливать воспроизведение
                  </a>
                </div>
              </div>
            </div>
          </Card>
          <Card body>
            <h5 className="mb-2 fw-6 h6">Организации</h5>
            {user?.organizations?.length > 0 &&
              user.organizations.map((e) => (
                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <div>
                    <p>{e.name}</p>
                    <p className="text-muted fs-08">ИНН {e.inn}</p>
                  </div>
                  <div className="d-flex align-items-center flex-row">
                    <a className="me-3">
                      <IoCreateOutline size={24} className="text-muted" />
                    </a>
                    <a>
                      <IoTrashOutline size={22} className="text-danger" />
                    </a>
                  </div>
                </div>
              ))}
            <Button
              className="btn btn-primary-outline btn-sm"
              onClick={() => setAddOrganizationModal(!addOrganizationModal)}
            >
              Добавить организацию
            </Button>
          </Card>
        </Col>
      </Row>
      <CustomModal
        show={addOrganizationModal}
        setShow={setAddOrganizationModal}
        title="Добавить организацию"
        size="lg"
        footer={
          <>
            <Button
              type="button"
              className="btn-default"
              onClick={() => setAddOrganizationModal(!addOrganizationModal)}
            >
              Отмена
            </Button>
            <Button type="button" className="btn-primary">
              Сохранить
            </Button>
          </>
        }
      >
        <Row>
          <Col md={12}>
            <div className="mb-4">
              <Input
                label="Полное название организации"
                name="name"
                errors={errors}
                placeholder='ООО "Ромашка"'
                register={register}
                validation={{
                  required: "Введите полное название организации",
                  maxLength: {
                    value: 255,
                    message: "Максимально 255 символов",
                  },
                }}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Input
                label="ИНН"
                name="inn"
                errors={errors}
                register={register}
                validation={{
                  required: "Введите ИНН",
                  maxLength: {
                    value: 20,
                    message: "Максимально 20 символов",
                  },
                }}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Input
                label="КПП"
                name="kpp"
                errors={errors}
                register={register}
                validation={{
                  required: "Введите КПП",
                  maxLength: {
                    value: 20,
                    message: "Максимально 20 символов",
                  },
                }}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Input
                label="БИК"
                name="bik"
                errors={errors}
                register={register}
                validation={{
                  required: "Введите БИК",
                  maxLength: {
                    value: 20,
                    message: "Максимально 20 символов",
                  },
                }}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-4">
              <Input
                label="Расчетный счет"
                name="rCheck"
                errors={errors}
                register={register}
                validation={{
                  required: "Введите расчетный счет",
                  maxLength: {
                    value: 50,
                    message: "Максимально 50 символов",
                  },
                }}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-4">
              <Input
                label="Корреспондентский счет"
                name="kCheck"
                errors={errors}
                register={register}
                validation={{
                  required: "Введите корреспондентский счет",
                  maxLength: {
                    value: 50,
                    message: "Максимально 50 символов",
                  },
                }}
              />
            </div>
          </Col>
          <Col md={12}>
            <Textarea
              label="Адрес"
              name="address"
              rows={1}
              errors={errors}
              register={register}
              validation={{
                required: "Обязательное поле",
                maxLength: {
                  value: 500,
                  message: "Максимально 500 символов",
                },
              }}
            />
          </Col>
        </Row>
      </CustomModal>
    </>
  );
};

export default Account;
