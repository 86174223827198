import { useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { getRedirectPay } from "../services/document";

const RedirectPay = () => {
  const { brandId, action } = useParams();

  useLayoutEffect(() => {
    getRedirectPay({ brandId, action }).then((res) => {
      if (res?.link) {
        window.location.href = res?.link + "://Home";
      }
    });
  }, []);
};

export default RedirectPay;
