import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  ip: '0.0.0.0',
};

const statisticSlice = createSlice({
  name: "statistic",
  initialState,
  reducers: {
    setStatistics: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setStatistics } = statisticSlice.actions;

export default statisticSlice.reducer;
