import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { Badge, Form } from "react-bootstrap";
import { IoCreateOutline, IoTrashOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import { deleteStatus, getStatuses } from "../../services/status";

const Statuses = () => {
  const statuses = useSelector((state) => state.status.data);

  const statusColumns = [
    {
      name: "Название",
      align: "center",
      cell: (row) => row.name ?? "-",
    },
    {
      name: "Значение",
      align: "center",
      cell: (row) => row.value ?? "-",
    },
    {
      name: "Цвет",
      align: "center",
      cell: (row) =>
        row.color ? <span style={{ color: row.color }}>{row.color}</span> : "-",
    },
    {
      name: "Порядок",
      align: "center",
      cell: (row) => row.order ?? "-",
    },
    {
      name: "Интеграция",
      align: "center",
      cell: (row) => row.apiId ?? "-",
    },
    {
      name: "Статус",
      align: "center",
      cell: (row) =>
        row.status ? (
          <Badge bg="success">Активен</Badge>
        ) : (
          <Badge bg="secondary">Отключен</Badge>
        ),
    },
    {
      width: "80px",
      selector: "action",
      align: "right",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <Link to={"/options/status/" + row.id} className="me-3">
            <IoCreateOutline size={22} />
          </Link>
        </div>
      ),
    },
  ];

  const header = useMemo(() => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <h5 className="fw-7 me-3">Статусы</h5>
        </div>
      </div>
    );
  }, []);

  if (!statuses || statuses.length === 0) {
    return (
      <>
        <Meta title="Статусы" />
        <Info>
          <svg
            className="mb-3"
            width="60"
            height="60"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.32"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
              fill="#999"
            />
            <path
              d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
              fill="#999"
            />
          </svg>
          <h3>Нет статусов</h3>
        </Info>
      </>
    );
  }

  return (
    <>
      <Meta title="Статусы" />
      <DataTable columns={statusColumns} data={statuses} header={header} />
    </>
  );
};

export default Statuses;
