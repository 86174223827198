import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Collapse, Dropdown, Form, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline, IoTrashOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import DragDropFile from "../../components/DragDropFile";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import Textarea from "../../components/UI/Textarea";
import { timezones, work } from "../../helpers/all";
import useDebounce from "../../hooks/useDebounce";
import { createAffiliate } from "../../services/affiliate";
import { getCities } from "../../services/city";
import { getDadataStreets } from "../../services/dadata";
import { getModule } from "../../services/module";
import { setAffiliates } from "../../store/reducers/affiliateSlice";

const CreateAffiliate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [streets, setStreets] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [module, setModule] = useState({ data: false, loading: true });
  const [open, setOpen] = useState(false);
  const [cities, setCities] = useState([]);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      apiId: "",
      cities: [],
      organizationId: "",
      title: null,
      desc: null,
      full: "",
      media: null,
      phone: null,
      email: null,
      options: {
        timezone: "Europe/Moscow",
        area: null,
        city: "",
        flat: "",
        work: work,
        block: "",
        house: "",
        okato: "",
        oktmo: "",
        region: "",
        street: "",
        country: "",
        fias_id: "",
        kladr_id: "",
        coordinates: {
          lat: "",
          lon: "",
        },
        postal_code: "",
        preorderMax: 7,
        preorderMin: 60,
        area_fias_id: null,
        area_kladr_id: null,
        house_fias_id: "",
        discountPickup: "",
        house_kladr_id: null,
        region_fias_id: "",
        region_kladr_id: "",
        settlement_fias_id: null,
        settlement_kladr_id: null,
      },
      comment: null,
      status: 1,
    },
  });
  const data = useWatch({ control });

  const streetText = useDebounce(data.full, 1000);

  const clickAffiliate = (affiliate) => {
    setValue("full", affiliate.value ?? null);

    setValue("options.country", affiliate.data.country ?? null);

    setValue("options.region", affiliate.data.region ?? null);

    setValue("options.city", affiliate.data.city ?? null);
    setValue("options.settlement", affiliate.data.settlement ?? null);
    setValue("options.area", affiliate.data.area ?? null);

    setValue("options.coordinates.lat", affiliate.data.geo_lat ?? null);
    setValue("options.coordinates.lon", affiliate.data.geo_lon ?? null);

    setValue("options.fias_id", affiliate.data.fias_id ?? null);
    setValue("options.region_fias_id", affiliate.data.region_fias_id ?? null);
    setValue("options.area_fias_id", affiliate.data.area_fias_id ?? null);
    setValue("options.house_fias_id", affiliate.data.house_fias_id ?? null);
    setValue(
      "options.settlement_fias_id",
      affiliate.data.settlement_fias_id ?? null
    );

    setValue("options.kladr_id", affiliate.data.kladr_id ?? null);
    setValue("options.region_kladr_id", affiliate.data.region_kladr_id ?? null);
    setValue("options.area_kladr_id", affiliate.data.area_kladr_id ?? null);
    setValue("options.house_kladr_id", affiliate.data.area_kladr_id ?? null);
    setValue(
      "options.settlement_kladr_id",
      affiliate.data.settlement_kladr_id ?? null
    );

    setValue("options.postal_code", affiliate.data.postal_code ?? null);

    setValue("options.okato", affiliate.data.okato ?? null);
    setValue("options.oktmo", affiliate.data.oktmo ?? null);

    setShowDropdown(false);
  };

  const onKeyDown = (e) => {
    if (e === "Enter" && streets?.length > 0) {
      clickAffiliate(streets[0]);
      setStreets([]);
    }
  };

  useEffect(() => {
    getCities({ size: 200 }).then((res) =>
      setCities(
        res.items
          .sort((a, b) => a.title.localeCompare(b.title))
          .map((e) => ({ title: e.title, value: e.id }))
      )
    );
    getModule({ moduleId: 1 })
      .then(
        (res) =>
          res &&
          setModule({
            loading: false,
            data: res,
          })
      )
      .catch(() =>
        setModule((res) => ({
          ...res,
          loading: false,
        }))
      );
  }, []);

  useEffect(() => {
    if (streetText) {
      getDadataStreets(streetText).then((res) => {
        if (res?.data?.suggestions) {
          setStreets(res.data.suggestions);
        }
      });
    }
  }, [streetText]);

  const onSubmit = useCallback((data) => {
    var formData = new FormData();

    formData.append("dataParse", JSON.stringify(data));

    if (data?.file) {
      for (let file of data?.file) {
        formData.append("file", file);
      }
    }

    createAffiliate(formData)
      .then((res) => {
        NotificationManager.success("Филал успешно создан");
        if (res?.length > 0 && res[0]?.id) {
          dispatch(setAffiliates(res));
        }
        navigate(-1);
      })
      .catch((err) => {
        NotificationManager.error(
          err.response.data.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  if (module.loading) {
    return <Loader full />;
  }

  return (
    <>
      <Meta title="Создать филиал" />
      <div className="d-flex justify-content-between align-items-center">
        <Link
          to="/options/affiliates"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
        <Button onClick={handleSubmit(onSubmit)}>Сохранить изменения</Button>
      </div>
      <h3 className="mb-4">Создать филиал</h3>
      <Row>
        <Col md={6}>
          <Row>
            <Col>
              <div className="mb-4">
                <DragDropFile
                  col={6}
                  file={data.file}
                  onChange={(e) => setValue("file", e)}
                  onDelete={(e) => setValue("file", e.file)}
                />
              </div>
            </Col>
            {data?.media && (
              <Col>
                <PreviewImages
                  medias={data?.media}
                  type="affiliate"
                  col={12}
                  onDelete={(e) => {
                    setValue("media", e.media);
                    let newArrayDelete =
                      data?.delete?.length > 0
                        ? [...data.delete, e.delete]
                        : [e.delete];
                    setValue("delete", newArrayDelete);
                  }}
                />
              </Col>
            )}
          </Row>
        </Col>
        <Col md={6}>
          <Form.Check className="mb-4">
            <Form.Check.Input
              type="checkbox"
              name="status"
              id="status"
              defaultChecked={!!data?.status}
              {...register("status")}
            />
            <Form.Check.Label htmlFor="status" className="ms-2">
              Филиал Работает\Не работает
            </Form.Check.Label>
          </Form.Check>
          <Form.Check className="mb-4 d-inline-block">
            <Form.Check.Input
              type="checkbox"
              name="main"
              id="main"
              value={1}
              defaultChecked={getValues("main")}
              {...register("main")}
            />
            <Form.Check.Label htmlFor="main" className="ms-2">
              Адрес по умолчанию
            </Form.Check.Label>
          </Form.Check>
          <div className="mb-4">
            <Input
              errors={errors}
              label="Название"
              name="title"
              placeholder="Введите название (Необязательно)"
              register={register}
              validation={{
                maxLength: { value: 150, message: "Максимум 150 символов" },
              }}
            />
          </div>

          <div className="mb-4">
            <Textarea
              label="Описание"
              name="desc"
              placeholder="Введите описание (Необязательно)"
              errors={errors}
              rows={4}
              register={register}
              validation={{
                maxLength: { value: 1500, message: "Максимум 1500 символов" },
              }}
            />
          </div>
        </Col>
        <Col md={12}>
          <div className="mb-2 position-relative">
            <Input
              errors={errors}
              label="Адрес"
              onKeyDown={(e) => onKeyDown(e)}
              onClick={() => setShowDropdown(true)}
              type="search"
              autoComplete="off"
              name="full"
              placeholder="Введите адрес"
              register={register}
              validation={{
                required: "Обязательное поле",
                maxLength: { value: 250, message: "Максимум 250 символов" },
              }}
            />
            {showDropdown && streets?.length > 0 && (
              <Dropdown.Menu
                onClick={() => setShowDropdown(false)}
                show
                className="w-100 custom-input-street"
              >
                {streets.map(
                  (item, key) =>
                    item && (
                      <Dropdown.Item
                        onClick={() => clickAffiliate(item)}
                        key={key}
                      >
                        {item.value}
                      </Dropdown.Item>
                    )
                )}
              </Dropdown.Menu>
            )}
          </div>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <Select
            placeholder="Выберите часовой пояс"
            value={data.options.timezone}
            onClick={(e) => setValue("options.timezone", e.value)}
            data={timezones}
          />
        </Col>
      </Row>
      <a
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        className="d-inline-block mb-4 fs-09 text-muted"
      >
        Показать подробнее
      </a>

      <Collapse in={open}>
        <Row>
          <Col md={3}>
            <div className="mb-4">
              <Input
                readOnly={false}
                errors={errors}
                label="Страна"
                name="options.country"
                placeholder="Введите страну"
                register={register}
                validation={{
                  required: "Обязательное поле",
                  maxLength: { value: 150, message: "Максимум 150 символов" },
                }}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-4">
              <Input
                readOnly={false}
                errors={errors}
                label="Регион"
                name="options.region"
                placeholder="Введите регион"
                register={register}
                validation={{
                  required: "Обязательное поле",
                  maxLength: { value: 150, message: "Максимум 150 символов" },
                }}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-4">
              <Input
                readOnly={false}
                errors={errors}
                label="Город"
                name="options.city"
                placeholder="Введите город"
                register={register}
                validation={{
                  maxLength: { value: 150, message: "Максимум 150 символов" },
                }}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-4">
              <Input
                readOnly={false}
                errors={errors}
                label="Улица"
                name="options.street"
                placeholder="Введите улицу"
                register={register}
                validation={{
                  maxLength: { value: 250, message: "Максимум 250 символов" },
                }}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-4">
              <Input
                readOnly={false}
                errors={errors}
                label="Дом"
                name="options.house"
                placeholder="Введите дом"
                register={register}
                validation={{
                  maxLength: { value: 30, message: "Максимум 30 символов" },
                }}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-4">
              <Input
                readOnly={false}
                errors={errors}
                label="Корпус"
                name="options.block"
                placeholder="Введите корпус"
                register={register}
                validation={{
                  maxLength: { value: 30, message: "Максимум 30 символов" },
                }}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-4">
              <Input
                readOnly={false}
                errors={errors}
                label="Квартира"
                name="options.flat"
                placeholder="Введите квартиру"
                register={register}
                validation={{
                  maxLength: { value: 30, message: "Максимум 30 символов" },
                }}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-4">
              <Input
                readOnly={false}
                errors={errors}
                label="Индекс"
                name="options.postal_code"
                placeholder="Введите индекс"
                register={register}
                validation={{
                  maxLength: { value: 30, message: "Максимум 30 символов" },
                }}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-4">
              <Input
                readOnly={false}
                errors={errors}
                label="Широта"
                type="number"
                name="options.coordinates.lat"
                placeholder="Введите широту"
                register={register}
                validation={{
                  required: "Обязательное поле",
                  maxLength: { value: 50, message: "Максимум 50 символов" },
                }}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-4">
              <Input
                readOnly={false}
                errors={errors}
                label="Долгота"
                type="number"
                name="options.coordinates.lon"
                placeholder="Введите долготу"
                register={register}
                validation={{
                  required: "Обязательное поле",
                  maxLength: { value: 50, message: "Максимум 50 символов" },
                }}
              />
            </div>
          </Col>
        </Row>
      </Collapse>

      <Row>
        <Col md={6}>
          <h5 className="mb-2">Номер телефона</h5>
          <div className="mb-4">
            <Input
              errors={errors}
              name="phone"
              placeholder="Введите номер телефона"
              register={register}
            />
          </div>
          <h5 className="mb-2">Скидка на самовывоз</h5>
          <p className="text-muted fs-09 mb-3">
            Скидка не работает с интеграцией
          </p>
          <div className="mb-4">
            <Input
              errors={errors}
              label="Кол-во скидки"
              name="options.discountPickup"
              placeholder="Укажите процент (Необязательно)"
              register={register}
            />
          </div>
          <h5 className="mb-2">Привязка к городам</h5>
          <p className="text-muted fs-09 mb-3">
            Выберите из списка города, либо{" "}
            <Link
              to="/options/affiliates/cities/create"
              className="text-success"
            >
              создайте город
            </Link>
          </p>
          {data?.cities?.length > 0 &&
            data.cities.map((item, index) => {
              return (
                <div className="mb-2 d-flex flex-row align-items-center">
                  <Select
                    classNameContainer="w-100"
                    value={item?.title ?? null}
                    onClick={(e) => {
                      if (data.cities.find((city) => city.value === e.value)) {
                        return NotificationManager.error(
                          "Данный город уже добавлен"
                        );
                      }
                      setValue(
                        `cities.${index}`,
                        cities.find((city) => city.value === e.value)
                      );
                    }}
                    data={cities}
                  />
                  <Button
                    className="btn-light ms-2"
                    onClick={() =>
                      setValue(
                        `cities`,
                        data.cities.filter((e) => e.value != item.value)
                      )
                    }
                  >
                    <IoTrashOutline size={20} />
                  </Button>
                </div>
              );
            })}
          <a
            className="text-success fs-09"
            onClick={() =>
              setValue(
                `cities`,
                data?.cities?.length > 0
                  ? [...data.cities, { title: null, value: null }]
                  : [{ title: null, value: null }]
              )
            }
          >
            Добавить из списка
          </a>
        </Col>
        <Col md={6}>
          <h5 className="mb-2">Интеграция</h5>
          <p className="text-muted fs-09 mb-3">
            Укажите идентификатор филиала если вы используйте интеграцию
          </p>
          <div className="mb-4">
            {module?.data?.length > 0 ? (
              <Select
                value={data.apiId}
                onClick={(e) => setValue("apiId", e.value)}
                data={module.data}
              />
            ) : (
              <Input
                errors={errors}
                label="Идентификатор"
                name="apiId"
                placeholder="Идентификатор (Необязательно)"
                register={register}
              />
            )}
          </div>
          <h5 className="mb-3">Предзаказ</h5>
          <div className="mb-2">
            <Row>
              <Col>
                <Input
                  label="Минимально минут"
                  type="number"
                  name="options.preorderMin"
                  register={register}
                />
                <p className="text-muted fs-09 mt-2 mb-3">
                  Минимальное время в минутах до предзаказа
                </p>
              </Col>
              <Col>
                <Input
                  label="Максимально дней"
                  type="number"
                  name="options.preorderMax"
                  register={register}
                />
                <p className="text-muted fs-09 mt-2 mb-3">
                  Максимально дней предзаказа
                </p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <h5 className="mb-2 mt-3">Время работы</h5>
          <p className="text-muted fs-09 mb-4">
            Если вы работайте в какой либо из дней круглосуточно, оставьте поле
            пустым
          </p>
          <div className="mb-3 d-flex align-items-center">
            <div className="d-flex align-items-center">
              <span className="me-2 work-label">Все</span>
              <Input
                className="me-2"
                label="Начало"
                type="time"
                onChange={(event) =>
                  setValue(
                    "options.work",
                    data.options?.work?.length > 0
                      ? data.options.work.map((e) => ({
                          ...e,
                          start: event,
                        }))
                      : work.map((e) => ({
                          ...e,
                          start: event,
                        }))
                  )
                }
              />
              <Input
                label="Конец"
                type="time"
                onChange={(event) =>
                  setValue(
                    "options.work",
                    data.options?.work?.length > 0
                      ? data.options.work.map((e) => ({
                          ...e,
                          end: event,
                        }))
                      : work.map((e) => ({
                          ...e,
                          end: event,
                        }))
                  )
                }
              />
            </div>
            <div>
              <Form.Check className="my-2 ms-2">
                <Form.Check.Input
                  type="checkbox"
                  id="status-all"
                  checked={
                    data.options?.work &&
                    !!!data.options?.work.find((e) => !e.status)
                  }
                  onChange={(event) =>
                    setValue(
                      "options.work",
                      data.options?.work?.length > 0
                        ? data.options.work.map((e) => ({
                            ...e,
                            status: event.target.checked,
                          }))
                        : work.map((e) => ({
                            ...e,
                            status: event.target.checked,
                          }))
                    )
                  }
                />
                <Form.Check.Label htmlFor="status-all" className="ms-2">
                  Работает
                </Form.Check.Label>
              </Form.Check>
            </div>
          </div>
          <hr />
          <div className="mb-3 d-flex align-items-center">
            <div className="d-flex align-items-center">
              <span className="me-2 work-label">Пн -</span>
              <Input
                className="me-2"
                label="Начало"
                type="time"
                name="options.work.0.start"
                register={register}
              />
              <Input
                label="Конец"
                type="time"
                name="options.work.0.end"
                register={register}
              />
            </div>
            <div>
              <Form.Check className="my-2 ms-2">
                <Form.Check.Input
                  type="checkbox"
                  id="status-0"
                  {...register("options.work.0.status")}
                />
                <Form.Check.Label htmlFor="status-0" className="ms-2">
                  Работает
                </Form.Check.Label>
              </Form.Check>
            </div>
          </div>
          <div className="mb-3 d-flex align-items-center">
            <div className="d-flex align-items-center">
              <span className="me-2 work-label">Вт -</span>
              <Input
                className="me-2"
                label="Начало"
                type="time"
                name="options.work.1.start"
                register={register}
              />
              <Input
                label="Конец"
                type="time"
                name="options.work.1.end"
                register={register}
              />
            </div>
            <div>
              <Form.Check className="my-2 ms-2">
                <Form.Check.Input
                  type="checkbox"
                  id="status-1"
                  {...register("options.work.1.status")}
                />
                <Form.Check.Label htmlFor="status-1" className="ms-2">
                  Работает
                </Form.Check.Label>
              </Form.Check>
            </div>
          </div>
          <div className="mb-3 d-flex align-items-center">
            <div className="d-flex align-items-center">
              <span className="me-2 work-label">Ср -</span>
              <Input
                className="me-2"
                label="Начало"
                type="time"
                name="options.work.2.start"
                register={register}
              />
              <Input
                label="Конец"
                type="time"
                name="options.work.2.end"
                register={register}
              />
            </div>
            <div>
              <Form.Check className="my-2 ms-2">
                <Form.Check.Input
                  type="checkbox"
                  id="status-2"
                  {...register("options.work.2.status")}
                />
                <Form.Check.Label htmlFor="status-2" className="ms-2">
                  Работает
                </Form.Check.Label>
              </Form.Check>
            </div>
          </div>
          <div className="mb-3 d-flex align-items-center">
            <div className="d-flex align-items-center">
              <span className="me-2 work-label">Чт -</span>
              <Input
                className="me-2"
                label="Начало"
                type="time"
                name="options.work.3.start"
                register={register}
              />
              <Input
                label="Конец"
                type="time"
                name="options.work.3.end"
                register={register}
              />
            </div>
            <div>
              <Form.Check className="my-2 ms-2">
                <Form.Check.Input
                  type="checkbox"
                  id="status-3"
                  {...register("options.work.3.status")}
                />
                <Form.Check.Label htmlFor="status-3" className="ms-2">
                  Работает
                </Form.Check.Label>
              </Form.Check>
            </div>
          </div>
          <div className="mb-3 d-flex align-items-center">
            <div className="d-flex align-items-center">
              <span className="me-2 work-label">Пт -</span>
              <Input
                className="me-2"
                label="Начало"
                type="time"
                name="options.work.4.start"
                register={register}
              />
              <Input
                label="Конец"
                type="time"
                name="options.work.4.end"
                register={register}
              />
            </div>
            <div>
              <Form.Check className="my-2 ms-2">
                <Form.Check.Input
                  type="checkbox"
                  id="status-4"
                  {...register("options.work.4.status")}
                />
                <Form.Check.Label htmlFor="status-4" className="ms-2">
                  Работает
                </Form.Check.Label>
              </Form.Check>
            </div>
          </div>
          <div className="mb-3 d-flex align-items-center">
            <div className="d-flex align-items-center">
              <span className="me-2 work-label">Сб -</span>
              <Input
                className="me-2"
                label="Начало"
                type="time"
                name="options.work.5.start"
                register={register}
              />
              <Input
                label="Конец"
                type="time"
                name="options.work.5.end"
                register={register}
              />
            </div>
            <div>
              <Form.Check className="my-2 ms-2">
                <Form.Check.Input
                  type="checkbox"
                  id="status-5"
                  {...register("options.work.5.status")}
                />
                <Form.Check.Label htmlFor="status-5" className="ms-2">
                  Работает
                </Form.Check.Label>
              </Form.Check>
            </div>
          </div>
          <div className="mb-4 d-flex align-items-center">
            <div className="d-flex align-items-center">
              <span className="me-2 work-label">Вс -</span>
              <Input
                className="me-2"
                label="Начало"
                type="time"
                name="options.work.6.start"
                register={register}
              />
              <Input
                label="Конец"
                type="time"
                name="options.work.6.end"
                register={register}
              />
            </div>
            <div>
              <Form.Check className="my-2 ms-2">
                <Form.Check.Input
                  type="checkbox"
                  id="status-6"
                  {...register("options.work.6.status")}
                />
                <Form.Check.Label htmlFor="status-6" className="ms-2">
                  Работает
                </Form.Check.Label>
              </Form.Check>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CreateAffiliate;
