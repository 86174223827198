import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Col, Dropdown, Form, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import useDebounce from "../../hooks/useDebounce";
import { editCity, getCity } from "../../services/city";
import { getDadataCity } from "../../services/dadata";

const CityEdit = () => {
  const { cityId } = useParams();
  const navigate = useNavigate();
  const [cities, setCities] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [loading, setLoading] = useState(true);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      status: 1,
    },
  });
  const data = useWatch({ control });

  const cityText = useDebounce(data.title, 1000);

  const clickCity = (city) => {
    setValue("title", city.data.city ?? city.data.settlement ?? null);
    setShowDropdown(false);
  };

  const onKeyDown = (e) => {
    if (e === "Enter" && cities?.length > 0) {
      clickCity(cities[0]);
      setCities([]);
    }
  };

  useEffect(() => {
    if (cityText) {
      getDadataCity(cityText).then((res) => {
        if (res?.data?.suggestions) {
          setCities(res.data.suggestions);
        }
      });
    }
  }, [cityText]);

  const onSubmit = useCallback((data) => {
    editCity(data)
      .then((res) => {
        NotificationManager.success("Город успешно обновлен");
        navigate(-1);
      })
      .catch((err) => {
        NotificationManager.error(
          err.response.data.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  useLayoutEffect(() => {
    getCity(cityId)
      .then(
        (res) =>
          res &&
          reset((prev) => ({
            ...prev,
            ...res,
          }))
      )
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <Loader full />;
  }

  return (
    <>
      <Meta title="Редактировать город" />
      <div className="d-flex justify-content-between align-items-center">
        <Link
          to="/options/affiliates/cities"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
        <Button onClick={handleSubmit(onSubmit)}>Сохранить изменения</Button>
      </div>
      <h3 className="mb-4">Редактировать город</h3>
      <Row>
        <Col md={6}>
          <div className="mb-4 position-relative">
            <Input
              errors={errors}
              label="Название"
              onKeyDown={(e) => onKeyDown(e)}
              onClick={() => setShowDropdown(true)}
              type="search"
              autoComplete="off"
              name="title"
              placeholder="Введите город"
              register={register}
              validation={{
                required: "Обязательное поле",
                maxLength: { value: 50, message: "Максимум 50 символов" },
              }}
            />
            {showDropdown && cities?.length > 0 && (
              <Dropdown.Menu
                onClick={() => setShowDropdown(false)}
                show
                className="w-100 custom-input-city"
              >
                {cities.map(
                  (item, key) =>
                    item && (
                      <Dropdown.Item onClick={() => clickCity(item)} key={key}>
                        {item.value}
                      </Dropdown.Item>
                    )
                )}
              </Dropdown.Menu>
            )}
          </div>
          <Form.Check className="mb-4">
            <Form.Check.Input
              type="checkbox"
              name="status"
              id="status"
              {...register("status")}
            />
            <Form.Check.Label htmlFor="status" className="ms-2">
              Город Работает\Не работает
            </Form.Check.Label>
          </Form.Check>
        </Col>
      </Row>
    </>
  );
};

export default CityEdit;
