import React, { useCallback, useState } from "react";
import { Col, Form, Row, Tab, Tabs, Card, Badge } from "react-bootstrap";
import { BlockPicker } from "react-color";
import { useForm, useWatch } from "react-hook-form";
import Button from "../../../components/UI/Button";
import Input from "../../../components/UI/Input";
import Textarea from "../../../components/UI/Textarea";
import Select from "../../../components/UI/Select";
import DragDropFile from "../../../components/DragDropFile";
import {
  IoAdd,
  IoCreateOutline,
  IoEllipse,
  IoRefresh,
  IoSaveOutline,
  IoTrashOutline,
} from "react-icons/io5";
import CustomModal from "../../../components/utils/CustomModal";
import { NotificationManager } from "react-notifications";
import PreviewImages from "../../../components/PreviewImages";
import { editModuleWeb, updateDomain } from "../../../services/module";
import { useSelector } from "react-redux";
import Loader from "../../../components/UI/Loader";
import { getImageURL } from "../../../helpers/image";
import { HexAlphaColorPicker } from "react-colorful";

const syncAuthData = [
  {
    title: "По почте",
    value: "email",
  },
  {
    title: "По номеру телефона",
    value: "phone",
  },
];

const syncThemeTypeData = [
  {
    title: "Системная (По умолчанию)",
    value: false,
  },
  {
    title: "Темная",
    value: "dark",
  },
  {
    title: "Светлая",
    value: "light",
  },
];

const syncThemeFontData = [
  {
    title: "Open Sans (Основной)",
    value: false,
  },
  // {
  //   title: "Roboto",
  //   value: "roboto",
  // },
  // {
  //   title: "Roboto Condensed",
  //   value: "robotocondensed",
  // },
  // {
  //   title: "Montserrat",
  //   value: "montserrat",
  // },
  // {
  //   title: "Lato",
  //   value: "lato",
  // },
  // {
  //   title: "Nunito",
  //   value: "nunito",
  // },
  {
    title: "Amatic SC",
    value: "amaticsc",
  },
];

const syncThemeFontSizeData = [
  {
    title: "Средний (Основной)",
    value: false,
  },
  {
    title: "Маленький",
    value: "small",
  },
  {
    title: "Большой",
    value: "big",
  },
  {
    title: "Очень большой",
    value: "large",
  },
];

const syncPointData = [
  {
    title: "Номер телефона",
    value: "phone",
  },
  {
    title: "Email",
    value: "email",
  },
  {
    title: "Идентификатор из интеграции",
    value: "apiId",
  },
];

const syncThemeProduct = [
  {
    title: "Средний размер изображения",
    value: 0,
  },
  {
    title: "Большой размер изображения",
    value: 1,
  },
];

const syncThemeProductImage = [
  {
    title: "Квадрат",
    value: 0,
  },
  {
    title: "Прямоугольник",
    value: 1,
  },
];

const syncProductColumn = [
  {
    title: "Один в ряд",
    value: 0,
  },
  {
    title: "Два в ряд",
    value: 1,
  },
];

const syncThemeAddition = [
  {
    title: "Список по вертикали",
    value: 0,
  },
  {
    title: "Список по горизонтали",
    value: 1,
  },
];

const pageData = [
  {
    title: "Главная",
    value: "/",
  },
  {
    title: "Каталог",
    value: "/categories",
  },
  {
    title: "Акции",
    value: "/promo",
  },
  {
    title: "Услуги",
    value: "/services",
  },
  {
    title: "Проекты",
    value: "/projects",
  },
  {
    title: "О нас",
    value: "/about",
  },
  {
    title: "Контакты",
    value: "/contact",
  },
  {
    title: "Портфолио",
    value: "/portfolio",
  },
  {
    title: "Новости",
    value: "/blogs",
  },
  {
    title: "Произвольная ссылка",
    value: false,
  },
];

const SiteForm = ({ data }) => {
  const brand = useSelector((state) => state?.brand?.active);

  const defaultMenu = {
    show: false,
    data: {
      title: null,
      page: "/",
      type: "light",
      link: null,
      mobile: false,
      icon: false,
      order: 0,
      status: true,
    },
  };
  const [showMenu, setShowMenu] = useState(defaultMenu);
  const [loadingRefreshDomain, setLoadingRefreshDomain] = useState({
    status: false,
    domain: false,
  });
  const [showMenuEdit, setShowMenuEdit] = useState(defaultMenu);

  const defaultWidget = {
    show: false,
    data: { title: null, value: null, desc: null, order: 0, status: true },
  };
  const [showWidget, setShowWidget] = useState(defaultWidget);
  const [showWidgetEdit, setShowWidgetEdit] = useState(defaultWidget);

  const widgetData =
    brand?.type == "food"
      ? [
          {
            title: "Привет блок",
            value: "hello",
          },
          {
            title: "Истории",
            value: "stories",
          },
          {
            title: "Баннеры",
            value: "banners",
          },
          {
            title: "Акции",
            value: "sales",
          },
          {
            title: "Меню",
            value: "menu",
          },
          {
            title: "Категории",
            value: "categories",
          },
          {
            title: "Квиз",
            value: "quiz",
          },
          {
            title: "Портфолио",
            value: "portfolio",
          },
          {
            title: "Обратная связь",
            value: "contact",
          },
          {
            title: "Новости",
            value: "blogs",
          },
          {
            title: "О нас",
            value: "about",
          },
          {
            title: "Популярное",
            value: "popular",
          },
        ]
      : [
          {
            title: "Привет блок",
            value: "hello",
          },
          {
            title: "Истории",
            value: "stories",
          },
          {
            title: "Баннеры",
            value: "banners",
          },
          {
            title: "Акции",
            value: "sales",
          },
          {
            title: "Категории",
            value: "categories",
          },
          {
            title: "Товары",
            value: "products",
          },
          {
            title: "Проекты",
            value: "projects",
          },
          {
            title: "Услуги",
            value: "services",
          },
          {
            title: "Квиз",
            value: "quiz",
          },
          {
            title: "Портфолио",
            value: "portfolio",
          },
          {
            title: "Обратная связь",
            value: "contact",
          },
          {
            title: "Новости",
            value: "blogs",
          },
          {
            title: "О нас",
            value: "about",
          },
          {
            title: "Популярное",
            value: "popular",
          },
        ];

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues:
      data && !Array.isArray(data)
        ? data
        : {
            cart: true,
            logo: "",
            menu: [],
            name: "",
            title: "",
            pickup: {
              card: true,
              cash: true,
              online: false,
            },
            qrType: "email",
            widget: [],
            domains: [],
            favicon: "",
            version: "0.0.1",
            authType: "email",
            colorBtn: "#ff5252",
            delivery: {
              card: true,
              cash: true,
              online: false,
            },
            colorMain: "#ff5252",
            metrikaId: "",
            themeType: "",
            multiBrand: false,
            giftVisible: false,
            promoVisible: false,
            supportVisible: true,
            themeProductColumn: 1,
            checkoutSuccessText: "",
            profilePointVisible: true,
            productEnergyVisible: false,
          },
  });

  const form = useWatch({ control });

  const onCreateMenu = () => {
    if (!showMenu?.data?.title) {
      NotificationManager.error("Нет текста пункта меню");
    }
    if (!showMenu?.data?.page && !showMenu?.data?.link) {
      NotificationManager.error("Нет раздела или ссылки пункта меню");
    }
    if (!showMenu?.data?.type) {
      NotificationManager.error("Нет стиля пункта меню");
    }
    let array =
      form?.relationModule?.options?.menu?.length > 0 &&
      Array.isArray(form.relationModule.options.menu)
        ? form.relationModule.options.menu
        : [];
    array.push(showMenu.data);
    setValue("relationModule.options.menu", array);
    setShowMenu(defaultMenu);
  };

  const onEditMenu = () => {
    if (!showMenuEdit?.data?.title) {
      return NotificationManager.error("Нет текста пункта меню");
    }
    if (!showMenuEdit?.data?.page && !showMenuEdit?.data?.link) {
      return NotificationManager.error("Нет раздела или ссылки пункта меню");
    }
    if (!showMenuEdit?.data?.type) {
      return NotificationManager.error("Нет стиля пункта меню");
    }
    let array =
      form?.relationModule?.options?.menu?.length > 0 &&
      Array.isArray(form.relationModule.options.menu)
        ? form.relationModule.options.menu
        : [];

    if (array.length > 0) {
      array[showMenuEdit.index] = showMenuEdit.data;
    }

    setValue("relationModule.options.menu", array);
    setShowMenuEdit(defaultMenu);
  };

  const onEditWidget = () => {
    if (!showWidgetEdit?.data?.value) {
      return NotificationManager.error("Нет типа виджета");
    }
    let array =
      form?.relationModule?.options?.widget?.length > 0 &&
      Array.isArray(form.relationModule.options.widget)
        ? form.relationModule.options.widget
        : [];

    if (array.length > 0) {
      array[showWidgetEdit.index] = showWidgetEdit.data;
    }

    setValue("relationModule.options.widget", array);
    setShowWidgetEdit(defaultWidget);
  };

  const onDeleteMenu = (index) => {
    if (
      form?.relationModule?.options?.menu?.length > 0 &&
      Array.isArray(form.relationModule.options.menu)
    ) {
      setValue(
        "relationModule.options.menu",
        form.relationModule.options.menu.filter((e, i) => i !== index)
      );
    }
  };

  const onCreateWidget = () => {
    if (!showWidget?.data?.value) {
      NotificationManager.error("Вы не выбрали виджет");
    }
    let array =
      form?.relationModule?.options?.widget?.length > 0 &&
      Array.isArray(form.relationModule.options.widget)
        ? form.relationModule.options.widget
        : [];
    array.push(showWidget.data);
    setValue("relationModule.options.widget", array);
    setShowWidget(defaultWidget);
  };

  const onDeleteWidget = (index) => {
    if (
      form?.relationModule?.options?.widget?.length > 0 &&
      Array.isArray(form.relationModule.options.widget)
    ) {
      setValue(
        "relationModule.options.widget",
        form.relationModule.options.widget.filter((e, i) => i !== index)
      );
    }
  };

  const onSubmit = useCallback((data) => {
    var formData = new FormData();

    formData.append("dataParse", JSON.stringify(data));

    if (data?.logo) {
      for (let file of data.logo) {
        formData.append("logo", file);
      }
    }
    if (data?.logodark) {
      for (let file of data.logodark) {
        formData.append("logodark", file);
      }
    }
    if (data?.favicon) {
      for (let file of data.favicon) {
        formData.append("favicon", file);
      }
    }
    if (data?.feedback) {
      for (let file of data.feedback) {
        formData.append("feedback", file);
      }
    }
    if (data?.auth) {
      for (let file of data.auth) {
        formData.append("auth", file);
      }
    }
    if (data?.widgetAbout) {
      for (let file of data.widgetAbout) {
        formData.append("widgetAbout", file);
      }
    }
    if (data?.widgetHello) {
      for (let file of data.widgetHello) {
        formData.append("widgetHello", file);
      }
    }
    editModuleWeb(formData)
      .then(() => {
        NotificationManager.success("Сайт успешно обновлен");
      })
      .catch(
        (err) => err && NotificationManager.error("Ошибка при сохранении сайта")
      );
  }, []);

  const onUpdateDomain = useCallback((domain) => {
    setLoadingRefreshDomain({ status: true, domain });
    updateDomain({ domain })
      .then(() => {
        NotificationManager.success("Домен успешно обновлен");
      })
      .catch(
        (err) =>
          err &&
          NotificationManager.error(
            "Домен не подключен к системе. Проверьте настройки у регистратора. Подключение может занять до 12 часов."
          )
      )
      .finally(() => setLoadingRefreshDomain({ status: false, domain: false }));
  }, []);

  return (
    <Card body className="mb-4">
      <h3 className="mb-4 fw-7">Настройки</h3>
      <Tabs defaultActiveKey={0} className="mb-3" fill>
        <Tab eventKey={0} title="Основное">
          <Row>
            <Col md={4}>
              <div className="mb-3">
                <DragDropFile
                  col={12}
                  title="Лого для светлой версии"
                  desc=" "
                  file={form.logo}
                  onChange={(e) => setValue("logo", e)}
                  onDelete={(e) => setValue("logo", e.file)}
                />
              </div>
              {form?.relationModule?.options?.logo && (
                <div className="mb-3 bg-light border rounded-3 p-3 justify-content-center d-flex">
                  <img
                    src={getImageURL({
                      path: form?.relationModule?.options?.logo,
                      type: "all/web/logo",
                      size: "full",
                    })}
                    width="100%"
                  />
                </div>
              )}
            </Col>
            <Col md={4}>
              <div className="mb-3">
                <DragDropFile
                  col={12}
                  desc=" "
                  title="Лого для темной версии"
                  file={form.logodark}
                  onChange={(e) => setValue("logodark", e)}
                  onDelete={(e) => setValue("logodark", e.file)}
                />
              </div>
              {form?.relationModule?.options?.logodark && (
                <div className="mb-3 bg-dark border-dark rounded-3 p-3 justify-content-center d-flex">
                  <img
                    src={getImageURL({
                      path: form?.relationModule?.options?.logodark,
                      type: "all/web/logo",
                      size: "full",
                    })}
                    width="100%"
                  />
                </div>
              )}
            </Col>
            <Col md={4}>
              <div className="mb-3">
                <DragDropFile
                  col={12}
                  title="Выберите favicon"
                  desc=" "
                  file={form.favicon}
                  maxAspect={1}
                  onChange={(e) => setValue("favicon", e)}
                  onDelete={(e) => setValue("favicon", e.file)}
                />
              </div>
              {form?.relationModule?.options?.favicon && (
                <div className="mb-3 p-3 justify-content-center d-flex">
                  <img
                    width="40"
                    src={getImageURL({
                      path: form?.relationModule?.options?.favicon,
                      type: "all/web/favicon",
                      size: "full",
                    })}
                  />
                </div>
              )}
            </Col>
            <Col md={12}>
              <Row>
                <Col md={6}>
                  <div className="mb-4">
                    <Input
                      className="mb-4"
                      label="Название"
                      name="relationModule.options.title"
                      errors={errors}
                      defaultValue={form?.relationModule?.options?.title}
                      register={register}
                      validation={{ required: "Обязательное поле" }}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-4">
                    <Input
                      label="Версия"
                      name="relationModule.options.version"
                      errors={errors}
                      defaultValue={form?.relationModule?.options?.version}
                      register={register}
                    />
                  </div>
                </Col>

                <Col md={6}>
                  <div className="mb-4">
                    <div className="d-flex align-items-center mb-3">
                      <Input
                        className="w-100"
                        label="Введите новый домен"
                        name="newDomain"
                        errors={errors}
                        register={register}
                        validation={{
                          pattern: {
                            value: /^([a-z\d]+(-[a-z\d]+)*\.)+[a-z]{2,}$/i,
                            message: "Неверный формат домена",
                          },
                        }}
                      />
                      <Button
                        className="btn-primary-outline ms-3"
                        disabled={!form.newDomain || errors?.newDomain}
                        onClick={() => {
                          if (form?.newDomain?.length > 0) {
                            let newData =
                              form.relationModule.options?.domains?.length > 0
                                ? [...form.relationModule.options.domains]
                                : [];
                            newData.push(form.newDomain);
                            setValue("relationModule.options.domains", newData);
                            setValue("newDomain", "");
                            handleSubmit(onSubmit);
                          }
                        }}
                      >
                        <IoSaveOutline size={20} />
                      </Button>
                    </div>
                    {form?.relationModule?.options?.domains?.length > 0 &&
                      form.relationModule.options.domains.map((e) => (
                        <p className="mb-2 d-flex align-items-center justify-content-between">
                          <span className="d-flex align-items-center">
                            <a
                              className={
                                form?.relationModule?.options?.name == e
                                  ? "fw-6"
                                  : ""
                              }
                              onClick={() =>
                                setValue("relationModule.options.name", e)
                              }
                            >
                              {e}
                              {form?.relationModule?.options?.name == e && (
                                <IoEllipse
                                  size={8}
                                  className="ms-2 text-success"
                                />
                              )}
                            </a>
                          </span>
                          <span className="d-flex align-items-center">
                            <a
                              className="ms-2"
                              onClick={() =>
                                !loadingRefreshDomain?.status &&
                                onUpdateDomain(e)
                              }
                            >
                              {loadingRefreshDomain?.status &&
                              loadingRefreshDomain?.domain == e ? (
                                <Loader size={16} />
                              ) : (
                                <IoRefresh size={16} className="ms-1" />
                              )}
                            </a>
                            <a
                              className="ms-3"
                              onClick={() => {
                                let newArray =
                                  form.relationModule.options.domains.filter(
                                    (item) => item != e
                                  );

                                setValue(
                                  "relationModule.options.domains",
                                  newArray
                                );
                              }}
                            >
                              <IoTrashOutline
                                size={16}
                                className="text-danger"
                              />
                            </a>
                          </span>
                        </p>
                      ))}
                  </div>
                </Col>
                <Col>
                  <Form.Check className="mb-4 d-inline-block me-3">
                    <Form.Check.Input
                      type="checkbox"
                      name="relationModule.options.multiBrand"
                      id="multiBrand"
                      defaultChecked={form?.relationModule?.options?.multiBrand}
                      {...register("relationModule.options.multiBrand")}
                    />
                    <Form.Check.Label htmlFor="multiBrand" className="ms-2">
                      Мультибред
                    </Form.Check.Label>
                    <small className="ms-2 text-muted fs-07">
                      Вкл\Откл выбор разных заведений
                    </small>
                  </Form.Check>
                </Col>
              </Row>
            </Col>
          </Row>
          <h5 className="mb-4 fw-7">Профиль</h5>
          <div className="mb-4">
            <Select
              label="Тип аутентификации"
              onClick={(e) =>
                setValue("relationModule.options.authType", e.value)
              }
              value={form?.relationModule?.options?.authType ?? "phone"}
              data={syncAuthData}
            />
          </div>
          <Form.Check className="mb-4 d-inline-block me-3">
            <Form.Check.Input
              type="checkbox"
              name="relationModule.options.profilePointVisible"
              id="profilePointVisible"
              defaultChecked={
                form?.relationModule?.options?.profilePointVisible
              }
              {...register("relationModule.options.profilePointVisible")}
            />
            <Form.Check.Label htmlFor="profilePointVisible" className="ms-2">
              Показывать бонусы в профиле
            </Form.Check.Label>
          </Form.Check>
          <div className="mb-4">
            <Select
              label="Идентификатор QR"
              onClick={(e) =>
                setValue("relationModule.options.qrType", e.value)
              }
              value={form?.relationModule?.options?.qrType ?? "phone"}
              data={syncPointData}
            />
          </div>
          <Form.Check className="mb-4 d-inline-block me-3">
            <Form.Check.Input
              type="checkbox"
              name="relationModule.options.supportVisible"
              id="supportVisible"
              defaultChecked={form?.relationModule?.options?.supportVisible}
              {...register("relationModule.options.supportVisible")}
            />
            <Form.Check.Label htmlFor="supportVisible" className="ms-2">
              Вкл\Выкл тех поддержку
            </Form.Check.Label>
          </Form.Check>
          <hr />
          <h5 className="mb-4 fw-7">Товар</h5>
          <Form.Check className="mb-4 d-inline-block me-3">
            <Form.Check.Input
              type="checkbox"
              name="relationModule.options.productEnergyVisible"
              id="productEnergyVisible"
              defaultChecked={
                form?.relationModule?.options?.productEnergyVisible
              }
              {...register("relationModule.options.productEnergyVisible")}
            />
            <Form.Check.Label htmlFor="productEnergyVisible" className="ms-2">
              Показать\Скрыть пищевую ценность
            </Form.Check.Label>
          </Form.Check>
          <hr />
          <h5 className="mb-4 fw-7">Корзина</h5>
          <Form.Check className="mb-4 d-inline-block me-3">
            <Form.Check.Input
              type="checkbox"
              name="relationModule.options.cart"
              id="cart"
              defaultChecked={form?.relationModule?.options?.cart}
              {...register("relationModule.options.cart")}
            />
            <Form.Check.Label htmlFor="cart" className="ms-2">
              Вкл\Выкл корзины
            </Form.Check.Label>
          </Form.Check>
          <hr />
          <h5 className="mb-4 fw-7">Оформление заказа</h5>
          <Form.Check className="mb-4 d-inline-block me-3">
            <Form.Check.Input
              type="checkbox"
              name="relationModule.options.promoVisible"
              id="promoVisible"
              defaultChecked={form?.relationModule?.options?.promoVisible}
              {...register("relationModule.options.promoVisible")}
            />
            <Form.Check.Label htmlFor="promoVisible" className="ms-2">
              Вкл\Выкл промокоды
            </Form.Check.Label>
          </Form.Check>
          <Form.Check className="mb-4 d-inline-block me-3">
            <Form.Check.Input
              type="checkbox"
              name="relationModule.options.giftVisible"
              id="giftVisible"
              defaultChecked={form?.relationModule?.options?.giftVisible}
              {...register("relationModule.options.giftVisible")}
            />
            <Form.Check.Label htmlFor="giftVisible" className="ms-2">
              Показать\Скрыть выбор подарков
            </Form.Check.Label>
          </Form.Check>
          <div className="mb-4">
            <Textarea
              label="Текст успешной отправки заказа"
              placeholder='Статус заказа вы можете узнать в личном профиле, в разделе "Заказы".'
              name="relationModule.options.checkoutSuccessText"
              errors={errors}
              register={register}
              validation={{
                maxLength: {
                  value: 1500,
                  message: "Максимально 500 символов",
                },
              }}
              defaultValue={form?.relationModule?.options?.checkoutSuccessText}
            />
          </div>
          <h5 className="mb-4 fw-7">Настройки Яндекс</h5>
          <Row>
            <Col md={6}>
              <Input
                className="mb-4"
                label="Идентификатор метрики"
                name="relationModule.options.metrikaId"
                errors={errors}
                defaultValue={form?.relationModule?.options?.metrikaId}
                register={register}
              />
            </Col>
            <Col md={6}>
              <div className="mb-4">
                <Input
                  label="Идентификатор подтверждения"
                  name="relationModule.options.yandexVerifyId"
                  errors={errors}
                  defaultValue={form?.relationModule?.options?.yandexVerifyId}
                  register={register}
                />
                <small className="text-muted fs-07">
                  Введите идентификатор Meta подтверждения из Яндекс Вебмастера
                </small>
              </div>
            </Col>
          </Row>
        </Tab>
        <Tab eventKey={1} title="Разделы">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="mb-2 fw-7">Меню</h5>
            <div>
              <a
                onClick={() => setShowMenu((prev) => ({ ...prev, show: true }))}
                className="btn-sm btn-primary-outline"
              >
                <IoAdd size={18} />
              </a>
            </div>
          </div>
          <div className="mb-4">
            {form?.relationModule?.options?.menu?.length > 0 &&
            Array.isArray(form.relationModule.options.menu) ? (
              form.relationModule.options.menu
                .sort((a, b) => a.order - b.order)
                .map((e, index) => (
                  <Row className="py-2 border-bottom d-flex flex-row justify-content-between align-items-center">
                    <Col className="text-nowrap">
                      <p className="text-muted fs-07">Текст</p>
                      {e.title}
                    </Col>
                    <Col>
                      <p className="text-muted fs-07">Раздел</p>
                      {e?.page ? e?.page : "Произвольная ссылка"}
                    </Col>
                    <Col>
                      <p className="text-muted fs-07">Стиль</p>
                      {e.type == "light" ? (
                        <Badge bg="secondary">Светлый</Badge>
                      ) : (
                        <Badge bg="dark">Темный</Badge>
                      )}
                    </Col>
                    <Col>
                      <p className="text-muted fs-07">Порядок</p>
                      {e.order ?? 0}
                    </Col>
                    <Col className="d-flex flex-row justify-content-between align-items-center">
                      <div className="me-3">
                        {e.status ? (
                          <Badge bg="success">Активно</Badge>
                        ) : (
                          <Badge bg="secondary">Архив</Badge>
                        )}
                      </div>
                      <div className="d-flex flex-row align-items-center">
                        <a
                          className="me-3"
                          onClick={() =>
                            setShowMenuEdit((prev) => ({
                              ...prev,
                              index,
                              data: e,
                              show: true,
                            }))
                          }
                        >
                          <IoCreateOutline size={20} />
                        </a>
                        <a onClick={() => onDeleteMenu(index)}>
                          <IoTrashOutline size={20} className="text-danger" />
                        </a>
                      </div>
                    </Col>
                  </Row>
                ))
            ) : (
              <p className="text-muted fs-09">Добавьте свои пункты меню</p>
            )}
            <CustomModal
              title="Добавить пункт меню"
              show={showMenu?.show}
              setShow={(e) => setShowMenu((prev) => ({ ...prev, show: e }))}
              footer={
                <>
                  <Button
                    className=" me-3"
                    onClick={() =>
                      setShowMenu((prev) => ({ ...prev, show: false }))
                    }
                  >
                    Отмена
                  </Button>
                  <Button
                    className="btn-primary"
                    onClick={() => onCreateMenu()}
                  >
                    Сохранить
                  </Button>
                </>
              }
            >
              <Input
                className="mb-4"
                label="Текст"
                placeholder="Введите текст"
                onChange={(e) =>
                  setShowMenu((prev) => ({
                    ...prev,
                    data: { ...prev.data, title: e },
                  }))
                }
              />
              <Select
                className="mb-4"
                label="Раздел"
                onClick={(e) =>
                  setShowMenu((prev) => ({
                    ...prev,
                    data: { ...prev.data, page: e.value },
                  }))
                }
                value={showMenu?.data?.page}
                data={pageData}
              />
              <Select
                className="mb-4"
                label="Стиль"
                onClick={(e) =>
                  setShowMenu((prev) => ({
                    ...prev,
                    data: { ...prev.data, type: e.value },
                  }))
                }
                value={showMenu?.data?.type}
                data={[
                  { title: "Светлый", value: "light" },
                  { title: "Темный", value: "dark" },
                ]}
              />
              <Input
                className="mb-4"
                label="Порядок"
                type="number"
                placeholder="0"
                onChange={(e) =>
                  setShowMenu((prev) => ({
                    ...prev,
                    data: { ...prev.data, order: e },
                  }))
                }
              />
              <Input
                className="mb-4"
                label="Иконка"
                placeholder="Название иконки ionicons"
                errors={errors}
                onChange={(e) =>
                  setShowMenu((prev) => ({
                    ...prev,
                    data: { ...prev.data, icon: e },
                  }))
                }
              />
              {showMenu?.data?.page === false && (
                <Input
                  className="mb-4"
                  label="Ссылка"
                  errors={errors}
                  onChange={(e) =>
                    setShowMenu((prev) => ({
                      ...prev,
                      data: { ...prev.data, link: e },
                    }))
                  }
                />
              )}
              <div className="d-flex">
                <Form.Check className="me-3 d-inline-block me-3">
                  <Form.Check.Input
                    type="checkbox"
                    id="menu-status"
                    defaultChecked={showMenu.data.status}
                    onChange={(e) =>
                      setShowMenu((prev) => ({
                        ...prev,
                        data: { ...prev.data, status: e },
                      }))
                    }
                  />
                  <Form.Check.Label htmlFor="menu-status" className="ms-2">
                    Показать\Скрыть
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check>
                  <Form.Check.Input
                    type="checkbox"
                    id="mobile-status"
                    defaultChecked={showMenu.data.mobile}
                    onChange={(e) =>
                      setShowMenu((prev) => ({
                        ...prev,
                        data: { ...prev.data, mobile: e },
                      }))
                    }
                  />
                  <Form.Check.Label htmlFor="mobile-status" className="ms-2">
                    Показать в моб версии
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </CustomModal>
            <CustomModal
              title="Редактировать меню"
              show={showMenuEdit?.show}
              setShow={(e) => setShowMenuEdit((prev) => ({ ...prev, show: e }))}
              footer={
                <>
                  <Button
                    className=" me-3"
                    onClick={() =>
                      setShowMenuEdit((prev) => ({ ...prev, show: false }))
                    }
                  >
                    Отмена
                  </Button>
                  <Button className="btn-primary" onClick={() => onEditMenu()}>
                    Сохранить
                  </Button>
                </>
              }
            >
              <Input
                defaultValue={showMenuEdit.data.title}
                className="mb-4"
                label="Текст"
                placeholder="Введите текст"
                onChange={(e) =>
                  setShowMenuEdit((prev) => ({
                    ...prev,
                    data: { ...prev.data, title: e },
                  }))
                }
              />
              <Select
                value={showMenuEdit.data.page}
                className="mb-4"
                label="Раздел"
                onClick={(e) =>
                  setShowMenuEdit((prev) => ({
                    ...prev,
                    data: { ...prev.data, page: e.value },
                  }))
                }
                data={pageData}
              />
              <Select
                value={showMenuEdit.data.type}
                className="mb-4"
                label="Стиль"
                onClick={(e) =>
                  setShowMenuEdit((prev) => ({
                    ...prev,
                    data: { ...prev.data, type: e.value },
                  }))
                }
                data={[
                  { title: "Светлый", value: "light" },
                  { title: "Темный", value: "dark" },
                ]}
              />
              <Input
                defaultValue={showMenuEdit.data.order}
                className="mb-4"
                label="Порядок"
                type="number"
                placeholder="0"
                onChange={(e) =>
                  setShowMenuEdit((prev) => ({
                    ...prev,
                    data: { ...prev.data, order: e },
                  }))
                }
              />
              <Input
                defaultValue={showMenuEdit.data.icon}
                className="mb-4"
                label="Иконка"
                placeholder="Название иконки ionicons"
                errors={errors}
                onChange={(e) =>
                  setShowMenuEdit((prev) => ({
                    ...prev,
                    data: { ...prev.data, icon: e },
                  }))
                }
              />
              {showMenu?.data?.page === false && (
                <Input
                  defaultValue={showMenuEdit.data.link}
                  className="mb-4"
                  label="Ссылка"
                  errors={errors}
                  onChange={(e) =>
                    setShowMenuEdit((prev) => ({
                      ...prev,
                      data: { ...prev.data, link: e },
                    }))
                  }
                />
              )}
              <div className="d-flex">
                <Form.Check className="me-3 d-inline-block me-3">
                  <Form.Check.Input
                    type="checkbox"
                    id="menu-status-edit"
                    defaultChecked={showMenuEdit.data.status}
                    onChange={(e) =>
                      setShowMenuEdit((prev) => ({
                        ...prev,
                        data: { ...prev.data, status: e.target.checked },
                      }))
                    }
                  />
                  <Form.Check.Label htmlFor="menu-status-edit" className="ms-2">
                    Показать\Скрыть
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check>
                  <Form.Check.Input
                    type="checkbox"
                    id="mobile-status-edit"
                    defaultChecked={showMenuEdit.data.mobile}
                    onChange={(e) =>
                      setShowMenuEdit((prev) => ({
                        ...prev,
                        data: { ...prev.data, mobile: e.target.checked },
                      }))
                    }
                  />
                  <Form.Check.Label
                    htmlFor="mobile-status-edit"
                    className="ms-2"
                  >
                    Показать в моб версии
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </CustomModal>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="mb-2 fw-7">Главная</h5>
            <div>
              <a
                onClick={() =>
                  setShowWidget((prev) => ({ ...prev, show: true }))
                }
                className="btn-sm btn-primary-outline"
              >
                <IoAdd size={18} />
              </a>
            </div>
          </div>
          <div className="mb-4">
            {form?.relationModule?.options?.widget?.length > 0 &&
            Array.isArray(form.relationModule.options.widget) ? (
              form.relationModule.options.widget
                .sort((a, b) => a.order - b.order)
                .map((e, index) => (
                  <Row className="py-2 border-bottom d-flex flex-row justify-content-between align-items-center">
                    <Col className="text-nowrap">
                      <p className="text-muted fs-07">Заголовок</p>
                      {e?.title?.length > 0 ? e.title : "Нет заголовка"}
                    </Col>
                    <Col>
                      <p className="text-muted fs-07">Виджет</p>
                      {e.value}
                    </Col>
                    <Col>
                      <p className="text-muted fs-07">Порядок</p>
                      {e.order ?? 0}
                    </Col>
                    <Col className="d-flex flex-row justify-content-between align-items-center">
                      <a className="me-3">
                        {e.status ? (
                          <Badge bg="success">Активно</Badge>
                        ) : (
                          <Badge bg="secondary">Архив</Badge>
                        )}
                      </a>
                      <div className="d-flex flex-row align-items-center">
                        <a
                          className="me-3"
                          onClick={() =>
                            setShowWidgetEdit((prev) => ({
                              ...prev,
                              index,
                              data: e,
                              show: true,
                            }))
                          }
                        >
                          <IoCreateOutline size={20} />
                        </a>
                        <a onClick={() => onDeleteWidget(index)}>
                          <IoTrashOutline size={20} className="text-danger" />
                        </a>
                      </div>
                    </Col>
                  </Row>
                ))
            ) : (
              <p className="text-muted fs-09">Выберите виджет из списка</p>
            )}
            <CustomModal
              title="Добавить виджет"
              show={showWidget?.show}
              setShow={(e) => setShowWidget((prev) => ({ ...prev, show: e }))}
              footer={
                <>
                  <Button
                    className=" me-3"
                    onClick={() =>
                      setShowWidget((prev) => ({ ...prev, show: false }))
                    }
                  >
                    Отмена
                  </Button>
                  <Button
                    className="btn-primary"
                    onClick={() => onCreateWidget()}
                  >
                    Сохранить
                  </Button>
                </>
              }
            >
              <Select
                className="mb-4"
                label="Виджет"
                onClick={(e) =>
                  setShowWidget((prev) => ({
                    ...prev,
                    data: { ...prev.data, value: e.value },
                  }))
                }
                value={showWidget?.data?.value}
                data={widgetData}
              />
              {showWidget?.data?.value == "stories" && (
                <>
                  <Select
                    className="mb-4"
                    label="Вид"
                    onClick={(e) =>
                      setShowWidget((prev) => ({
                        ...prev,
                        data: { ...prev.data, type: e.value },
                      }))
                    }
                    value={showWidget?.data?.type ?? ""}
                    data={[
                      { title: "Круглые", value: "" },
                      { title: "Квадратные", value: "square" },
                      { title: "Прямоугольные", value: "rectangle" },
                    ]}
                  />
                  <Select
                    className="mb-4"
                    label="Размер"
                    onClick={(e) =>
                      setShowWidget((prev) => ({
                        ...prev,
                        data: { ...prev.data, size: e.value },
                      }))
                    }
                    value={showWidget?.data?.size ?? ""}
                    data={[
                      { title: "Средний", value: "" },
                      { title: "Большой", value: "big" },
                    ]}
                  />
                </>
              )}
              {showWidget?.data?.value == "banners" && (
                <Select
                  className="mb-4"
                  label="Размер"
                  onClick={(e) =>
                    setShowWidget((prev) => ({
                      ...prev,
                      data: { ...prev.data, size: e.value },
                    }))
                  }
                  value={showWidget?.data?.size ?? ""}
                  data={[
                    { title: "Малый", value: "" },
                    { title: "Средний", value: "middle" },
                    { title: "Большой", value: "big" },
                  ]}
                />
              )}
              <Input
                className="mb-4"
                label="Заголовок"
                placeholder="Введите заголовок"
                onChange={(e) =>
                  setShowWidget((prev) => ({
                    ...prev,
                    data: { ...prev.data, title: e },
                  }))
                }
              />
              <Textarea
                className="mb-4"
                label="Описание"
                placeholder="Введите описание (Необязательно)"
                onChange={(e) =>
                  setShowWidget((prev) => ({
                    ...prev,
                    data: { ...prev.data, desc: e },
                  }))
                }
              />
              {showWidget?.data?.value == "hello" && (
                <>
                  <Input
                    className="mb-4"
                    label="Текст кнопки"
                    placeholder="Введите текст"
                    onChange={(e) =>
                      setShowWidgetEdit((prev) => ({
                        ...prev,
                        data: { ...prev.data, btnText: e },
                      }))
                    }
                  />
                  <Input
                    className="mb-4"
                    label="Ссылка кнопки"
                    placeholder="Введите ссылку"
                    onChange={(e) =>
                      setShowWidgetEdit((prev) => ({
                        ...prev,
                        data: { ...prev.data, btnLink: e },
                      }))
                    }
                  />
                </>
              )}

              <Input
                className="mb-4"
                label="Порядок"
                type="number"
                placeholder="0"
                onChange={(e) =>
                  setShowWidget((prev) => ({
                    ...prev,
                    data: { ...prev.data, order: e },
                  }))
                }
              />
              <Form.Check>
                <Form.Check.Input
                  type="checkbox"
                  id="widget-status"
                  defaultChecked={showWidget.data.status}
                  onChange={(e) =>
                    setShowWidget((prev) => ({
                      ...prev,
                      data: { ...prev.data, status: e.target.checked },
                    }))
                  }
                />
                <Form.Check.Label htmlFor="widget-status" className="ms-2">
                  Показать\Скрыть
                </Form.Check.Label>
              </Form.Check>
              {showWidget?.data?.value == "about" ? (
                <div className="mt-3">
                  <DragDropFile
                    col={12}
                    title="Выберите картинку"
                    file={form.widgetAbout}
                    onChange={(e) => setValue("widgetAbout", e)}
                    onDelete={(e) => setValue("widgetAbout", e.file)}
                  />
                </div>
              ) : (
                showWidget?.data?.value == "hello" && (
                  <div className="mt-3">
                    <DragDropFile
                      col={12}
                      title="Выберите фон"
                      file={form.widgetHello}
                      onChange={(e) => setValue("widgetHello", e)}
                      onDelete={(e) => setValue("widgetHello", e.file)}
                    />
                  </div>
                )
              )}
            </CustomModal>
            <CustomModal
              title="Редактировать виджет"
              show={showWidgetEdit?.show}
              setShow={(e) =>
                setShowWidgetEdit((prev) => ({ ...prev, show: e }))
              }
              footer={
                <>
                  <Button
                    className=" me-3"
                    onClick={() =>
                      setShowWidgetEdit((prev) => ({ ...prev, show: false }))
                    }
                  >
                    Отмена
                  </Button>
                  <Button
                    className="btn-primary"
                    onClick={() => onEditWidget()}
                  >
                    Сохранить
                  </Button>
                </>
              }
            >
              <Select
                className="mb-4"
                label="Виджет"
                onClick={(e) =>
                  setShowWidgetEdit((prev) => ({
                    ...prev,
                    data: { ...prev.data, value: e.value },
                  }))
                }
                value={showWidgetEdit?.data?.value}
                data={widgetData}
              />
              {showWidgetEdit?.data?.value == "stories" && (
                <>
                  <Select
                    className="mb-4"
                    label="Вид"
                    onClick={(e) =>
                      setShowWidgetEdit((prev) => ({
                        ...prev,
                        data: { ...prev.data, type: e.value },
                      }))
                    }
                    value={showWidgetEdit?.data?.type ?? ""}
                    data={[
                      { title: "Круглые", value: "" },
                      { title: "Квадратные", value: "square" },
                      { title: "Прямоугольные", value: "rectangle" },
                    ]}
                  />
                  <Select
                    className="mb-4"
                    label="Размер"
                    onClick={(e) =>
                      setShowWidgetEdit((prev) => ({
                        ...prev,
                        data: { ...prev.data, size: e.value },
                      }))
                    }
                    value={showWidgetEdit?.data?.size ?? ""}
                    data={[
                      { title: "Средний", value: "" },
                      { title: "Большой", value: "big" },
                    ]}
                  />
                </>
              )}
              {showWidgetEdit?.data?.value == "banners" && (
                <Select
                  className="mb-4"
                  label="Размер"
                  onClick={(e) =>
                    setShowWidgetEdit((prev) => ({
                      ...prev,
                      data: { ...prev.data, size: e.value },
                    }))
                  }
                  value={showWidgetEdit?.data?.size ?? ""}
                  data={[
                    { title: "Малый", value: "" },
                    { title: "Средний", value: "middle" },
                    { title: "Большой", value: "big" },
                  ]}
                />
              )}
              <Input
                defaultValue={showWidgetEdit.data.title}
                className="mb-4"
                label="Заголовок"
                placeholder="Введите заголовок"
                onChange={(e) =>
                  setShowWidgetEdit((prev) => ({
                    ...prev,
                    data: { ...prev.data, title: e },
                  }))
                }
              />
              <Textarea
                defaultValue={showWidgetEdit.data.desc}
                className="mb-4"
                label="Описание"
                placeholder="Введите описание (Необязательно)"
                onChange={(e) =>
                  setShowWidgetEdit((prev) => ({
                    ...prev,
                    data: { ...prev.data, desc: e },
                  }))
                }
              />
              {showWidgetEdit?.data?.value == "hello" && (
                <>
                  <Input
                    defaultValue={showWidgetEdit.data.btnText}
                    className="mb-4"
                    label="Текст кнопки"
                    placeholder="Введите текст"
                    onChange={(e) =>
                      setShowWidgetEdit((prev) => ({
                        ...prev,
                        data: { ...prev.data, btnText: e },
                      }))
                    }
                  />
                  <Input
                    defaultValue={showWidgetEdit.data.btnLink}
                    className="mb-4"
                    label="Ссылка кнопки"
                    placeholder="Введите ссылку"
                    onChange={(e) =>
                      setShowWidgetEdit((prev) => ({
                        ...prev,
                        data: { ...prev.data, btnLink: e },
                      }))
                    }
                  />
                </>
              )}
              <Input
                defaultValue={showWidgetEdit.data.order}
                className="mb-4"
                label="Порядок"
                type="number"
                placeholder="0"
                onChange={(e) =>
                  setShowWidgetEdit((prev) => ({
                    ...prev,
                    data: { ...prev.data, order: e },
                  }))
                }
              />
              <Form.Check>
                <Form.Check.Input
                  type="checkbox"
                  id="widget-status-edit"
                  defaultChecked={showWidgetEdit.data.status}
                  onChange={(e) =>
                    setShowWidgetEdit((prev) => ({
                      ...prev,
                      data: { ...prev.data, status: e.target.checked },
                    }))
                  }
                />
                <Form.Check.Label htmlFor="widget-status-edit" className="ms-2">
                  Показать\Скрыть
                </Form.Check.Label>
              </Form.Check>
              {showWidgetEdit?.data?.value == "about" ? (
                <div className="mt-3">
                  <DragDropFile
                    col={12}
                    title="Выберите картинку"
                    file={form.widgetAbout}
                    onChange={(e) => setValue("widgetAbout", e)}
                    onDelete={(e) => setValue("widgetAbout", e.file)}
                  />
                </div>
              ) : (
                showWidgetEdit?.data?.value == "hello" && (
                  <div className="mt-3">
                    <DragDropFile
                      col={12}
                      title="Выберите фон"
                      file={form.widgetHello}
                      onChange={(e) => setValue("widgetHello", e)}
                      onDelete={(e) => setValue("widgetHello", e.file)}
                    />
                  </div>
                )
              )}
            </CustomModal>
          </div>
          {brand?.type == "shop" && (
            <>
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="mb-2 fw-7">Проект</h5>
              </div>
              <div className="mb-4">
                <Row className="py-3 border-bottom d-flex flex-row justify-content-between align-items-center">
                  <Col>
                    <Input
                      label="Текст кнопки"
                      defaultValue={
                        form?.relationModule?.options?.project?.buttons[0]
                          ?.title
                      }
                      onChange={(e) =>
                        setValue(
                          "relationModule.options.project.buttons.0.title",
                          e
                        )
                      }
                      placeholder="Введите текст"
                    />
                  </Col>
                  <Col>
                    <Select
                      label="Стиль"
                      onClick={(e) =>
                        setValue(
                          "relationModule.options.project.buttons.0.type",
                          e.value
                        )
                      }
                      value={
                        form?.relationModule?.options?.project?.buttons[0]?.type
                      }
                      data={[
                        { title: "Светлый", value: "light" },
                        { title: "Темный", value: "dark" },
                      ]}
                    />
                  </Col>
                  <Col className="d-flex flex-row justify-content-between align-items-center">
                    <Form.Check>
                      <Form.Check.Input
                        type="checkbox"
                        id="project-button-0"
                        defaultChecked={showMenu.data.status}
                        onChange={(e) =>
                          setValue(
                            "relationModule.options.project.buttons.0.status",
                            e.target.checked
                          )
                        }
                      />
                      <Form.Check.Label
                        htmlFor="project-button-0"
                        className="ms-2"
                      >
                        Показать\Скрыть
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                </Row>
                <Row className="py-3 border-bottom d-flex flex-row justify-content-between align-items-center">
                  <Col>
                    <Input
                      label="Текст кнопки"
                      defaultValue={
                        form?.relationModule?.options?.project?.buttons[1]
                          ?.title
                      }
                      onChange={(e) =>
                        setValue(
                          "relationModule.options.project.buttons.1.title",
                          e
                        )
                      }
                      placeholder="Введите текст"
                    />
                  </Col>
                  <Col>
                    <Select
                      label="Стиль"
                      onClick={(e) =>
                        setValue(
                          "relationModule.options.project.buttons.1.type",
                          e.value
                        )
                      }
                      value={
                        form?.relationModule?.options?.project?.buttons[1]?.type
                      }
                      data={[
                        { title: "Светлый", value: "light" },
                        { title: "Темный", value: "dark" },
                      ]}
                    />
                  </Col>
                  <Col className="d-flex flex-row justify-content-between align-items-center">
                    <Form.Check>
                      <Form.Check.Input
                        type="checkbox"
                        id="project-button-1"
                        defaultChecked={showMenu.data.status}
                        onChange={(e) =>
                          setValue(
                            "relationModule.options.project.buttons.1.status",
                            e.target.checked
                          )
                        }
                      />
                      <Form.Check.Label
                        htmlFor="project-button-1"
                        className="ms-2"
                      >
                        Показать\Скрыть
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </Tab>
        <Tab eventKey={2} title="Компоненты">
          <h5 className="mb-2 fw-7">Обратная связь</h5>
          <div className="mb-4">
            <Row className="py-3 border-bottom d-flex flex-row justify-content-between align-items-center">
              <Col md={6}>
                <div className="mb-3">
                  <DragDropFile
                    col={12}
                    maxAspect={1116 / 274}
                    title="Выберите картинку"
                    file={form.feedback}
                    onChange={(e) => setValue("feedback", e)}
                    onDelete={(e) => setValue("feedback", e.file)}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <PreviewImages
                    medias={form?.relationModule?.options?.feedback}
                    type="all/web/feedback"
                    col={12}
                    onDelete={(e) => {
                      setValue("form.relationModule.options.feedback", e.media);
                      let newArrayDelete =
                        data?.delete?.length > 0
                          ? [...data.delete, e.delete]
                          : [e.delete];
                      setValue("delete", newArrayDelete);
                    }}
                  />
                </div>
              </Col>
              <Col>
                <Input
                  label="Заголовок"
                  defaultValue={form?.relationModule?.options?.feedback?.title}
                  onChange={(e) =>
                    setValue("relationModule.options.feedback.title", e)
                  }
                  placeholder="Введите текст"
                />
              </Col>
              <Col>
                <Select
                  label="Поле"
                  onClick={(e) =>
                    setValue("relationModule.options.feedback.type", e.value)
                  }
                  value={form?.relationModule?.options?.feedback?.type}
                  data={[
                    { title: "Email", value: "email" },
                    { title: "Номер телефона", value: "phone" },
                  ]}
                />
              </Col>
              <Col className="d-flex flex-row justify-content-between align-items-center">
                <Form.Check>
                  <Form.Check.Input
                    type="checkbox"
                    id="feedback-status"
                    defaultChecked={
                      form?.relationModule?.options?.feedback?.status
                    }
                    onChange={(e) =>
                      setValue(
                        "relationModule.options.feedback.status",
                        e.target.checked
                      )
                    }
                  />
                  <Form.Check.Label htmlFor="feedback-status" className="ms-2">
                    Показать\Скрыть
                  </Form.Check.Label>
                </Form.Check>
              </Col>
            </Row>
          </div>
          <h5 className="mb-2 fw-7">Форма Входа/Регистрации</h5>
          <div className="mb-4">
            <Row className="py-3 border-bottom d-flex flex-row justify-content-between align-items-center">
              <Col md={6}>
                <div className="mb-3">
                  <DragDropFile
                    col={12}
                    maxAspect={13 / 19}
                    title="Выберите картинку"
                    file={form.auth}
                    onChange={(e) => setValue("auth", e)}
                    onDelete={(e) => setValue("auth", e.file)}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <PreviewImages
                    medias={form?.relationModule?.options?.auth}
                    type="all/web/auth"
                    col={12}
                    onDelete={(e) => {
                      setValue("form.relationModule.options.auth", e.media);
                      let newArrayDelete =
                        data?.delete?.length > 0
                          ? [...data.delete, e.delete]
                          : [e.delete];
                      setValue("delete", newArrayDelete);
                    }}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Tab>
        <Tab eventKey={3} title="Оплата и доставка">
          <Row>
            <Col md={4}>
              <p className="mb-3 fw-6 border-bottom pb-3 d-flex align-items-center justify-content-between">
                Доставка
                <Form.Check
                  id="status-delivery"
                  type="switch"
                  className="switch"
                  defaultChecked={
                    form?.relationModule?.options?.dellivery?.status
                  }
                  {...register("relationModule.options.delivery.status")}
                />
              </p>
              <Form.Check className="mb-4 d-inline-block me-3">
                <Form.Check.Input
                  type="checkbox"
                  id="online-delivery"
                  defaultChecked={
                    form?.relationModule?.options?.delivery?.online
                  }
                  {...register("relationModule.options.delivery.online")}
                />
                <Form.Check.Label htmlFor="online-delivery" className="ms-2">
                  Онлайн оплата
                </Form.Check.Label>
              </Form.Check>
              <Form.Check className="mb-4 d-inline-block me-3">
                <Form.Check.Input
                  type="checkbox"
                  id="card-delivery"
                  defaultChecked={form?.relationModule?.options?.delivery?.card}
                  {...register("relationModule.options.delivery.card")}
                />
                <Form.Check.Label htmlFor="card-delivery" className="ms-2">
                  Банковской картой
                </Form.Check.Label>
              </Form.Check>
              <Form.Check className="mb-4 d-inline-block me-3">
                <Form.Check.Input
                  type="checkbox"
                  id="cash-delivery"
                  defaultChecked={form?.relationModule?.options?.delivery?.cash}
                  {...register("relationModule.options.delivery.cash")}
                />
                <Form.Check.Label htmlFor="cash-delivery" className="ms-2">
                  Наличными
                </Form.Check.Label>
              </Form.Check>
            </Col>
            <Col md={4}>
              <p className="mb-3 border-bottom pb-3 fw-6 d-flex align-items-center justify-content-between">
                Самовывоз
                <Form.Check
                  id="status-pickup"
                  type="switch"
                  className="switch"
                  defaultChecked={form?.relationModule?.options?.pickup?.status}
                  {...register("relationModule.options.pickup.status")}
                />
              </p>
              <Form.Check className="mb-4 d-inline-block me-3">
                <Form.Check.Input
                  type="checkbox"
                  id="online-pickup"
                  defaultChecked={form?.relationModule?.options?.pickup?.online}
                  {...register("relationModule.options.pickup.online")}
                />
                <Form.Check.Label htmlFor="online-pickup" className="ms-2">
                  Онлайн оплата
                </Form.Check.Label>
              </Form.Check>
              <Form.Check className="mb-4 d-inline-block me-3">
                <Form.Check.Input
                  type="checkbox"
                  id="card-pickup"
                  defaultChecked={form?.relationModule?.options?.pickup?.card}
                  {...register("relationModule.options.pickup.card")}
                />
                <Form.Check.Label htmlFor="card-pickup" className="ms-2">
                  Банковской картой
                </Form.Check.Label>
              </Form.Check>
              <Form.Check className="mb-4 d-inline-block me-3">
                <Form.Check.Input
                  type="checkbox"
                  id="cash-pickup"
                  defaultChecked={form?.relationModule?.options?.pickup?.cash}
                  {...register("relationModule.options.pickup.cash")}
                />
                <Form.Check.Label htmlFor="cash-pickup" className="ms-2">
                  Наличными
                </Form.Check.Label>
              </Form.Check>
            </Col>
            <Col md={4}>
              <p className="mb-3 fw-6 border-bottom pb-3 d-flex align-items-center justify-content-between">
                В зале
                <Form.Check
                  id="status-hall"
                  type="switch"
                  className="switch"
                  defaultChecked={form?.relationModule?.options?.hall?.status}
                  {...register("relationModule.options.hall.status")}
                />
              </p>
              <Form.Check className="mb-4 d-inline-block me-3">
                <Form.Check.Input
                  type="checkbox"
                  id="online-hall"
                  defaultChecked={form?.relationModule?.options?.hall?.online}
                  {...register("relationModule.options.hall.online")}
                />
                <Form.Check.Label htmlFor="online-hall" className="ms-2">
                  Онлайн оплата
                </Form.Check.Label>
              </Form.Check>
              <Form.Check className="mb-4 d-inline-block me-3">
                <Form.Check.Input
                  type="checkbox"
                  id="card-hall"
                  defaultChecked={form?.relationModule?.options?.hall?.card}
                  {...register("relationModule.options.hall.card")}
                />
                <Form.Check.Label htmlFor="card-hall" className="ms-2">
                  Банковской картой
                </Form.Check.Label>
              </Form.Check>
              <Form.Check className="mb-4 d-inline-block me-3">
                <Form.Check.Input
                  type="checkbox"
                  id="cash-hall"
                  defaultChecked={form?.relationModule?.options?.hall?.cash}
                  {...register("relationModule.options.hall.cash")}
                />
                <Form.Check.Label htmlFor="cash-hall" className="ms-2">
                  Наличными
                </Form.Check.Label>
              </Form.Check>
            </Col>
          </Row>
        </Tab>
        <Tab eventKey={4} title="Тема">
          <Row>
            <Col md={6}>
              <div className="mb-4">
                <Select
                  label="Тема"
                  onClick={(e) =>
                    setValue("relationModule.options.themeType", e.value)
                  }
                  value={form?.relationModule?.options?.themeType ?? false}
                  data={syncThemeTypeData}
                />
              </div>
              <h5 className="mb-4">Шрифт</h5>
              <Row>
                <Col md={6}>
                  <div className="mb-4">
                    <Select
                      label="Основной шрифт"
                      onClick={(e) =>
                        setValue("relationModule.options.themeFont", e.value)
                      }
                      value={form?.relationModule?.options?.themeFont ?? false}
                      data={syncThemeFontData}
                    />
                  </div>
                  <div className="mb-4">
                    <Select
                      label="Размер текста"
                      onClick={(e) =>
                        setValue(
                          "relationModule.options.themeFontSize",
                          e.value
                        )
                      }
                      value={
                        form?.relationModule?.options?.themeFontSize ?? false
                      }
                      data={syncThemeFontSizeData}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-4">
                    <Select
                      label="Шрифт заголовков"
                      onClick={(e) =>
                        setValue(
                          "relationModule.options.themeFontTitle",
                          e.value
                        )
                      }
                      value={
                        form?.relationModule?.options?.themeFontTitle ?? false
                      }
                      data={syncThemeFontData}
                    />
                  </div>
                  <div className="mb-4">
                    <Select
                      label="Размер заголовка"
                      onClick={(e) =>
                        setValue(
                          "relationModule.options.themeFontTitleSize",
                          e.value
                        )
                      }
                      value={
                        form?.relationModule?.options?.themeFontTitleSize ??
                        false
                      }
                      data={syncThemeFontSizeData}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <h5 className="mb-4">Цвета</h5>
          <Row>
            <Col md={12} className="d-flex flex-wrap">
              <div className="mb-4 px-3 colorful d-flex justify-content-center align-items-center flex-column">
                <p className="mb-3 fw-6 text-center fs-08">Основное</p>
                <HexAlphaColorPicker
                  color={form?.relationModule?.options?.colorMain}
                  onChange={(e) =>
                    setValue("relationModule.options.colorMain", e)
                  }
                />
                <Input
                  className="mt-3"
                  onChange={(e) =>
                    setValue("relationModule.options.colorMain", e)
                  }
                  value={form?.relationModule?.options?.colorMain}
                />
              </div>
              <div className="mb-4 px-3 colorful d-flex justify-content-center align-items-center flex-column">
                <p className="mb-3 fw-6 text-center fs-08">Кнопка</p>
                <HexAlphaColorPicker
                  color={form?.relationModule?.options?.colorBtn}
                  onChange={(e) =>
                    setValue("relationModule.options.colorBtn", e)
                  }
                />
                <Input
                  className="mt-3"
                  onChange={(e) =>
                    setValue("relationModule.options.colorBtn", e)
                  }
                  value={form?.relationModule?.options?.colorBtn}
                />
              </div>
              <div className="mb-4 px-3 colorful d-flex justify-content-center align-items-center flex-column">
                <p className="mb-3 fw-6 text-center fs-08">Текст</p>
                <HexAlphaColorPicker
                  color={form?.relationModule?.options?.colorText}
                  onChange={(e) =>
                    setValue("relationModule.options.colorText", e)
                  }
                />
                <Input
                  className="mt-3"
                  onChange={(e) =>
                    setValue("relationModule.options.colorText", e)
                  }
                  value={form?.relationModule?.options?.colorText}
                />
              </div>
              <div className="mb-4 px-3 colorful d-flex justify-content-center align-items-center flex-column">
                <p className="mb-3 fw-6 text-center fs-08">Заголовок</p>
                <HexAlphaColorPicker
                  color={form?.relationModule?.options?.colorTextTitle}
                  onChange={(e) =>
                    setValue("relationModule.options.colorTextTitle", e)
                  }
                />
                <Input
                  className="mt-3"
                  onChange={(e) =>
                    setValue("relationModule.options.colorTextTitle", e)
                  }
                  value={form?.relationModule?.options?.colorTextTitle}
                />
              </div>
              <div className="mb-4 px-3 colorful d-flex justify-content-center align-items-center flex-column">
                <p className="mb-3 fw-6 text-center fs-08">Подзаголовок</p>
                <HexAlphaColorPicker
                  color={form?.relationModule?.options?.colorTextSubtitle}
                  onChange={(e) =>
                    setValue("relationModule.options.colorTextSubtitle", e)
                  }
                />
                <Input
                  className="mt-3"
                  onChange={(e) =>
                    setValue("relationModule.options.colorTextSubtitle", e)
                  }
                  value={form?.relationModule?.options?.colorTextSubtitle}
                />
              </div>
            </Col>
          </Row>
          <h5 className="mb-4">Товары</h5>
          <Row>
            <Col md={6}>
              <div className="mb-4">
                <Select
                  label="Вид товара"
                  onClick={(e) =>
                    setValue("relationModule.options.themeProduct", e.value)
                  }
                  value={form?.relationModule?.options?.themeProduct ?? 0}
                  data={syncThemeProduct}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-4">
                <Select
                  label="Вид изображения товара"
                  onClick={(e) =>
                    setValue(
                      "relationModule.options.themeProductImage",
                      e.value
                    )
                  }
                  value={form?.relationModule?.options?.themeProductImage ?? 0}
                  data={syncThemeProductImage}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-4">
                <Select
                  label="Вид ряда"
                  onClick={(e) =>
                    setValue(
                      "relationModule.options.themeProductColumn",
                      e.value
                    )
                  }
                  value={form?.relationModule?.options?.themeProductColumn ?? 0}
                  data={syncProductColumn}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-4">
                <Select
                  label="Вид допов"
                  onClick={(e) =>
                    setValue("relationModule.options.themeAddition", e.value)
                  }
                  value={form?.relationModule?.options?.themeAddition ?? 0}
                  data={syncThemeAddition}
                />
              </div>
            </Col>
          </Row>
        </Tab>
        <Tab eventKey={5} title="SEO">
          <p className="mb-4">
            Вы можете добавить в шаблон текста шорткоды: <br />
            <p>
              <b>{`{{name}}`}</b> - название товара, категории, новости, акции.
            </p>
            <p>
              <b>{`{{site}}`}</b> - название сайта.
            </p>
          </p>

          <h5 className="mb-3">Главная</h5>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Input
                  label="Title"
                  placeholder="Введите заголовок 80 символов"
                  name="relationModule.options.seo.home.title"
                  errors={errors}
                  defaultValue={form?.relationModule?.options?.seo?.home?.title}
                  register={register}
                  maxLength={80}
                  validation={{
                    maxLength: {
                      value: 80,
                      message: "Максимально 80 символов",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={6}>
              <Textarea
                label="Description"
                placeholder="Введите описание 160 символов"
                name="relationModule.options.seo.home.description"
                errors={errors}
                rows={1}
                maxLength={160}
                register={register}
                validation={{
                  maxLength: {
                    value: 160,
                    message: "Максимально 160 символов",
                  },
                }}
                defaultValue={
                  form?.relationModule?.options?.seo?.home?.description
                }
              />
            </Col>
          </Row>
          <h5 className="mb-3">Вход и регистрация</h5>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Input
                  label="Title"
                  placeholder="Введите заголовок 80 символов"
                  name="relationModule.options.seo.login.title"
                  errors={errors}
                  defaultValue={
                    form?.relationModule?.options?.seo?.login?.title
                  }
                  register={register}
                  maxLength={80}
                  validation={{
                    maxLength: {
                      value: 80,
                      message: "Максимально 80 символов",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={6}>
              <Textarea
                label="Description"
                placeholder="Введите описание 160 символов"
                name="relationModule.options.seo.login.description"
                errors={errors}
                rows={1}
                maxLength={160}
                register={register}
                validation={{
                  maxLength: {
                    value: 160,
                    message: "Максимально 160 символов",
                  },
                }}
                defaultValue={
                  form?.relationModule?.options?.seo?.login?.description
                }
              />
            </Col>
          </Row>
          <h5 className="mb-3">Контакты</h5>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Input
                  label="Title"
                  placeholder="Введите заголовок 80 символов"
                  name="relationModule.options.seo.contact.title"
                  errors={errors}
                  defaultValue={
                    form?.relationModule?.options?.seo?.contact?.title
                  }
                  register={register}
                  maxLength={80}
                  validation={{
                    maxLength: {
                      value: 80,
                      message: "Максимально 80 символов",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={6}>
              <Textarea
                label="Description"
                placeholder="Введите описание 160 символов"
                name="relationModule.options.seo.contact.description"
                errors={errors}
                rows={1}
                maxLength={160}
                register={register}
                validation={{
                  maxLength: {
                    value: 160,
                    message: "Максимально 160 символов",
                  },
                }}
                defaultValue={
                  form?.relationModule?.options?.seo?.contact?.description
                }
              />
            </Col>
          </Row>
          <h5 className="mb-3">Категории</h5>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Input
                  label="Title"
                  placeholder="Введите заголовок 80 символов"
                  name="relationModule.options.seo.categories.title"
                  errors={errors}
                  defaultValue={
                    form?.relationModule?.options?.seo?.categories?.title
                  }
                  register={register}
                  maxLength={80}
                  validation={{
                    maxLength: {
                      value: 80,
                      message: "Максимально 80 символов",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={6}>
              <Textarea
                label="Description"
                placeholder="Введите описание 160 символов"
                name="relationModule.options.seo.categories.description"
                errors={errors}
                rows={1}
                maxLength={160}
                register={register}
                validation={{
                  maxLength: {
                    value: 160,
                    message: "Максимально 160 символов",
                  },
                }}
                defaultValue={
                  form?.relationModule?.options?.seo?.categories?.description
                }
              />
            </Col>
          </Row>
          <h5 className="mb-3">Категория</h5>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Input
                  label="Title"
                  placeholder="Введите заголовок 80 символов"
                  name="relationModule.options.seo.category.title"
                  errors={errors}
                  defaultValue={
                    form?.relationModule?.options?.seo?.category?.title
                  }
                  register={register}
                  maxLength={80}
                  validation={{
                    maxLength: {
                      value: 80,
                      message: "Максимально 80 символов",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={6}>
              <Textarea
                label="Description"
                placeholder="Введите описание 160 символов"
                name="relationModule.options.seo.category.description"
                errors={errors}
                rows={1}
                maxLength={160}
                register={register}
                validation={{
                  maxLength: {
                    value: 160,
                    message: "Максимально 160 символов",
                  },
                }}
                defaultValue={
                  form?.relationModule?.options?.seo?.category?.description
                }
              />
            </Col>
          </Row>
          <h5 className="mb-3">Товар</h5>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Input
                  label="Title"
                  placeholder="Введите заголовок 80 символов"
                  name="relationModule.options.seo.product.title"
                  errors={errors}
                  defaultValue={
                    form?.relationModule?.options?.seo?.product?.title
                  }
                  register={register}
                  maxLength={80}
                  validation={{
                    maxLength: {
                      value: 80,
                      message: "Максимально 80 символов",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={6}>
              <Textarea
                label="Description"
                placeholder="Введите описание 160 символов"
                name="relationModule.options.seo.product.description"
                errors={errors}
                rows={1}
                maxLength={160}
                register={register}
                validation={{
                  maxLength: {
                    value: 160,
                    message: "Максимально 160 символов",
                  },
                }}
                defaultValue={
                  form?.relationModule?.options?.seo?.product?.description
                }
              />
            </Col>
          </Row>
          <h5 className="mb-3">Акции</h5>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Input
                  label="Title"
                  placeholder="Введите заголовок 80 символов"
                  name="relationModule.options.seo.sales.title"
                  errors={errors}
                  defaultValue={
                    form?.relationModule?.options?.seo?.sales?.title
                  }
                  register={register}
                  maxLength={80}
                  validation={{
                    maxLength: {
                      value: 80,
                      message: "Максимально 80 символов",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={6}>
              <Textarea
                label="Description"
                placeholder="Введите описание 160 символов"
                name="relationModule.options.seo.sales.description"
                errors={errors}
                rows={1}
                maxLength={160}
                register={register}
                validation={{
                  maxLength: {
                    value: 160,
                    message: "Максимально 160 символов",
                  },
                }}
                defaultValue={
                  form?.relationModule?.options?.seo?.sales?.description
                }
              />
            </Col>
          </Row>
          <h5 className="mb-3">Акция</h5>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Input
                  label="Title"
                  placeholder="Введите заголовок 80 символов"
                  name="relationModule.options.seo.sale.title"
                  errors={errors}
                  defaultValue={form?.relationModule?.options?.seo?.sale?.title}
                  register={register}
                  maxLength={80}
                  validation={{
                    maxLength: {
                      value: 80,
                      message: "Максимально 80 символов",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={6}>
              <Textarea
                label="Description"
                placeholder="Введите описание 160 символов"
                name="relationModule.options.seo.sale.description"
                errors={errors}
                rows={1}
                maxLength={160}
                register={register}
                validation={{
                  maxLength: {
                    value: 160,
                    message: "Максимально 160 символов",
                  },
                }}
                defaultValue={
                  form?.relationModule?.options?.seo?.sale?.description
                }
              />
            </Col>
          </Row>
          <h5 className="mb-3">Новости</h5>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Input
                  label="Title"
                  placeholder="Введите заголовок 80 символов"
                  name="relationModule.options.seo.blogs.title"
                  errors={errors}
                  defaultValue={
                    form?.relationModule?.options?.seo?.blogs?.title
                  }
                  register={register}
                  maxLength={80}
                  validation={{
                    maxLength: {
                      value: 80,
                      message: "Максимально 80 символов",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={6}>
              <Textarea
                label="Description"
                placeholder="Введите описание 160 символов"
                name="relationModule.options.seo.blogs.description"
                errors={errors}
                rows={1}
                maxLength={160}
                register={register}
                validation={{
                  maxLength: {
                    value: 160,
                    message: "Максимально 160 символов",
                  },
                }}
                defaultValue={
                  form?.relationModule?.options?.seo?.blogs?.description
                }
              />
            </Col>
          </Row>
          <h5 className="mb-3">Новость</h5>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Input
                  label="Title"
                  placeholder="Введите заголовок 80 символов"
                  name="relationModule.options.seo.blog.title"
                  errors={errors}
                  defaultValue={form?.relationModule?.options?.seo?.blog?.title}
                  register={register}
                  maxLength={80}
                  validation={{
                    maxLength: {
                      value: 80,
                      message: "Максимально 80 символов",
                    },
                  }}
                />
              </div>
            </Col>
            <Col md={6}>
              <Textarea
                label="Description"
                placeholder="Введите описание 160 символов"
                name="relationModule.options.seo.blog.description"
                errors={errors}
                rows={1}
                maxLength={160}
                register={register}
                validation={{
                  maxLength: {
                    value: 160,
                    message: "Максимально 160 символов",
                  },
                }}
                defaultValue={
                  form?.relationModule?.options?.seo?.blog?.description
                }
              />
            </Col>
          </Row>
        </Tab>
      </Tabs>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <span className="text-muted fs-08 me-3">Данные применяться в течении 1 мин</span>
        <Button isValid={isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить изменения
        </Button>
      </div>
    </Card>
  );
};
export default SiteForm;
