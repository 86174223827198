import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import Textarea from "../../components/UI/Textarea";
import { kkal } from "../../helpers/product";
import { editWish, getWish } from "../../services/wish";

const WishEdit = () => {
  const { wishId } = useParams();
  const [loading, setLoading] = useState(true);

  const {
    control,
    register,
    formState: { errors },
    reset,
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {
      type: "wish",
    },
  });

  const data = useWatch({ control });

  const kkalData = useRef();

  useLayoutEffect(() => {
    getWish(wishId)
      .then((res) => {
        if (res) {
          reset(res);
          kkalData.current = kkal(res?.wish?.storages);
          setLoading(false);
        }
      })
      .catch(() => setLoading(false));
  }, []);

  const onSubmit = useCallback((data) => {
    // var formData = new FormData();

    // formData.append("dataParse", JSON.stringify(data));

    // if (data?.file) {
    //   for (let file of data?.file) {
    //     formData.append("file", file);
    //   }
    // }
    editWish(data)
      .then(() => NotificationManager.success("Пожелание успешно обновлено"))
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  if (loading) {
    return <Loader full />;
  }

  if (!data) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Такого поделания нет</h3>
      </Info>
    );
  }

  return (
    <>
      <Meta title="Редактировать пожелание" />
      <div>
        <Link
          to="/catalog/wishes"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
      </div>
      <h3 className="mb-4">Редактировать пожелание</h3>
      <Row>
        <Col md={8}>
          <Card>
            <Card.Body>
              <Row>
                <Col md={8}>
                  <div className="mb-3">
                    <Input
                      label="Название"
                      name="title"
                      errors={errors}
                      register={register}
                      validation={{
                        required: "Обязательное поле",
                      }}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Input
                      label="Артикул"
                      name="code"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Input
                      defaultValue={0}
                      label="Порядок"
                      name="priority"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Select
                      classNameContainer="w-100"
                      label="Тип отправки"
                      data={[
                        { title: "Модификатор", value: "wish" },
                        { title: "Блюдо", value: "product" },
                        { title: "Добавка", value: "addition" },
                      ]}
                      value={data.options?.typeSend ?? null}
                      onClick={(e) => {
                        setValue("options.type", e.value);
                      }}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <Textarea
                      defaultValue={data.description}
                      label="Описание"
                      name="description"
                      errors={errors}
                      rows={6}
                      register={register}
                    />
                  </div>
                </Col>
                {/* <Col lg={6}>
                  <div className="mb-3">
                    <Select
                      label="Категория"
                      search
                      value={data.categoryId}
                      data={categories.items.map((e) => ({
                        title: e.title,
                        value: e.id,
                      }))}
                      onClick={(e) => setValue("categoryId", e.value)}
                    />
                  </div>
                </Col> */}
                {data.apiId && (
                  <Col md={12}>
                    <Input
                      defaultValue={data.apiId}
                      label="API ID"
                      name="apiId"
                      readOnly={false}
                    />
                  </Col>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="position-relative">
          <div className="position-sticky top-1">
            {/* <Card className="mb-3" body>
              <DragDropFile
                col={3}
                maxAspect={1}
                file={data.file}
                onChange={(e) => setValue("file", e)}
                onDelete={(e) => setValue("file", e.file)}
              />
              <PreviewImages
                medias={data?.media}
                type="wish"
                col={6}
                onDelete={(e) => {
                  setValue("media", e.media);
                  let newArrayDelete =
                    data?.delete?.length > 0
                      ? [...data.delete, e.delete]
                      : [e.delete];
                  setValue("delete", newArrayDelete);
                }}
              />
            </Card> */}
            <Card body className="mb-3">
              <p className="fs-08">
                Энергетическая ценность в 100г -{" "}
                <span className="text-success">
                  {kkalData?.current?.kkal ?? 0}
                </span>{" "}
                ккал
              </p>
              <Row className="gx-2">
                <Col lg={4}>
                  <div className="mt-4">
                    <Input
                      value={kkalData?.current?.protein ?? 0}
                      label="Белки, г"
                      readOnly={false}
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mt-4">
                    <Input
                      value={kkalData?.current?.fat ?? 0}
                      label="Жиры, г"
                      readOnly={false}
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mt-4">
                    <Input
                      value={kkalData?.current?.carbohydrate ?? 0}
                      label="Углеводы, г"
                      readOnly={false}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="gx-2">
                <Col lg={6}>
                  <div className="mt-4">
                    <Input
                      value={kkalData?.current?.kkalAll ?? 0}
                      label="Всего ккал, г"
                      readOnly={false}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mt-4">
                    <Input
                      defaultValue={kkalData?.current?.weight ?? 0}
                      label="Вес, г"
                      name="energy.weight"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
            <Card body className="mb-3">
              <p className="fs-08">Цена</p>
              <Row className="gx-2">
                <Col lg={6}>
                  <div className="mt-4">
                    <Input
                      defaultValue={0}
                      label="Цена"
                      name="price"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mt-4">
                    <Input
                      defaultValue={0}
                      label="Скидка"
                      name="discount"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
            <Button
              className="btn-primary align-self-end w-100 mt-3"
              onClick={handleSubmit(onSubmit)}
            >
              Сохранить
            </Button>
          </div>
        </Col>
      </Row>
      {/* <ImageCropper
        file={editImageProduct.data[0]}
        show={editImageProduct.show}
        setShow={(e) => setEditImageProduct((info) => ({ ...info, show: e }))}
        onComplete={(e) => {
          e && setValue("file", e.file);
          e && setValue("medias", e.blob);
        }}
      /> */}
    </>
  );
};

export default WishEdit;
