import React, { useCallback, useLayoutEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useParams } from "react-router-dom";
import DragDropFile from "../../components/DragDropFile";
import CustomEditor from "../../components/editor/CustomEditor";
import Meta from "../../components/Meta";
import PreviewImages from "../../components/PreviewImages";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import { editPortfolio, getPortfolioOne } from "../../services/portfolio";

const PortfolioEdit = () => {
  const { portfolioId } = useParams();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const {
    control,
    register,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const data = useWatch({ control });

  useLayoutEffect(() => {
    getPortfolioOne(portfolioId)
      .then(
        (res) =>
          res &&
          reset((prev) => ({
            ...prev,
            ...res,
          }))
      )
      .finally(() => setLoading(false));
  }, []);

  const onSubmit = useCallback(() => {
    var formData = new FormData();

    formData.append("dataParse", JSON.stringify(data));

    if (data?.file?.length > 0) {
      for (let file of data.file) {
        formData.append("file", file);
      }
    } else if (data?.file) {
      formData.append("file", data.file);
    }

    editPortfolio(formData)
      .then(() => {
        NotificationManager.success("Портфолио успешно обновлено");
        navigate(-1);
      })
      .catch(
        (err) =>
          err && NotificationManager.error("Ошибка при сохранении документа")
      );
  }, [data]);

  if (loading) {
    return <Loader full />;
  }

  if (!data) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Такого портфолио нет</h3>
      </Info>
    );
  }

  return (
    <>
      <Meta title={data.title ?? "Редактирование портфолио"} />
      <div className="d-flex justify-content-between align-items-center">
        <Link
          to="/content/portfolio"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
        <Button onClick={() => onSubmit()}>Сохранить изменения</Button>
      </div>
      <h3 className="mb-4">Редактирование портфолио</h3>
      <div className="mb-3">
        <DragDropFile
          col={3}
          file={data.file}
          main
          multiple
          onChange={(e) => setValue("file", e)}
          onDelete={(e) => setValue("file", e.file)}
        />
      </div>
      <div className="mb-3">
        <PreviewImages
          medias={data?.medias}
          type="portfolio"
          col={3}
          main
          onMain={(e) => setValue("main", e)}
          onDelete={(e) => {
            setValue("medias", e.medias);
            let newArrayDelete =
              data?.delete?.length > 0
                ? [...data.delete, e.delete]
                : [e.delete];
            setValue("delete", newArrayDelete);
          }}
        />
      </div>
      <div className="mb-3">
        <Input
          autoFocus={true}
          label="Название"
          name="title"
          errors={errors}
          register={register}
          validation={{
            required: "Обязательное поле",
          }}
        />
      </div>
      <CustomEditor
        content={data.content}
        onChange={(e) => setValue("content", e)}
      />
    </>
  );
};

export default PortfolioEdit;
