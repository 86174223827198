import React, { useCallback, useRef } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Textarea from "../../components/UI/Textarea";
import { createWish } from "../../services/wish";

const WishCreate = () => {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {
      type: "wish",
    },
  });

  const data = useWatch({ control });

  const kkalData = useRef();

  const onSubmit = useCallback((data) => {
    createWish(data)
      .then(() => NotificationManager.success("Пожелание успешно создано"))
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      });
  }, []);

  const FormIngredient = ({ data, onChange }) => {
    return (
      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Input
              defaultValue={data.weight ?? 0}
              label="Вес, г"
              name="weight"
              type="number"
              onChange={(e) => onChange && onChange("weight", e)}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Input
              defaultValue={data.price ?? 0}
              label="Цена"
              type="number"
              name="price"
              onChange={(e) => onChange && onChange("price", e)}
            />
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Meta title="Создать пожелание" />
      <div>
        <Link
          to="/catalog/wishes"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
      </div>
      <h3 className="mb-4">Создать пожелание</h3>
      <Row>
        <Col md={8}>
          <Card>
            <Card.Body>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Input
                      label="Название"
                      name="title"
                      errors={errors}
                      register={register}
                      validation={{
                        required: "Обязательное поле",
                      }}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Input
                      label="Артикул"
                      name="code"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Input
                      defaultValue={0}
                      label="Порядок"
                      name="priority"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <Textarea
                      defaultValue={data.description}
                      label="Описание"
                      name="description"
                      errors={errors}
                      rows={6}
                      register={register}
                    />
                  </div>
                </Col>
                {data.apiId && (
                  <Col md={12}>
                    <Input
                      defaultValue={data.apiId}
                      label="API ID"
                      name="apiId"
                      readOnly={false}
                    />
                  </Col>
                )}
              </Row>
              <Button
                className="btn-primary align-self-end w-100 mt-3"
                onClick={handleSubmit(onSubmit)}
              >
                Сохранить
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default WishCreate;
