import moment from "moment";
import { memo, useEffect, useState, forwardRef } from "react";
import { useSelector } from "react-redux";
import { customPrice } from "../helpers/product";

const Print = memo(
  forwardRef(({ data }, ref) => {
    const brand = useSelector((state) => state.brand.active);
    if (!data?.products || !data?.products[0]) {
      return null;
    }
    return (
      <div className="d-none">
        <div ref={ref}>
          <div className="p-2">
            <div className="border-bottom-dashed pb-2 mb-2">
              <h3 className="text-center">{brand.title ?? "Чек"}</h3>
            </div>
            <div className="border-bottom-dashed pb-2 mb-2 fs-08">
              <div className="d-flex justify-content-between">
                <span>Номер заказа:</span>
                <b>{data.id}</b>
              </div>
              <div className="d-flex justify-content-between">
                <span>Заказ создан:</span>
                <span>{moment(data.createdAt).format("DD.MM.YYYY kk:mm")}</span>
              </div>
            </div>
            <div className="border-bottom-dashed pb-2 mb-2 fs-08">
              <b className="mb-3">Товары</b>
              {data?.products?.length > 0 &&
                data.products.map((e) => {
                  return (
                    <>
                      <div className="d-flex justify-content-between">
                        <span>
                          {e?.title}
                          {e?.modifiers?.title ? (
                            <span className="ms-2">{e?.modifiers?.title}</span>
                          ) : null}
                        </span>
                        <span className="ps-2">
                          {customPrice(
                            e?.modifiers?.price ? e.modifiers.price : e?.price
                          )}
                        </span>
                      </div>
                      {e.additions.length > 0 &&
                        e.additions.map((addition) => (
                          <div className="ps-3 d-flex justify-content-between fs-09">
                            <span>{addition.title}</span>
                            <span className="ps-2">
                              {customPrice(addition.price)}
                            </span>
                          </div>
                        ))}
                    </>
                  );
                })}
            </div>
            <div className="border-bottom-dashed pb-2 mb-2 fs-08">
              <div className="d-flex justify-content-between">
                <b>Итого:</b>
                <b>{customPrice(data.total)}</b>
              </div>
            </div>
            {data.delivery == "delivery" && (
              <div className="border-bottom-dashed pb-2 mb-2 fs-08">
                <b className="mb-3">Информация для курьера</b>
                <div className="d-flex">
                  <span className="me-2">Имя:</span>
                  <span>{data.name}</span>
                </div>
                <div>
                  <p>Адрес:</p>
                  <p>
                    {data.street}
                    {data.home ? ` ${data.home}` : null}
                    {data.block ? `, корпус ${data.block}` : null}
                    {data.entrance ? `, подъезд' ${data.entrance}` : null}
                    {data.apartment ? `, кв ${data.apartment}` : null}
                    {data.floor ? `, этаж' ${data.floor}` : null}
                    {data.code ? `, код ' ${data.code}` : null}
                  </p>
                </div>
                <div className="d-flex">
                  <span className="me-2">Телефон:</span>
                  <span>{data.phone}</span>
                </div>
                {data.comment && (
                  <div>
                    <p>Комментарий:</p>
                    <p>{data.comment}</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  })
);

export default Print;
