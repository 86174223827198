import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { Badge } from "react-bootstrap";
import {
  IoAdd,
  IoChevronForwardOutline,
  IoCreateOutline,
  IoDocumentOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import DragDropFile from "../../components/DragDropFile";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import { customPrice } from "../../helpers/product";
import { deleteZone, getZones, uploadZone } from "../../services/zone";

const Zones = () => {
  const brand = useSelector((state) => state.brand.active);
  const affiliate = useSelector((state) => state.affiliate.active);
  const [searchParams, setSearchParams] = useSearchParams();
  const [zones, setZones] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });

  const [uploadModal, setUploadModal] = useState({
    show: false,
    data: false,
    geojson: false,
  });

  const zoneColumns = [
    {
      name: "Название",
      selector: "title",
      cell: (row) => (
        <Link to={"/options/zone/" + row.id} className="me-2">
          {row.title}
        </Link>
      ),
    },
    {
      name: "Цена",
      align: "center",
      selector: "price",
      width: "80px",
      cell: (row) => customPrice(row.price),
    },
    {
      name: "Бесплатно от",
      align: "center",
      width: "120px",
      selector: "priceFree",
      cell: (row) => customPrice(row.priceFree),
    },
    {
      name: "Мин сумма",
      align: "center",
      width: "120px",
      selector: "minPrice",
      cell: (row) => customPrice(row.minPrice),
    },
    {
      name: "Филиал",
      align: "center",
      width: "120px",
      selector: "affiliateId",
      cell: (row) =>
        row.affiliateId ? (
          <Badge bg="success">Установлен</Badge>
        ) : (
          <Badge bg="danger">Не указан</Badge>
        ),
    },
    {
      name: "Статус",
      align: "center",
      width: "80px",
      selector: "status",
      cell: (row) =>
        row.status === 1 ? (
          <Badge bg="success">Активно</Badge>
        ) : (
          <Badge bg="danger">Отключено</Badge>
        ),
    },
    {
      width: "60px",
      selector: "action",
      align: "right",
      cell: (row) => (
        <Link to={"/options/affiliates/zone/" + row.id} className="me-2">
          <IoCreateOutline size={22} />
        </Link>
      ),
    },
  ];

  const header = useMemo(() => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <h5 className="fw-7">
            {selected.length > 0
              ? `Выбрано ${selected.length}`
              : "Зоны доставок"}
          </h5>
        </div>
        <div className="d-flex align-items-center">
          <Link to="create" className="btn-primary-outline me-3">
            <IoAdd size={18} />
          </Link>
          <Button
            className="btn-primary-outline me-3"
            onClick={() => setUploadModal({ show: true, data: false })}
          >
            <IoDocumentOutline size={18} />
          </Button>
          <Button
            disabled={selected.length === 0}
            className="btn-light"
            onClick={() => setModalDelete({ show: true, id: false })}
          >
            <IoTrashOutline size={18} />
          </Button>
        </div>
      </div>
    );
  }, [selected, searchParams, modalDelete]);

  const getData = useCallback(() => {
    getZones({ ...searchParams, affiliateId: affiliate.id })
      .then(
        (res) =>
          res &&
          setZones((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }))
      )
      .finally(() => setZones((prev) => ({ ...prev, loading: false })));
  }, [searchParams, brand, affiliate]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page"), brand, affiliate]);

  const onDeleteSelected = useCallback(() => {
    deleteZone(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        NotificationManager.success("Выбранные зоны успешно удалены");
        setModalDelete({ show: false, id: false });
        getData();
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, [selected]);

  const onGetFile = useCallback((e) => {
    let file = e[0];

    if (!file) {
      NotificationManager.error("Вы не выбрали файл");
    }
    let ext = false;
    let parts = file.name.split(".");
    if (parts.length > 1) ext = parts.pop().toLowerCase();

    if (ext != "json" && ext != "geojson") {
      return NotificationManager.error(
        "Неверный формат файла. Допустимы только json или geojson."
      );
    }

    if (window.File && window.FileReader && window.FileList && window.Blob) {
      // All the File APIs are supported.
    } else {
      return NotificationManager.error(
        "Браузер не поддерживает данный формат."
      );
    }

    var objFileReader = new FileReader();
    objFileReader.onload = function (event) {
      var data = event.target.result;
      if (!data) {
        return NotificationManager.error("Файл пуст.");
      }
      data = JSON.parse(data);

      let zones = data.features;
      if (!zones || zones.length === 0) {
        return NotificationManager.error("Нет данных зон.");
      }
      zones = zones.filter((e) => e?.geometry?.type?.toLowerCase() != "point");

      setUploadModal((prev) => ({
        ...prev,
        data: e,
        geojson: zones,
      }));
    };
    objFileReader.readAsText(file);
  }, []);

  const onUploadFile = useCallback(() => {
    uploadZone({
      geojson: uploadModal.geojson,
      affiliateId: affiliate?.id ?? null,
    })
      .then(() => {
        setUploadModal((prev) => ({
          ...prev,
          show: false,
          data: false,
          geojson: false,
        }));
        getData();
        NotificationManager.success("Зона доставки успешно загружены");
      })
      .catch((error) =>
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        )
      );
  }, [uploadModal, affiliate]);

  if (zones.loading) {
    return <Loader full />;
  }

  return (
    <>
      <Meta title="Зоны доставок" />
      <DataTable
        columns={zoneColumns}
        onChange={(items) => setSelected(items)}
        data={zones.items}
        header={header}
        selectable
        pagination={zones.pagination}
      />
      <CustomModal
        classNameBody="p-0"
        title="Загрузка карты GeoJson"
        show={uploadModal.show}
        setShow={(e) => setUploadModal({ show: e, data: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() =>
                setUploadModal({
                  show: !uploadModal.show,
                  data: false,
                  geojson: false,
                })
              }
            >
              Отмена
            </Button>
            <Button
              disabled={!uploadModal.geojson}
              onClick={onUploadFile}
              className="btn-primary"
            >
              Загрузить
            </Button>
          </>
        }
      >
        {uploadModal?.geojson?.length > 0 ? (
          <div className="p-4">
            Вы добавите/обновите <b>{uploadModal.geojson.length}</b> зон
            доставок.
          </div>
        ) : (
          <div className="p-4">
            <DragDropFile
              title="Выберите файл GeoJson"
              onChange={(e) => onGetFile(e)}
              accept=".geojson,application/geojson"
            />
            <a
              href="https://yandex.ru/map-constructor"
              target="_blank"
              className="my-3 d-flex align-items-center justify-content-between"
            >
              <div>
                <div className="fs-08 text-muted">
                  Ссылка на Яндекс Конструктор
                </div>
                <div className="text-success">
                  https://yandex.ru/map-constructor
                </div>
              </div>
              <div>
                <IoChevronForwardOutline size={20} className="text-muted" />
              </div>
            </a>
            <p className="mb-2 fw-6">1. Создайте карту.</p>
            <img width="100%" src="/images/createmap1.png" />
            <p className="my-2 fw-6">
              2. Добавьте многоугольник и выделите нужные зоны доставок.
              Старайтесь не пересекать зоны между собой.
            </p>
            <img width="100%" src="/images/createmap2.png" />
            <p className="my-2 fw-6">
              3. Нажмите кнопку Экспорт и скачайте файл в формате GeoJson. После
              чего, вы можете загрузить его выше.
            </p>
            <img width="100%" src="/images/createmap3.png" />
          </div>
        )}
      </CustomModal>
      <CustomModal
        title={`Удаление ${modalDelete.id ? "#" + modalDelete.id : ""}`}
        show={modalDelete.show}
        setShow={(e) => setModalDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={(e) =>
                setModalDelete({ show: !modalDelete.show, id: false })
              }
            >
              Отмена
            </Button>
            <Button className="btn-primary" onClick={() => onDeleteSelected()}>
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить зону доставки?
      </CustomModal>
    </>
  );
};

export default Zones;
