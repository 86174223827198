import React, { useCallback, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate } from "react-router-dom";
import DragDropFile from "../../components/DragDropFile";
import CustomEditor from "../../components/editor/CustomEditor";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import { generateUrl } from "../../helpers/all";
import { createBlog } from "../../services/blog";

const BlogCreate = () => {
  const navigate = useNavigate();

  const {
    control,
    register,
    formState: { errors, isValid },
    setValue,
    handleSubmit,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const data = useWatch({ control });

  const onSubmit = useCallback((data) => {
    var formData = new FormData();

    formData.append("dataParse", JSON.stringify(data));

    if (data?.file) {
      for (let file of data?.file) {
        formData.append("file", file);
      }
    }

    createBlog(formData)
      .then(() => {
        NotificationManager.success("Новость успешно создана");
        navigate(-1);
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      });
  }, []);

  useEffect(() => {
    setValue("alias", generateUrl(data.title));
  }, [data.title]);

  return (
    <>
      <Meta title="Создать новость" />
      <div className="d-flex justify-content-between align-items-center">
        <Link
          to="/content/blogs"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
        <Button disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить изменения
        </Button>
      </div>
      <h3 className="mb-4">Создать новость</h3>
      <div className="mb-3">
        <DragDropFile
          file={data.file}
          onChange={(e) => setValue("file", e)}
          onDelete={(e) => setValue("file", e.file)}
        />
      </div>
      <div className="mb-3">
        <Input
          autoFocus={true}
          label="Заголовок"
          name="title"
          placeholder="Введите заголовок"
          errors={errors}
          register={register}
          validation={{
            required: "Обязательное поле",
          }}
        />
      </div>
      <div className="mb-3">
        <Input
          defaultValue={data?.alias ?? generateUrl(data.title)}
          label="Ссылка"
          name="alias"
          placeholder="Введите ссылку"
          errors={errors}
          register={register}
          validation={{
            required: "Введите ссылку",
            minLength: {
              value: 2,
              message: "Минимально 2 символа",
            },
            maxLength: {
              value: 255,
              message: "Максимально 255 символов",
            },
            pattern: {
              value: /^[a-z0-9_]+$/,
              message: "Неверный формат (Только a-z0-9_)",
            },
          }}
        />
      </div>
      <CustomEditor
        content={data.content}
        onChange={(e) => setValue("content", e)}
      />
    </>
  );
};

export default BlogCreate;
