import { useCallback, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import Input from "../../../components/UI/Input";
import Button from "../../../components/UI/Button";
import { Card, Col, Form, Row } from "react-bootstrap";
import { IoRefreshOutline } from "react-icons/io5";
import { getStatistics } from "../../../services/statistic";
import {
  getEprCategory,
  getEprDiscounts,
  getEprModifier,
  getEprOrganizations,
  getEprProduct,
  getEprTerminal,
  getEprTypesDelivery,
  getEprTypesPayment,
  getEprMenu,
  getEprAll,
  getEprCoupons,
  getEprTable,
} from "../../../services/upload";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Select from "../../../components/UI/Select";
import socket from "../../../config/socket";
import { NotificationManager } from "react-notifications";

const initalData = {
  type: "office",
  login: null,
  payment: {
    card: null,
    cash: null,
    point: null,
    online: null,
  },
  delivery: {
    hall: null,
    pickup: null,
    delivery: null,
  },
  terminals: [],
  typesPayment: [],
  organizations: [],
  typesDelivery: [
    {
      items: [],
      organizationId: "",
    },
  ],
};

const IikoForm = ({ data, onSubmit, onUpdate, setInitalData }) => {
  const brand = useSelector((state) => state?.brand?.active);

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: data ?? initalData,
  });

  const form = useWatch({ control });

  const [all, setAll] = useState(false);

  const [statistic, setStatistic] = useState({
    loading: true,

    categories: 0,
    products: 0,
    modifiers: 0,
    tables: 0,

    organizations: 0,
    discounts: 0,
    typesDelivery: 0,
    typesPayment: 0,
    terminal: 0,
  });

  const updateStatistics = () => {
    getStatistics({
      sync: true,
      category: true,
      table: true,
      product: true,
      modifier: true,
    })
      .then((res) => {
        onUpdate();
        setStatistic({ ...statistic, ...res, loading: false });
      })
      .catch(() => setStatistic({ ...statistic, loading: false }));
  };

  const clickGetErpProduct = useCallback(() => {
    getEprProduct(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpModifier = useCallback(() => {
    getEprModifier(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpTable = useCallback(() => {
    getEprTable(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpCategory = useCallback(() => {
    getEprCategory(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpOrganization = useCallback(() => {
    getEprOrganizations(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpCoupon = useCallback(() => {
    getEprCoupons(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpDiscount = useCallback(() => {
    getEprDiscounts(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpTypesDelivery = useCallback(() => {
    getEprTypesDelivery(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpTypesPayment = useCallback(() => {
    getEprTypesPayment(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpTerminal = useCallback(() => {
    getEprTerminal(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpMenu = useCallback(() => {
    getEprMenu(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpAll = useCallback(() => {
    setAll(true);
    getEprAll(form)
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      })
      .finally(() => setAll(false));
  }, [form]);

  useEffect(() => {
    setInitalData(initalData);
    updateStatistics();

    socket.on("module/iiko", (data) => {
      if (data) {
        setValue("relationModule.options.status", data);
        if (
          data?.category?.status == "end" ||
          data?.product?.status == "end" ||
          data?.modifier?.status == "end" ||
          data?.organization?.status == "end" ||
          data?.terminal?.status == "end" ||
          data?.discount?.status == "end" ||
          data?.menu?.status == "end" ||
          data?.delivery?.status == "end" ||
          data?.payment?.status == "end"
        ) {
          updateStatistics();
        }
      }
    });

    return () => {
      socket.off("module/iiko");
    };
  }, [brand]);

  useEffect(() => {
    data && reset(data);
  }, [data]);

  return (
    <>
      <Card body className="mb-5">
        <h5 className="mb-4">Настройки</h5>
        <Row>
          <Col lg={6}>
            <div className="mb-3">
              <Input
                label="Логин"
                placeholder="Введите логин"
                name="relationModule.options.login"
                errors={errors}
                defaultValue={form?.relationModule?.options?.login}
                register={register}
                validation={{ required: "Обязательное поле" }}
              />
              {form?.relationModule?.options?.error ? (
                <p className="text-danger fs-07 mt-1">
                  {typeof form.relationModule.options.error === "object"
                    ? JSON.parse(form.relationModule.options.error)
                    : String(form.relationModule.options.error)}
                </p>
              ) : (
                <p className="text-muted fs-07 mt-1">
                  Спросите специалиста который подключал вам систему
                </p>
              )}
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Select
                label="Тип интеграции"
                placeholder="Выберите тип"
                className="me-3"
                onClick={(e) =>
                  setValue("relationModule.options.type", e.value)
                }
                value={form?.relationModule?.options?.type}
                data={[
                  { title: "Back Office", value: null },
                  { title: "Web", value: "web" },
                ]}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Input
                label="Идентификатор доставки"
                placeholder="Введите id"
                name="relationModule.options.deliveryApiId"
                errors={errors}
                defaultValue={form?.relationModule?.options?.deliveryApiId}
                register={register}
              />
              <small className="text-muted fs-08">
                Создайте товар в iiko с изменяемой суммой для доставки и укажите
                идентификатор.
              </small>
            </div>
          </Col>
          <Col lg={6}>
            <Form.Check className="mb-3">
              <Form.Check.Input
                type="checkbox"
                name="relationModule.options.callCenter"
                id="callCenter"
                defaultChecked={form?.relationModule?.options?.callCenter}
                {...register("relationModule.options.callCenter")}
              />
              <Form.Check.Label htmlFor="callCenter" className="ms-2">
                Получать заказы через колл-центр
              </Form.Check.Label>
            </Form.Check>
            <Form.Check className="mb-4">
              <Form.Check.Input
                type="checkbox"
                name="relationModule.options.required"
                id="required"
                defaultChecked={form?.relationModule?.options?.required}
                {...register("relationModule.options.required")}
              />
              <Form.Check.Label htmlFor="required" className="ms-2">
                Обязательные цены по филиалам
              </Form.Check.Label>
            </Form.Check>
          </Col>
        </Row>
        <h5 className="mt-3 mb-4">Типы доставок</h5>
        <Row>
          <Col md={4}>
            <div className="mb-3">
              <Select
                label="Доставка"
                placeholder="Выберите тип"
                className="me-3"
                onClick={(e) =>
                  setValue("relationModule.options.delivery.delivery", e.value)
                }
                value={
                  form?.relationModule?.options?.delivery?.delivery ?? null
                }
                data={
                  form?.relationModule?.options?.typesDelivery &&
                  form?.relationModule?.options?.typesDelivery[0]?.items
                    ?.length > 0
                    ? [
                        { title: "Не выбрано", value: null },
                        ...form.relationModule.options.typesDelivery[0]?.items.map(
                          (e) => ({
                            title: e.name,
                            value: e.id,
                          })
                        ),
                      ]
                    : []
                }
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-3">
              <Select
                label="Самовывоз"
                placeholder="Выберите тип"
                className="me-3"
                onClick={(e) =>
                  setValue("relationModule.options.delivery.pickup", e.value)
                }
                value={form?.relationModule?.options?.delivery?.pickup ?? null}
                data={
                  form?.relationModule?.options?.typesDelivery &&
                  form?.relationModule?.options?.typesDelivery[0]?.items
                    ?.length > 0
                    ? [
                        { title: "Не выбрано", value: null },
                        ...form.relationModule.options.typesDelivery[0]?.items.map(
                          (e) => ({
                            title: e.name,
                            value: e.id,
                          })
                        ),
                      ]
                    : []
                }
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-3">
              <Select
                label="В зале"
                placeholder="Выберите тип"
                className="me-3"
                onClick={(e) =>
                  setValue("relationModule.options.delivery.hall", e.value)
                }
                value={form?.relationModule?.options?.delivery?.hall ?? null}
                data={
                  form?.relationModule?.options?.typesDelivery &&
                  form?.relationModule?.options?.typesDelivery[0]?.items
                    ?.length > 0
                    ? [
                        { title: "Не выбрано", value: null },
                        ...form.relationModule.options.typesDelivery[0]?.items.map(
                          (e) => ({
                            title: e.name,
                            value: e.id,
                          })
                        ),
                      ]
                    : []
                }
              />
            </div>
          </Col>
        </Row>
        <h5 className="mt-3 mb-4">Типы оплат</h5>
        <Row>
          <Col md={3}>
            <div className="mb-3">
              <Select
                noSelect
                label="Банковской картой"
                placeholder="Выберите тип"
                className="me-3"
                onClick={(e) => {
                  let info = form?.relationModule?.options?.typesPayment.find(
                    (item) => item.id === e.value
                  );
                  if (info?.id && info?.paymentTypeKind) {
                    setValue("relationModule.options.payment.card", {
                      id: info.id,
                      paymentTypeKind: info.paymentTypeKind,
                    });
                  } else {
                    setValue("relationModule.options.payment.card", null);
                  }
                }}
                value={form?.relationModule?.options?.payment?.card?.id}
                data={
                  form?.relationModule?.options?.typesPayment?.length > 0
                    ? [
                        { title: "Не выбрано", value: null },
                        ...form.relationModule.options.typesPayment.map(
                          (e) => ({
                            title: e.name,
                            value: e.id,
                          })
                        ),
                      ]
                    : []
                }
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-3">
              <Select
                label="Наличными"
                placeholder="Выберите тип"
                className="me-3"
                onClick={(e) => {
                  let info = form?.relationModule?.options?.typesPayment.find(
                    (item) => item.id === e.value
                  );
                  if (info?.id && info?.paymentTypeKind) {
                    setValue("relationModule.options.payment.cash", {
                      id: info.id,
                      paymentTypeKind: info.paymentTypeKind,
                    });
                  } else {
                    setValue("relationModule.options.payment.cash", null);
                  }
                }}
                value={form?.relationModule?.options?.payment?.cash?.id ?? null}
                data={
                  form?.relationModule?.options?.typesPayment?.length > 0
                    ? [
                        { title: "Не выбрано", value: null },
                        ...form.relationModule.options.typesPayment.map(
                          (e) => ({
                            title: e.name,
                            value: e.id,
                          })
                        ),
                      ]
                    : []
                }
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-3">
              <Select
                label="Онлайн"
                placeholder="Выберите тип"
                className="me-3"
                onClick={(e) => {
                  let info = form?.relationModule?.options?.typesPayment.find(
                    (item) => item.id === e.value
                  );

                  if (info?.id && info?.paymentTypeKind) {
                    setValue("relationModule.options.payment.online", {
                      id: info.id,
                      paymentTypeKind: info.paymentTypeKind,
                    });
                  } else {
                    setValue("relationModule.options.payment.online", null);
                  }
                }}
                value={
                  form?.relationModule?.options?.payment?.online?.id ?? null
                }
                data={
                  form?.relationModule?.options?.typesPayment?.length > 0
                    ? [
                        { title: "Не выбрано", value: null },
                        ...form.relationModule.options.typesPayment.map(
                          (e) => ({
                            title: e.name,
                            value: e.id,
                          })
                        ),
                      ]
                    : []
                }
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-3">
              <Select
                label="Баллы"
                placeholder="Выберите тип"
                className="me-3"
                onClick={(e) => {
                  let info = form?.relationModule?.options?.typesPayment.find(
                    (item) => item.id === e.value
                  );
                  if (info?.id && info?.paymentTypeKind) {
                    setValue("relationModule.options.payment.point", {
                      id: info.id,
                      paymentTypeKind: info.paymentTypeKind,
                    });
                  } else {
                    setValue("relationModule.options.payment.point", null);
                  }
                }}
                value={
                  form?.relationModule?.options?.payment?.point?.id ?? null
                }
                data={
                  form?.relationModule?.options?.typesPayment?.length > 0
                    ? [
                        { title: "Не выбрано", value: null },
                        ...form.relationModule.options.typesPayment.map(
                          (e) => ({
                            title: e.name,
                            value: e.id,
                          })
                        ),
                      ]
                    : []
                }
              />
            </div>
          </Col>
        </Row>
        <Row>
          <h5 className="mt-3">Уведомления (callback)</h5>
          <Col lg={12}>
            <div className="mb-4 mt-4">
              <Input
                label="Ссылка для уведомлений (callback)"
                readOnly={false}
                defaultValue={`${
                  process.env.REACT_APP_WEBHOOK_URL
                }/accounting/${brand?.id ?? ""}/`}
              />
              <p className="text-muted fs-07 mt-1">
                Данную ссылку нужно указать в настройках интеграции.
              </p>
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <Button isValid={isValid} onClick={handleSubmit(onSubmit)}>
            Сохранить изменения
          </Button>
        </div>
      </Card>
      <div className="mb-4 d-flex align-items-center justify-content-between">
        <h5 className="fw-7">Синхронизация</h5>
        {/* <Button
          isLoading={all}
          disabled={all || !form?.relationModule?.options?.login}
          onClick={() => clickGetErpAll()}
          className="btn btn-sm btn-default text-gray"
        >
          <IoRefreshOutline size={16} className="text-gray me-2" />
          Выгрузить все
        </Button> */}
      </div>
      <Row>
        <Col md={4}>
          <Card body className="mb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-2 fw-6 h6">Категории</h5>
              <Button
                disabled={
                  !form?.relationModule?.options?.login ||
                  form?.relationModule?.options?.status?.category?.status ==
                    "process"
                }
                onClick={() => clickGetErpCategory()}
                className="btn btn-sm btn-default"
              >
                {!form?.relationModule?.options?.status?.category?.status ||
                form?.relationModule?.options?.status?.category?.status ==
                  "end" ? (
                  <IoRefreshOutline size={22} className="text-gray" />
                ) : (
                  form?.relationModule?.options?.status?.category?.text ??
                  "Выгрузка данных..."
                )}
              </Button>
            </div>
            <h2 className="m-0 d-flex align-items-center">
              {statistic.categories}
            </h2>
            <div className="mt-2">
              <Link
                to="/catalog/categories"
                disabled={!form?.relationModule?.options?.login}
                className="btn-sm btn-primary-outline"
              >
                Список
              </Link>
            </div>
          </Card>
        </Col>
        <Col md={4}>
          <Card body className="mb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-2 fw-6 h6">Товары</h5>
              <Button
                disabled={
                  !form?.relationModule?.options?.login ||
                  form?.relationModule?.options?.status?.product?.status ==
                    "process"
                }
                onClick={() => clickGetErpProduct()}
                className="btn btn-sm btn-default"
              >
                {!form?.relationModule?.options?.status?.product?.status ||
                form?.relationModule?.options?.status?.product?.status ==
                  "end" ? (
                  <IoRefreshOutline size={22} className="text-gray" />
                ) : (
                  form?.relationModule?.options?.status?.product?.text ??
                  "Выгрузка данных..."
                )}
              </Button>
            </div>
            <h2 className="m-0 d-flex align-items-center">
              {statistic.products}
            </h2>
            <div className="mt-2 d-flex flex-column">
              <Link
                to="/catalog/products"
                disabled={!form?.relationModule?.options?.login}
                className="btn-sm btn-primary-outline"
              >
                Список
              </Link>
              <Form.Check className="mt-2 mb-0">
                <Form.Check.Input
                  type="checkbox"
                  name="productImage"
                  className="btn-sm"
                  id="product-image"
                  {...register("productImage")}
                />
                <Form.Check.Label
                  htmlFor="product-image"
                  className="ms-2 fs-09"
                >
                  С картинками
                </Form.Check.Label>
              </Form.Check>
            </div>
          </Card>
        </Col>
        <Col md={4}>
          <Card body className="mb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-2 fw-6 h6">Модификаторы</h5>
              <Button
                disabled={
                  !form?.relationModule?.options?.login ||
                  form?.relationModule?.options?.status?.modifier?.status ==
                    "process"
                }
                onClick={() => clickGetErpModifier()}
                className="btn btn-sm btn-default"
              >
                {!form?.relationModule?.options?.status?.modifier?.status ||
                form?.relationModule?.options?.status?.modifier?.status ==
                  "end" ? (
                  <IoRefreshOutline size={22} className="text-gray" />
                ) : (
                  form?.relationModule?.options?.status?.modifier?.text ??
                  "Выгрузка данных..."
                )}
              </Button>
            </div>
            <h2 className="m-0 d-flex align-items-center">
              {statistic.modifiers}
            </h2>
            <div className="mt-2">
              <Link
                to="/catalog/modifiers"
                disabled={!form?.relationModule?.options?.login}
                className="btn-sm btn-primary-outline"
              >
                Список
              </Link>
            </div>
          </Card>
        </Col>
        {Number(statistic?.additions) > 0 && (
          <Col md={4}>
            <Card body className="mb-4">
              <div className="d-flex align-items-center justify-content-between">
                <h5 className="mb-2 fw-6 h6">Добавки</h5>
              </div>
              <h2 className="m-0 d-flex align-items-center">
                {statistic.additions}
              </h2>
              <div className="mt-2">
                <Link
                  to="/catalog/additions"
                  className="btn-sm btn-primary-outline"
                >
                  Список
                </Link>
              </div>
            </Card>
          </Col>
        )}
        <Col md={4}>
          <Card body className="mb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-2 fw-6 h6">Столы</h5>
              <Button
                disabled={
                  !form?.relationModule?.options?.login ||
                  form?.relationModule?.options?.status?.table?.status ==
                    "process"
                }
                onClick={() => clickGetErpTable()}
                className="btn btn-sm btn-default"
              >
                {!form?.relationModule?.options?.status?.table?.status ||
                form?.relationModule?.options?.status?.table?.status ==
                  "end" ? (
                  <IoRefreshOutline size={22} className="text-gray" />
                ) : (
                  form?.relationModule?.options?.status?.table?.text ??
                  "Выгрузка данных..."
                )}
              </Button>
            </div>
            <h2 className="m-0 d-flex align-items-center">
              {statistic.tables}
            </h2>
            <div className="mt-2">
              <Link
                to="/options/affiliates/tables"
                disabled={!form?.relationModule?.options?.login}
                className="btn-sm btn-primary-outline"
              >
                Список
              </Link>
            </div>
          </Card>
        </Col>
      </Row>
      {/* <Row>
        <Col md={6}>
          <Card body className="mb-4">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h5 className="mb-2 fw-6 h6">Webhook</h5>
              <Button
                disabled={!form?.relationModule?.options?.webhook?.accounting}
                // onClick={() => clickGetErpModifier()}
                className="btn btn-sm btn-default"
              >
                <IoRefreshOutline size={22} className="text-gray" />
              </Button>
            </div>
            <div className="mb-3">
              <Input
                label="Статусы заказа"
                name="relationModule.options.webhook.accounting"
                readOnly={false}
                errors={errors}
                defaultValue={
                  form?.relationModule?.options?.webhook?.accounting ??
                  `${process.env.REACT_APP_BASE_URL}/webhook/accounting/${
                    brand?.id ?? ""
                  }/`
                }
                register={register}
              />
            </div>
            <div className="mb-3">
              <Input
                label="Стоп меню"
                name="relationModule.options.webhook.stop"
                readOnly={false}
                errors={errors}
                defaultValue={
                  form?.relationModule?.options?.webhook?.stop ??
                  `${process.env.REACT_APP_BASE_URL}/webhook/stop/${
                    brand?.id ?? ""
                  }/`
                }
                register={register}
              />
            </div>
          </Card>
        </Col>
      </Row> */}

      <h5 className="my-4 fw-7">Данные для заказа</h5>
      <Row>
        <Col md={4}>
          <Row>
            <Col md={12}>
              <Card body className="mb-4">
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="mb-2 fw-6 h6">Организации</h5>
                  <Button
                    disabled={
                      !form?.relationModule?.options?.login ||
                      form?.relationModule?.options?.status?.organization
                        ?.status == "process"
                    }
                    onClick={() => clickGetErpOrganization()}
                    className="btn btn-sm btn-default"
                  >
                    {!form?.relationModule?.options?.status?.organization
                      ?.status ||
                    form?.relationModule?.options?.status?.organization
                      ?.status == "end" ? (
                      <IoRefreshOutline size={22} className="text-gray" />
                    ) : (
                      form?.relationModule?.options?.status?.organization
                        ?.text ?? "Выгрузка данных..."
                    )}
                  </Button>
                </div>
                <h2 className="m-0 d-flex align-items-center">
                  {form?.relationModule &&
                  Array.isArray(form?.relationModule?.options?.organizations)
                    ? form.relationModule.options.organizations.length
                    : 0}
                </h2>
                <div className="body-scroll">
                  {form?.relationModule &&
                    Array.isArray(
                      form?.relationModule?.options?.organizations
                    ) &&
                    form?.relationModule?.options?.organizations &&
                    form?.relationModule?.options?.organizations.map(
                      (item, index) => (
                        <div className="mt-3 d-flex align-items-center">
                          <div className="me-3">
                            <Form.Check.Input
                              type="checkbox"
                              name="main"
                              id="main-org"
                              defaultChecked={item?.main}
                              onClick={() => {
                                // form.relationModule.options.organizations =
                                //   form.relationModule.options.organizations.map(
                                //     (e) => {
                                //       e.main = false;
                                //       return e;
                                //     }
                                //   );

                                form.relationModule.options.organizations[
                                  index
                                ].main = !!!item.main;
                                setValue(
                                  "relationModule.options.organizations",
                                  form.relationModule.options.organizations
                                );
                              }}
                            />
                          </div>
                          <div>
                            <p className="fs-07 text-muted">{item.id}</p>
                            <p className="fs-09">
                              <span>{item.name}</span>
                              <span>{item.orderServiceType}</span>
                            </p>
                          </div>
                        </div>
                      )
                    )}
                </div>
              </Card>
            </Col>
            <Col md={12}>
              {form?.relationModule?.options?.type === "web" && (
                <Card body className="mb-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 className="mb-2 fw-6 h6">Внешнее меню</h5>
                    <Button
                      disabled={
                        !form?.relationModule?.options?.login ||
                        form?.relationModule?.options?.status?.menu?.status ==
                          "process"
                      }
                      onClick={() => clickGetErpMenu()}
                      className="btn btn-sm btn-default"
                    >
                      {!form?.relationModule?.options?.status?.menu?.status ||
                      form?.relationModule?.options?.status?.menu?.status ==
                        "end" ? (
                        <IoRefreshOutline size={22} className="text-gray" />
                      ) : (
                        form?.relationModule?.options?.status?.menu?.text ??
                        "Выгрузка данных..."
                      )}
                    </Button>
                  </div>

                  <h2 className="m-0 d-flex align-items-center">
                    {form?.relationModule &&
                    Array.isArray(form?.relationModule?.options?.menus)
                      ? form.relationModule.options.menus.length
                      : 0}
                  </h2>
                  <div className="body-scroll">
                    <h5 className="mb-2 mt-3 fw-6 h6">Категории</h5>
                    {form?.relationModule &&
                    Array.isArray(
                      form?.relationModule?.options?.menuCategories
                    ) &&
                    form?.relationModule?.options?.menuCategories?.length >
                      0 ? (
                      form?.relationModule?.options?.menuCategories.map(
                        (item, index) => (
                          <div className="mt-3 d-flex align-items-center">
                            <div className="me-3">
                              <Form.Check.Input
                                type="radio"
                                name="category_main"
                                id="main-category"
                                defaultChecked={item?.main}
                                onClick={() => {
                                  form.relationModule.options.menuCategories =
                                    form.relationModule.options.menuCategories.map(
                                      (e) => {
                                        if (e.id == item.id) {
                                          e.main = !!!item.main;
                                        } else {
                                          e.main = false;
                                        }
                                        return e;
                                      }
                                    );
                                  setValue(
                                    "relationModule.options.menuCategories",
                                    form.relationModule.options.menuCategories
                                  );
                                }}
                              />
                            </div>
                            <div>
                              <p className="fs-07 text-muted">{item.id}</p>
                              <div>{item.name}</div>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <span className="text-muted fs-09">Нет данных</span>
                    )}
                    <h5 className="mb-2 mt-3 fw-6 h6">Меню</h5>
                    {form?.relationModule &&
                    Array.isArray(form?.relationModule?.options?.menus) &&
                    form?.relationModule?.options?.menus?.length > 0 ? (
                      form?.relationModule?.options?.menus.map(
                        (item, index) => (
                          <div className="mt-3 d-flex align-items-center">
                            <div className="me-3">
                              <Form.Check.Input
                                type="radio"
                                name="menu_main"
                                id="main-menu"
                                defaultChecked={item?.main}
                                onClick={() => {
                                  form.relationModule.options.menus =
                                    form.relationModule.options.menus.map(
                                      (e) => {
                                        if (e.id == item.id) {
                                          e.main = !!!item.main;
                                        } else {
                                          e.main = false;
                                        }
                                        return e;
                                      }
                                    );
                                  setValue(
                                    "relationModule.options.menus",
                                    form.relationModule.options.menus
                                  );
                                }}
                              />
                            </div>
                            <div>
                              <p className="fs-07 text-muted">{item.id}</p>
                              <div>{item.name}</div>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <span className="text-muted fs-09">Нет данных</span>
                    )}
                  </div>
                </Card>
              )}
              <Card body className="mb-4">
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="mb-2 fw-6 h6">Купоны</h5>
                  <Button
                    disabled={
                      !form?.relationModule?.options?.login ||
                      form?.relationModule?.options?.status?.coupon?.status ==
                        "process"
                    }
                    onClick={() => clickGetErpCoupon()}
                    className="btn btn-sm btn-default"
                  >
                    {!form?.relationModule?.options?.status?.coupon?.status ||
                    form?.relationModule?.options?.status?.coupon?.status ==
                      "end" ? (
                      <IoRefreshOutline size={22} className="text-gray" />
                    ) : (
                      form?.relationModule?.options?.status?.coupon?.text ??
                      "Выгрузка данных..."
                    )}
                  </Button>
                </div>
                <h2 className="m-0 d-flex align-items-center">
                  {form?.relationModule &&
                  Array.isArray(form?.relationModule?.options?.coupons)
                    ? form.relationModule.options.coupons.length
                    : 0}
                </h2>
                <div className="body-scroll">
                  {form?.relationModule &&
                    Array.isArray(form?.relationModule?.options?.coupons) &&
                    form?.relationModule?.options?.coupons &&
                    form?.relationModule?.options?.coupons.map((item) => (
                      <>
                        <p className="mt-3 fs-07 text-muted d-flex justify-content-between">
                          {item.id}
                        </p>
                        <p className="fs-09 d-flex justify-content-between">
                          <span>{item.name}</span>
                          <span>{item.orderServiceType}</span>
                        </p>
                      </>
                    ))}
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Row>
            <Col md={12}>
              <Card body className="mb-4">
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="mb-2 fw-6 h6">Типы оплаты</h5>
                  <Button
                    disabled={
                      !form?.relationModule?.options?.login ||
                      form?.relationModule?.options?.status?.payment?.status ==
                        "process"
                    }
                    onClick={() => clickGetErpTypesPayment()}
                    className="btn btn-sm btn-default"
                  >
                    {!form?.relationModule?.options?.status?.payment?.status ||
                    form?.relationModule?.options?.status?.payment?.status ==
                      "end" ? (
                      <IoRefreshOutline size={22} className="text-gray" />
                    ) : (
                      form?.relationModule?.options?.status?.payment?.text ??
                      "Выгрузка данных..."
                    )}
                  </Button>
                </div>
                <h2 className="m-0 d-flex align-items-center">
                  {form?.relationModule?.options?.typesPayment?.length ?? 0}
                </h2>
                <div className="body-scroll">
                  {form?.relationModule?.options?.typesPayment &&
                    form?.relationModule?.options?.typesPayment.map((item) => (
                      <>
                        <p className="mt-3 fs-07 text-muted d-flex justify-content-between">
                          {item.id}
                        </p>
                        <p className="fs-09 d-flex justify-content-between">
                          <span>{item.name}</span>
                          <span>{item.code}</span>
                        </p>
                      </>
                    ))}
                </div>
              </Card>
            </Col>
            <Col md={12}>
              <Card body className="mb-4">
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="mb-2 fw-6 h6">Терминалы</h5>
                  <Button
                    disabled={
                      !form?.relationModule?.options?.login ||
                      form?.relationModule?.options?.status?.terminal?.status ==
                        "process"
                    }
                    onClick={() => clickGetErpTerminal()}
                    className="btn btn-sm btn-default"
                  >
                    {!form?.relationModule?.options?.status?.terminal?.status ||
                    form?.relationModule?.options?.status?.terminal?.status ==
                      "end" ? (
                      <IoRefreshOutline size={22} className="text-gray" />
                    ) : (
                      form?.relationModule?.options?.status?.terminal?.text ??
                      "Выгрузка данных..."
                    )}
                  </Button>
                </div>
                <div className="body-scroll">
                  {Array.isArray(form?.relationModule?.options?.terminals) &&
                    form?.relationModule?.options?.terminals?.length > 0 &&
                    form.relationModule.options.terminals.map((item) => {
                      return (
                        <>
                          <p className="mt-3 fs-07 text-muted d-flex justify-content-between">
                            Организация: {item.organizationId}
                          </p>
                          {item?.items?.length > 0 &&
                            item.items.map((item) => (
                              <>
                                <p className="mt-2 fs-09 d-flex justify-content-between">
                                  <span>{item.name}</span>
                                  <span>{item.address}</span>
                                </p>
                                <p className="fs-07 text-muted d-flex justify-content-between">
                                  Терминал: {item.id}
                                </p>
                              </>
                            ))}
                        </>
                      );
                    })}
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Row>
            <Col md={12}>
              <Card body className="mb-4">
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="mb-2 fw-6 h6">Скидки</h5>
                  <Button
                    disabled={
                      !form?.relationModule?.options?.login ||
                      form?.relationModule?.options?.status?.discount?.status ==
                        "process"
                    }
                    onClick={() => clickGetErpDiscount()}
                    className="btn btn-sm btn-default"
                  >
                    {!form?.relationModule?.options?.status?.discount?.status ||
                    form?.relationModule?.options?.status?.discount?.status ==
                      "end" ? (
                      <IoRefreshOutline size={22} className="text-gray" />
                    ) : (
                      form?.relationModule?.options?.status?.discount?.text ??
                      "Выгрузка данных..."
                    )}
                  </Button>
                </div>
                <h2 className="m-0 d-flex align-items-center">
                  {form?.relationModule &&
                  Array.isArray(form?.relationModule?.options?.discounts)
                    ? form.relationModule.options.discounts.length
                    : 0}
                </h2>
                <div className="body-scroll">
                  {form?.relationModule &&
                    Array.isArray(form?.relationModule?.options?.discounts) &&
                    form?.relationModule?.options?.discounts &&
                    form?.relationModule?.options?.discounts.map((item) => (
                      <>
                        <p className="mt-3 fs-07 text-muted d-flex justify-content-between">
                          {item.id}
                        </p>
                        <p className="fs-09 d-flex justify-content-between">
                          <span>{item.name}</span>
                          <span>{item.orderServiceType}</span>
                        </p>
                      </>
                    ))}
                </div>
              </Card>
            </Col>
            <Col md={12}>
              <Card body className="mb-4">
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="mb-2 fw-6 h6">Типы доставки</h5>
                  <Button
                    disabled={
                      !form?.relationModule?.options?.login ||
                      form?.relationModule?.options?.status?.delivery?.status ==
                        "process"
                    }
                    onClick={() => clickGetErpTypesDelivery()}
                    className="btn btn-sm btn-default"
                  >
                    {!form?.relationModule?.options?.status?.delivery?.status ||
                    form?.relationModule?.options?.status?.delivery?.status ==
                      "end" ? (
                      <IoRefreshOutline size={22} className="text-gray" />
                    ) : (
                      form?.relationModule?.options?.status?.delivery?.text ??
                      "Выгрузка данных..."
                    )}
                  </Button>
                </div>
                <h2 className="m-0 d-flex align-items-center">
                  {Array.isArray(
                    form?.relationModule?.options?.typesDelivery
                  ) &&
                  form?.relationModule?.options?.typesDelivery[0]?.items
                    ?.length > 0
                    ? form.relationModule.options.typesDelivery[0].items?.length
                    : 0}
                </h2>
                <div className="body-scroll">
                  {Array.isArray(
                    form?.relationModule?.options?.typesDelivery
                  ) &&
                    form?.relationModule?.options?.typesDelivery[0]?.items &&
                    form?.relationModule?.options?.typesDelivery[0]?.items.map(
                      (item) => (
                        <>
                          <p className="mt-3 fs-07 text-muted d-flex justify-content-between">
                            {item.id}
                          </p>
                          <p className="fs-09 d-flex justify-content-between">
                            <span>{item.name}</span>
                            <span>{item.orderServiceType}</span>
                          </p>
                        </>
                      )
                    )}
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default IikoForm;
