import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
} from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import {
  IoChevronBackOutline,
  IoCreateOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useParams } from "react-router-dom";
import ImageCropper from "../../components/Cropper";
import DataTable from "../../components/DataTable";
import DragDropFile from "../../components/DragDropFile";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Textarea from "../../components/UI/Textarea";
import CustomModal from "../../components/utils/CustomModal";
import { convertWeight, customPrice, kkal } from "../../helpers/product";
import { editAddition, getAddition } from "../../services/addition";
import { getIngredients } from "../../services/ingredient";
import Info from "../../components/UI/Info";
import { getImageURL } from "../../helpers/image";
import PreviewImages from "../../components/PreviewImages";
import Select from "../../components/UI/Select";

const AdditionEdit = () => {
  const { additionId } = useParams();
  const [loading, setLoading] = useState(true);

  const [editImageProduct, setEditImageProduct] = useState({
    show: false,
    data: [],
  });
  const [editIngredient, setEditIngredient] = useState({
    show: false,
    data: {},
  });
  const [listStorage, setListIngredient] = useState({
    loading: true,
    show: false,
    items: [],
    selected: [],
  });
  const [modalIngredientDelete, setModalIngredientDelete] = useState({
    show: false,
    id: false,
  });

  const ingredientColumns = [
    {
      name: "Название",
      selector: "title",
    },
    {
      name: "Описание",
      selector: "desc",
    },
    {
      name: "БЖУ",
      cell: (row) => (
        <span>
          {row.energy.protein}
          <sup>Б</sup> {row.energy.fat}
          <sup>Ж</sup> {row.energy.carbohydrate}
          <sup>У</sup>
        </span>
      ),
    },
  ];

  const {
    control,
    register,
    formState: { errors },
    reset,
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {
      options: {
        typeSend: null,
      },
    },
  });

  const data = useWatch({ control });

  const kkalData = useRef();

  useEffect(() => {
    if (listStorage.show) {
      if (!listStorage?.items?.length) {
        setListIngredient((e) => ({
          ...e,
          loading: true,
        }));
      }
      getIngredients()
        .then((res) =>
          setListIngredient((e) => ({
            ...e,
            loading: false,
            ...res,
          }))
        )
        .catch(() =>
          setListIngredient((e) => ({
            ...e,
            loading: false,
          }))
        );
    }
  }, [listStorage.show]);

  useLayoutEffect(() => {
    getAddition(additionId)
      .then((res) => {
        if (res) {
          reset(res);
          kkalData.current = kkal(res?.addition?.storages);
          setLoading(false);
        }
      })
      .catch(() => setLoading(false));
  }, []);

  const onSubmit = useCallback((data) => {
    var formData = new FormData();

    formData.append("dataParse", JSON.stringify(data));

    if (data?.file) {
      for (let file of data?.file) {
        formData.append("file", file);
      }
    }
    editAddition(formData)
      .then(() => NotificationManager.success("Добавка успешно обновлена"))
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  const FormIngredient = ({ data, onChange }) => {
    return (
      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Input
              defaultValue={data.weight ?? 0}
              label="Вес, г"
              name="weight"
              type="number"
              onChange={(e) => onChange && onChange("weight", e)}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Input
              defaultValue={data.price ?? 0}
              label="Цена"
              type="number"
              name="price"
              onChange={(e) => onChange && onChange("price", e)}
            />
          </div>
        </Col>
      </Row>
    );
  };

  if (loading) {
    return <Loader full />;
  }

  if (!data) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Такой добавки нет</h3>
      </Info>
    );
  }

  return (
    <>
      <Meta title="Редактировать добавку" />
      <div>
        <Link
          to="/catalog/additions"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
      </div>
      <h3 className="mb-4">Редактировать добавку</h3>
      <Row>
        <Col md={8}>
          <Card>
            <Card.Body>
              <Row>
                <Col md={8}>
                  <div className="mb-3">
                    <Input
                      label="Название"
                      name="title"
                      errors={errors}
                      register={register}
                      validation={{
                        required: "Обязательное поле",
                      }}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Input
                      label="Артикул"
                      name="code"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Input
                      defaultValue={0}
                      label="Порядок"
                      name="priority"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Select
                      classNameContainer="w-100"
                      label="Тип отправки"
                      data={[
                        { title: "Модификатор", value: null },
                        { title: "Блюдо", value: "product" },
                      ]}
                      value={data.options?.typeSend ?? null}
                      onClick={(e) => {
                        setValue("options.typeSend", e.value);
                      }}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <Textarea
                      defaultValue={data.description}
                      label="Описание"
                      name="description"
                      errors={errors}
                      rows={6}
                      register={register}
                    />
                  </div>
                </Col>
                {/* <Col lg={6}>
                  <div className="mb-3">
                    <Select
                      label="Категория"
                      search
                      value={data.categoryId}
                      data={categories.items.map((e) => ({
                        title: e.title,
                        value: e.id,
                      }))}
                      onClick={(e) => setValue("categoryId", e.value)}
                    />
                  </div>
                </Col> */}
                {data.apiId && (
                  <Col md={12}>
                    <Input
                      defaultValue={data.apiId}
                      label="API ID"
                      name="apiId"
                      readOnly={false}
                    />
                  </Col>
                )}
              </Row>
            </Card.Body>
            {/* {data?.storages?.length > 0 ? (
              <>
                <div className="d-flex">
                  <Button
                    className="btn-primary me-3"
                    onClick={() =>
                      setListIngredient((e) => ({ ...e, show: true }))
                    }
                  >
                    Создать
                  </Button>
                  <Button
                    className="btn-primary-outline"
                    onClick={() =>
                      setListIngredient((e) => ({ ...e, show: true }))
                    }
                  >
                    Выбрать из списка
                  </Button>
                </div>
                <Accordion defaultActiveKey="0" className="mt-3">
                  {data.storages.map((e, index) => (
                    <Accordion.Item eventKey={index}>
                      <div className="d-flex align-items-center">
                        <Accordion.Header className="w-100">
                          {e?.title ??
                            e?.ingredient?.title ??
                            "Нет названия продукта"}
                        </Accordion.Header>
                        <a
                          className="mx-2"
                          onClick={() =>
                            setEditIngredient((info) => ({
                              show: !info.show,
                              data: e,
                            }))
                          }
                        >
                          <IoCreateOutline size={22} className="text-muted" />
                        </a>
                        <a
                          className="mx-2"
                          onClick={() =>
                            setModalIngredientDelete({
                              show: !modalIngredientDelete.show,
                              id: e.id,
                            })
                          }
                        >
                          <IoTrashOutline size={20} className="text-danger" />
                        </a>
                      </div>
                      <Accordion.Body>
                        <Row>
                          <Col md="auto">
                            <img
                              src="/images/empty-product-image.png"
                              width={40}
                              height={40}
                            />
                          </Col>
                          <Col>
                            <p className="text-muted fs-09">Описание</p>
                            {e?.desc ?? e?.ingredient?.desc ?? "Описания нет"}
                          </Col>
                          <Col
                            md="auto"
                            className="d-flex flex-column align-items-end"
                          >
                            <p className="text-muted fs-09">Цена</p>
                            {customPrice(e.price)}
                          </Col>
                          <Col
                            md="auto"
                            className="d-flex flex-column align-items-end"
                          >
                            <p className="text-muted fs-09">Вес</p>
                            {convertWeight(e.weight ?? 0)}
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </>
            ) : (
              <p className="my-5 text-muted text-center">
                Состав отсутствует
                <div className="mt-3 d-flex justify-content-center">
                  <Link className="btn-primary me-3" to="/ingredient/create">
                    Создать
                  </Link>
                  <Button
                    className="btn-primary-outline"
                    onClick={() =>
                      setListIngredient((e) => ({ ...e, show: true }))
                    }
                  >
                    Выбрать из списка
                  </Button>
                </div>
              </p>
            )} */}
          </Card>
        </Col>
        <Col md={4} className="position-relative">
          <div className="position-sticky top-1">
            <Card className="mb-3" body>
              <DragDropFile
                col={3}
                maxAspect={1}
                file={data.file}
                onChange={(e) => setValue("file", e)}
                onDelete={(e) => setValue("file", e.file)}
              />
              <PreviewImages
                medias={data?.media}
                type="addition"
                col={6}
                onDelete={(e) => {
                  setValue("media", e.media);
                  let newArrayDelete =
                    data?.delete?.length > 0
                      ? [...data.delete, e.delete]
                      : [e.delete];
                  setValue("delete", newArrayDelete);
                }}
              />
            </Card>
            <Card body className="mb-3">
              <p className="fs-08">
                Энергетическая ценность в 100г -{" "}
                <span className="text-success">
                  {kkalData?.current?.kkal ?? 0}
                </span>{" "}
                ккал
              </p>
              <Row className="gx-2">
                <Col lg={4}>
                  <div className="mt-4">
                    <Input
                      value={kkalData?.current?.protein ?? 0}
                      label="Белки, г"
                      readOnly={false}
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mt-4">
                    <Input
                      value={kkalData?.current?.fat ?? 0}
                      label="Жиры, г"
                      readOnly={false}
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mt-4">
                    <Input
                      value={kkalData?.current?.carbohydrate ?? 0}
                      label="Углеводы, г"
                      readOnly={false}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="gx-2">
                <Col lg={6}>
                  <div className="mt-4">
                    <Input
                      value={kkalData?.current?.kkalAll ?? 0}
                      label="Всего ккал, г"
                      readOnly={false}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mt-4">
                    <Input
                      defaultValue={kkalData?.current?.weight ?? 0}
                      label="Вес, г"
                      name="energy.weight"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
            <Card body className="mb-3">
              <p className="fs-08">Цена</p>
              <Row className="gx-2">
                <Col lg={6}>
                  <div className="mt-4">
                    <Input
                      defaultValue={0}
                      label="Цена"
                      name="price"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mt-4">
                    <Input
                      defaultValue={0}
                      label="Скидка"
                      name="discount"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
            <Button
              className="btn-primary align-self-end w-100 mt-3"
              onClick={handleSubmit(onSubmit)}
            >
              Сохранить
            </Button>
          </div>
        </Col>
      </Row>
      <ImageCropper
        file={editImageProduct.data[0]}
        show={editImageProduct.show}
        setShow={(e) => setEditImageProduct((info) => ({ ...info, show: e }))}
        onComplete={(e) => {
          e && setValue("file", e.file);
          e && setValue("medias", e.blob);
        }}
      />

      <CustomModal
        size="lg"
        title="Редактировать состав"
        show={editIngredient.show}
        setShow={(e) => setEditIngredient((list) => ({ ...list, show: e }))}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() => setEditIngredient((e) => ({ ...e, show: false }))}
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              onClick={() => {
                let newArray = data.storages.map((e) =>
                  e.id === editIngredient.data.id ? editIngredient.data : e
                );
                setValue("storages", newArray);
                setEditIngredient({ show: false, data: {} });
                kkalData.current = kkal(newArray);
              }}
            >
              Сохранить продукт
            </Button>
          </>
        }
      >
        <FormIngredient
          data={editIngredient.data}
          onChange={(title, value) =>
            setEditIngredient((info) => {
              info.data[title] = value;
              return info;
            })
          }
        />
      </CustomModal>
      <CustomModal
        title="Список продукции"
        show={listStorage.show}
        setShow={(e) => setListIngredient((list) => ({ ...list, show: e }))}
        classNameBody="py-2 px-0"
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() => setListIngredient((e) => ({ ...e, show: false }))}
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              disabled={listStorage?.selected?.length === 0}
              onClick={() => {
                if (listStorage?.selected?.length > 0) {
                  let newArray = data.storages.concat(
                    listStorage.selected.map((e) => ({
                      productId: data.id,
                      ingredientId: e.item.id,
                      ingredient: e.item,
                      weight: 0,
                      price: 0,
                    }))
                  );
                  setValue("storages", newArray);
                  setListIngredient((e) => ({
                    ...e,
                    items: listStorage.items.filter(
                      (e) =>
                        !listStorage.selected.find((e2) => e2.item.id === e.id)
                    ),
                    selected: [],
                    show: false,
                  }));
                  kkalData.current = kkal(newArray);
                }
              }}
            >
              Добавить выбранное
            </Button>
          </>
        }
      >
        {listStorage.loading ? (
          <Loader className="d-flex m-auto my-5" />
        ) : listStorage?.items.length === 0 ? (
          <p className="my-5 text-muted text-center">Пока нет продукции</p>
        ) : (
          <DataTable
            columns={ingredientColumns}
            data={listStorage.items}
            onChange={(items) =>
              setListIngredient((e) => ({ ...e, selected: items }))
            }
            lite
            selectable
          />
        )}
      </CustomModal>
      <CustomModal
        title="Удаление элемента"
        show={modalIngredientDelete.show}
        setShow={(e) => setModalIngredientDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() =>
                setModalIngredientDelete({
                  show: !modalIngredientDelete.show,
                  id: false,
                })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-danger"
              onClick={() => {
                setValue(
                  "storages",
                  data.storages.filter((e) => e.id != modalIngredientDelete.id)
                );
                setListIngredient((e) => ({
                  ...e,
                  items: [
                    ...listStorage.items,
                    data.storages.find(
                      (e) => e.id === modalIngredientDelete.id
                    ),
                  ],
                  selected: [],
                }));
                setModalIngredientDelete({ show: false, id: false });
              }}
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить состав из товара?
      </CustomModal>
    </>
  );
};

export default AdditionEdit;
