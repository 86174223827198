const statusTypes = [
  {
    text: "Предзаказ",
    id: "reservation",
    className: "status-order-reservation",
  },
  { text: "Новый", id: "new", className: "status-order-new" },
  { text: "Готовится", id: "preparing", className: "status-order-preparing" },
  {
    text: "На выдаче",
    id: "prepared",
    className: "status-order-prepared",
  },
  { text: "Доставка", id: "delivery", className: "status-order-delivery" },
  { text: "Завершен", id: "done", className: "status-order-done" },
  {
    text: "В обработке",
    id: "processing",
    className: "status-order-processing",
  },
  { text: "Отменен", id: "canceled", className: "status-order-canceled" },
];

const typeTypes = [
  {
    text: "Сайт",
    id: "site",
    className: "site-order",
    icon: "/images/source/site.svg",
  },
  {
    text: "Приложение",
    id: "app",
    className: "app-order",
    icon: "/images/source/app.svg",
  },
  {
    text: "ВКонтакте",
    id: "vk",
    className: "vk-order",
    icon: "/images/source/vk.svg",
  },
];

const deliveryTypes = [
  {
    text: "Самовывоз",
    id: "affiliate",
    className: "delivery-order-1",
    icon: "/images/delivery/pickup.svg",
  },
  {
    text: "Доставка",
    id: "delivery",
    className: "status-order-2",
    icon: "/images/delivery/delivery.svg",
  },
  {
    text: "В заведении",
    id: "cafe",
    className: "status-order-3",
    icon: "/images/delivery/cafe.svg",
  },
];

const paymentTypes = [
  {
    text: "Банковской картой",
    id: 1,
    type: "card",
    className: "payment-order-1",
    icon: "/images/payments/card.svg",
  },
  {
    text: "Наличными",
    id: 2,
    type: "cash",
    className: "payment-order-2",
    icon: "/images/payments/cash.svg",
  },
  {
    text: "Онлайн",
    id: 3,
    type: "online",
    className: "payment-order-3",
    icon: "/images/payments/online.svg",
  },
  { text: "Расчетный счет", id: 4, type: "ip", className: "payment-order-4" },
];

const statusData = (data) => {
  for (const [key, value] of Object.entries(data)) {
    if (!value) {
      break;
    }
  }
  let info = statusTypes.find((e) => e.id === data) ?? statusTypes[0];
  return info;
};

const typeData = (value) => {
  let info = typeTypes.find((e) => e.id === value) ?? typeTypes[0];
  return info;
};

const deliveryData = (value) => {
  let info = deliveryTypes.find((e) => e.id === value) ?? deliveryTypes[0];
  return info;
};

const paymentData = (value) => {
  let info = paymentTypes.find((e) => e.type === value) ?? paymentTypes[0];
  return info;
};

export {
  paymentData,
  deliveryData,
  statusData,
  typeData,
  statusTypes,
  deliveryTypes,
  paymentTypes,
  typeTypes,
};
