import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getBanners = async (data) => {
  const response = await $authApi.get(apiRoutes.BANNERS, {
    params: data,
  });

  return response?.data;
};

const getBanner = async (id) => {
  const response = await $authApi.get(apiRoutes.BANNER, {
    params: {
      id,
    },
  });

  return response?.data;
};

const createBanner = async (data) => {
  const response = await $authApi.post(apiRoutes.BANNER_CREATE, data);
  return response?.data;
};

const editBanner = async (data) => {
  const response = await $authApi.post(apiRoutes.BANNERS, data);
  return response?.data;
};

const deleteBanner = async (ids) => {
  const response = await $authApi.delete(apiRoutes.BANNERS, {
    data: { ids },
  });
  return response?.data;
};

export { getBanners, getBanner, createBanner, editBanner, deleteBanner };
