import moment from "moment";
import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  IoAdd,
  IoCloseOutline,
  IoSearchOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { Link, useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Button from "../../components/UI/Button";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import {
  deleteNotification,
  getNotifications,
} from "../../services/notification";
import Select from "../../components/UI/Select";
import { Badge, Col, Row } from "react-bootstrap";
import Input from "../../components/UI/Input";
import Meta from "../../components/Meta";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Notifications = () => {
  const { t } = useTranslation();
  const inputRef = createRef();
  const brand = useSelector((state) => state.brand.active);
  const [searchParams, setSearchParams] = useSearchParams();

  const [notifications, setNotifications] = useState({
    loading: true,
    items: [],
    pagination: false,
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });

  const notificationColumns = [
    {
      name: "Заголовок",
      selector: "title",
    },
    {
      name: "Описание",
      selector: "desc",
      align: "left",
    },
    {
      width: "120px",
      name: "Настройки",
      align: "center",
      selector: "status",
      cell: (row) =>
        row.options?.sex ? (
          <Badge bg="success">
            {t(row.options?.sex === "man" ? "Мужчины" : "Женщины")}
          </Badge>
        ) : row.options?.login ? (
          <Badge bg="success">{row.options?.login}</Badge>
        ) : (
          "-"
        ),
    },
    {
      name: "Кол-во",
      width: "100px",
      align: "center",
      cell: (row) => row?.options?.count,
    },
    {
      name: "Время отправки",
      selector: "createdAt",
      width: "160px",
      align: "center",
      sortable: true,
      cell: (row) => moment(row.createdAt).format("DD.MM.YYYY kk:mm"),
    },
    {
      width: "120px",
      name: "Статус",
      align: "center",
      selector: "status",
      cell: (row) =>
        row.status === 0 ? (
          <Badge bg="secondary">{t("Архив")}</Badge>
        ) : row.status === -1 ? (
          <Badge bg="danger">{t("Ошибка")}</Badge>
        ) : (
          <Badge bg="success">{t("Отправлено")}</Badge>
        ),
    },
    {
      selector: "action",
      center: true,
      width: "60px",
      cell: (row) => (
        <a
          onClick={() =>
            setModalDelete({ show: !modalDelete.show, id: row.id })
          }
        >
          <IoTrashOutline size={20} className="text-danger" />
        </a>
      ),
    },
  ];

  const getData = useCallback(async () => {
    getNotifications(searchParams)
      .then(
        (res) =>
          res &&
          setNotifications((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }))
      )
      .finally(() => setProducts((prev) => ({ ...prev, loading: false })));
  }, [searchParams, brand]);

  const onSearch = useCallback(() => {
    getData();
  }, [searchParams.get("text"), searchParams.get("categoryId")]);

  useEffect(() => {
    getData();
  }, []);

  const onDelete = useCallback((id) => {
    deleteNotification(id)
      .then(() => {
        getData();
        NotificationManager.success(t("Уведомления успешно удалены"));
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error(t("Ошибка при запросе")));
  }, []);

  const onDeleteSelected = useCallback(() => {
    deleteNotification(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        getData();
        NotificationManager.success(t("Выбранные уведомления успешно удалены"));
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error(t("Ошибка при запросе")));
  }, [selected]);

  const header = useMemo(() => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h5 className="fw-7">
              {selected.length > 0
                ? `${t("Выбрано")} ${selected.length}`
                : t("Рассылки")}
            </h5>
          </div>
          <div className="d-flex align-items-center">
            <Link
              to="/notifications/create"
              className="btn-primary-outline me-3"
            >
              <IoAdd size={18} />
            </Link>
            <Button
              className="btn-light"
              onClick={() =>
                selected.length > 0
                  ? setModalDelete({ show: true, id: false })
                  : setModalDeleteAll(true)
              }
            >
              <IoTrashOutline size={18} />
            </Button>
          </div>
        </div>
        <div>
          <Row>
            <Col md={4}>
              <Select
                classNameContainer="w-100"
                label="Статус"
                data={[
                  { title: "Все", value: "" },
                  { title: "Отправленные", value: 1 },
                  { title: "С ошибкой", value: -1 },
                ]}
                value={searchParams.get("status") ?? ""}
                onClick={(e) => {
                  searchParams.set("status", e.value);
                  setSearchParams(searchParams);
                  onSearch();
                }}
              />
            </Col>
            <Col md={4}>
              <Select
                classNameContainer="w-100"
                label="Сортировка"
                data={[
                  { title: "По дате: новые", value: "new" },
                  { title: "По дате: старые", value: "old" },
                ]}
                value={searchParams.get("sort") ?? "new"}
                onClick={(e) => {
                  searchParams.set("sort", e.value);
                  setSearchParams(searchParams);
                  onSearch();
                }}
              />
            </Col>

            <Col md={4}>
              <Select
                classNameContainer="w-100"
                label="Показать"
                data={[
                  { title: "25", value: "" },
                  { title: "50", value: 50 },
                  { title: "Все", value: 1000 },
                ]}
                value={
                  searchParams.get("size")
                    ? Number(searchParams.get("size"))
                    : ""
                }
                onClick={(e) => {
                  searchParams.set("size", e.value);
                  setSearchParams(searchParams);
                  onSearch();
                }}
              />
            </Col>
            <Col md={12}>
              <Input
                ref={inputRef}
                placeholder={t("Найти")}
                className="w-100"
                onChange={(e) => {
                  searchParams.set("text", e);
                  setSearchParams(searchParams);
                }}
                rightIcon={() => <IoSearchOutline size={22} />}
                value={searchParams.get("text") ?? ""}
                rightIconClick={() => onSearch()}
                onKeyDown={(e) => e === "Enter" && onSearch()}
              />
              {searchParams.get("text")?.length > 0 && (
                <Button
                  className="btn-light ms-3"
                  onClick={() => {
                    searchParams.delete("text");
                    setSearchParams(searchParams);
                    onSearch();
                    if (inputRef.current) {
                      inputRef.current.value = "";
                    }
                  }}
                >
                  <IoCloseOutline size={22} />
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  }, [selected, searchParams, modalDelete, t]);

  if (notifications.loading) {
    return <Loader full />;
  }

  return (
    <>
      <Meta title={t("Рассылки")} />
      <section className="notifications">
        <DataTable
          columns={notificationColumns}
          onChange={(items) => setSelected(items)}
          data={notifications.items}
          header={header}
          selectable
          pagination={notifications.pagination}
        />
        <CustomModal
          title={`${t("Удаление")} ${
            modalDelete.id ? "#" + modalDelete.id : ""
          }`}
          show={modalDelete.show}
          setShow={(e) => setModalDelete({ show: e, id: false })}
          footer={
            <>
              <Button
                className="me-3"
                onClick={(e) =>
                  setModalDelete({ show: !modalDelete.show, id: false })
                }
              >
                {t("Отмена")}
              </Button>
              <Button
                className="btn-primary"
                onClick={() =>
                  selected.length > 0
                    ? onDeleteSelected()
                    : modalDelete.id && onDelete(modalDelete.id)
                }
              >
                {t("Удалить")}
              </Button>
            </>
          }
        >
          {t("Вы точно хотите удалить уведомление?")}
        </CustomModal>
      </section>
    </>
  );
};

export default Notifications;
