import React, { useCallback, useLayoutEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate } from "react-router-dom";
import DragDropFile from "../../components/DragDropFile";
import Meta from "../../components/Meta";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import { createCategory, getCategories } from "../../services/category";
import { useSelector } from "react-redux";
import { localeData, work } from "../../helpers/all";

const CreateCategory = () => {
  const brand = useSelector((state) => state.brand.active);
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {
      type: null,
      status: 1,
      options: { work: work },
    },
  });

  const data = useWatch({ control });

  const onSubmit = useCallback((data) => {
    var formData = new FormData();

    formData.append("dataParse", JSON.stringify(data));

    if (data?.file) {
      for (let file of data?.file) {
        formData.append("file", file);
      }
    }

    createCategory(formData)
      .then(() => {
        NotificationManager.success("Каталог успешно создан");
        navigate(-1);
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      });
  }, []);

  useLayoutEffect(() => {
    getCategories({ size: 200 })
      .then((res) =>
        setCategories((prev) => ({
          ...prev,
          loading: false,
          ...res,
        }))
      )
      .finally(() => setCategories((prev) => ({ ...prev, loading: false })));
  }, []);

  if (categories.loading) {
    return <Loader full />;
  }

  return (
    <>
      <Meta title="Создать категорию" />
      <div>
        <Link
          to="/catalog/categories"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h3>Создать категорию</h3>
        <Button disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить
        </Button>
      </div>
      <Card>
        <Card.Body>
          <Tabs defaultActiveKey={0} className="mb-3" fill>
            <Tab eventKey={0} title="Основное">
              <Row>
                <Col>
                  <DragDropFile
                    col={12}
                    maxAspect={1}
                    file={data.file}
                    onChange={(e) => setValue("file", e)}
                    onDelete={(e) => setValue("file", e.file)}
                  />
                </Col>
                <Col md={8}>
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <Input
                          defaultValue={data.title}
                          label="Название"
                          name="title"
                          errors={errors}
                          register={register}
                          validation={{
                            required: "Обязательное поле",
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Select
                          label="Тип"
                          value={
                            data.type
                              ? data.type
                              : brand.type == "food"
                              ? "dish"
                              : "product"
                          }
                          data={
                            brand.type == "food"
                              ? [
                                  { title: "Блюдо", value: "dish" },
                                  { title: "Добавка", value: "addition" },
                                  { title: "Модификатор", value: "modifier" },
                                  { title: "Дополнительно", value: "extra" },
                                  { title: "Подарок", value: "gift" },
                                  { title: "Акции", value: "sale" },
                                ]
                              : [
                                  { title: "Товар", value: "product" },
                                  { title: "Проект", value: "project" },
                                  { title: "Услуга", value: "service" },
                                  { title: "Подарок", value: "gift" },
                                  { title: "Акции", value: "sale" },
                                ]
                          }
                          onClick={(e) => setValue("type", e.value)}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Input
                          defaultValue={data.priority ?? 0}
                          label="Порядок"
                          name="priority"
                          errors={errors}
                          register={register}
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Select
                          label="Категория"
                          search
                          value={data.parent ?? ""}
                          data={[
                            { title: "Нет", value: "" },
                            ...(categories.items?.length > 0
                              ? categories.items.map((e) => ({
                                  title: e.title,
                                  value: e.id,
                                }))
                              : []),
                          ]}
                          onClick={(e) => setValue("parent", e.value)}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <Form.Check className="my-2">
                        <Form.Check.Input
                          type="checkbox"
                          name="status"
                          id="status"
                          defaultChecked={data.status}
                          {...register("status")}
                        />
                        <Form.Check.Label htmlFor="status" className="ms-2">
                          Показать\Скрыть
                        </Form.Check.Label>
                      </Form.Check>
                    </Col>
                    {data?.type == "modifier" && (
                      <Col md={12}>
                        <Form.Check className="d-inline-block mb-4">
                          <Form.Check.Input
                            type="checkbox"
                            id="optional"
                            defaultChecked={!!data?.options?.optional}
                            {...register("options.optional")}
                          />
                          <Form.Check.Label htmlFor="optional" className="ms-2">
                            Необязательная категория модификаторов
                          </Form.Check.Label>
                        </Form.Check>
                      </Col>
                    )}
                    <Col md={12}>
                      <h5 className="mb-2 mt-1">Время работы</h5>
                      <p className="text-muted fs-09 mb-4">
                        Если категория работает в какой либо из дней
                        круглосуточно, оставьте поле пустым
                      </p>
                      <div className="mb-3 d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <span className="me-2 work-label">Все</span>
                          <Input
                            className="me-2"
                            label="Начало"
                            type="time"
                            onChange={(event) =>
                              setValue(
                                "options.work",
                                data.options?.work?.length > 0
                                  ? data.options.work.map((e) => ({
                                      ...e,
                                      start: event,
                                    }))
                                  : work.map((e) => ({
                                      ...e,
                                      start: event,
                                    }))
                              )
                            }
                          />
                          <Input
                            label="Конец"
                            type="time"
                            onChange={(event) =>
                              setValue(
                                "options.work",
                                data.options?.work?.length > 0
                                  ? data.options.work.map((e) => ({
                                      ...e,
                                      end: event,
                                    }))
                                  : work.map((e) => ({
                                      ...e,
                                      end: event,
                                    }))
                              )
                            }
                          />
                        </div>
                        <div>
                          <Form.Check className="my-2 ms-2">
                            <Form.Check.Input
                              type="checkbox"
                              id="status-all"
                              checked={
                                data.options?.work &&
                                !!!data.options?.work.find((e) => !e.status)
                              }
                              onChange={(event) =>
                                setValue(
                                  "options.work",
                                  data.options?.work?.length > 0
                                    ? data.options.work.map((e) => ({
                                        ...e,
                                        status: event.target.checked,
                                      }))
                                    : work.map((e) => ({
                                        ...e,
                                        status: event.target.checked,
                                      }))
                                )
                              }
                            />
                            <Form.Check.Label
                              htmlFor="status-all"
                              className="ms-2"
                            >
                              Работает
                            </Form.Check.Label>
                          </Form.Check>
                        </div>
                      </div>
                      <hr />
                      <div className="mb-3 d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <span className="me-2 work-label">Пн -</span>
                          <Input
                            className="me-2"
                            label="Начало"
                            type="time"
                            name="options.work.0.start"
                            register={register}
                          />
                          <Input
                            label="Конец"
                            type="time"
                            name="options.work.0.end"
                            register={register}
                          />
                        </div>
                        <div>
                          <Form.Check className="my-2 ms-2">
                            <Form.Check.Input
                              type="checkbox"
                              id="status-0"
                              defaultChecked={true}
                              {...register("options.work.0.status")}
                            />
                            <Form.Check.Label
                              htmlFor="status-0"
                              className="ms-2"
                            >
                              Работает
                            </Form.Check.Label>
                          </Form.Check>
                        </div>
                      </div>
                      <div className="mb-3 d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <span className="me-2 work-label">Вт -</span>
                          <Input
                            className="me-2"
                            label="Начало"
                            type="time"
                            name="options.work.1.start"
                            register={register}
                          />
                          <Input
                            label="Конец"
                            type="time"
                            name="options.work.1.end"
                            register={register}
                          />
                        </div>
                        <div>
                          <Form.Check className="my-2 ms-2">
                            <Form.Check.Input
                              type="checkbox"
                              id="status-1"
                              defaultChecked={true}
                              {...register("options.work.1.status")}
                            />
                            <Form.Check.Label
                              htmlFor="status-1"
                              className="ms-2"
                            >
                              Работает
                            </Form.Check.Label>
                          </Form.Check>
                        </div>
                      </div>
                      <div className="mb-3 d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <span className="me-2 work-label">Ср -</span>
                          <Input
                            className="me-2"
                            label="Начало"
                            type="time"
                            name="options.work.2.start"
                            register={register}
                          />
                          <Input
                            label="Конец"
                            type="time"
                            name="options.work.2.end"
                            register={register}
                          />
                        </div>
                        <div>
                          <Form.Check className="my-2 ms-2">
                            <Form.Check.Input
                              type="checkbox"
                              id="status-2"
                              defaultChecked={true}
                              {...register("options.work.2.status")}
                            />
                            <Form.Check.Label
                              htmlFor="status-2"
                              className="ms-2"
                            >
                              Работает
                            </Form.Check.Label>
                          </Form.Check>
                        </div>
                      </div>
                      <div className="mb-3 d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <span className="me-2 work-label">Чт -</span>
                          <Input
                            className="me-2"
                            label="Начало"
                            type="time"
                            name="options.work.3.start"
                            register={register}
                          />
                          <Input
                            label="Конец"
                            type="time"
                            name="options.work.3.end"
                            register={register}
                          />
                        </div>
                        <div>
                          <Form.Check className="my-2 ms-2">
                            <Form.Check.Input
                              type="checkbox"
                              id="status-3"
                              defaultChecked={true}
                              {...register("options.work.3.status")}
                            />
                            <Form.Check.Label
                              htmlFor="status-3"
                              className="ms-2"
                            >
                              Работает
                            </Form.Check.Label>
                          </Form.Check>
                        </div>
                      </div>
                      <div className="mb-3 d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <span className="me-2 work-label">Пт -</span>
                          <Input
                            className="me-2"
                            label="Начало"
                            type="time"
                            name="options.work.4.start"
                            register={register}
                          />
                          <Input
                            label="Конец"
                            type="time"
                            name="options.work.4.end"
                            register={register}
                          />
                        </div>
                        <div>
                          <Form.Check className="my-2 ms-2">
                            <Form.Check.Input
                              type="checkbox"
                              id="status-4"
                              defaultChecked={true}
                              {...register("options.work.4.status")}
                            />
                            <Form.Check.Label
                              htmlFor="status-4"
                              className="ms-2"
                            >
                              Работает
                            </Form.Check.Label>
                          </Form.Check>
                        </div>
                      </div>
                      <div className="mb-3 d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <span className="me-2 work-label">Сб -</span>
                          <Input
                            className="me-2"
                            label="Начало"
                            type="time"
                            name="options.work.5.start"
                            register={register}
                          />
                          <Input
                            label="Конец"
                            type="time"
                            name="options.work.5.end"
                            register={register}
                          />
                        </div>
                        <div>
                          <Form.Check className="my-2 ms-2">
                            <Form.Check.Input
                              type="checkbox"
                              id="status-5"
                              defaultChecked={true}
                              {...register("options.work.5.status")}
                            />
                            <Form.Check.Label
                              htmlFor="status-5"
                              className="ms-2"
                            >
                              Работает
                            </Form.Check.Label>
                          </Form.Check>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <span className="me-2 work-label">Вс -</span>
                          <Input
                            className="me-2"
                            label="Начало"
                            type="time"
                            name="options.work.6.start"
                            register={register}
                          />
                          <Input
                            label="Конец"
                            type="time"
                            name="options.work.6.end"
                            register={register}
                          />
                        </div>
                        <div>
                          <Form.Check className="my-2 ms-2">
                            <Form.Check.Input
                              type="checkbox"
                              id="status-6"
                              defaultChecked={true}
                              {...register("options.work.6.status")}
                            />
                            <Form.Check.Label
                              htmlFor="status-6"
                              className="ms-2"
                            >
                              Работает
                            </Form.Check.Label>
                          </Form.Check>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Tab>
            {brand.options?.lang?.length > 1 && (
              <Tab eventKey={1} title="Перевод">
                <Row>
                  {brand.options?.lang
                    .filter((e) => e != "ru_RU")
                    .map((lang) => (
                      <Col md={12}>
                        <div className="mb-3">
                          <Input
                            label={
                              localeData.find((e) => e.lang === lang)?.title
                            }
                            name={`options.${lang}.title`}
                            errors={errors}
                            register={register}
                          />
                        </div>
                      </Col>
                    ))}
                </Row>
              </Tab>
            )}
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
};

export default CreateCategory;
