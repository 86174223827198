const roles = [
  {
    value: 1,
    title: "Кухня",
    desc: "",
    color: "#00a76f",
    data: [
      {
        group: 1,
        value: 1,
        title: "Шеф-повар",
        desc: "Основа его деятельности — это создание и поддержание общей концепции заведения, разработка меню и технологических карт, контроль готовых блюд и запасов на складе",
      },
      {
        group: 1,
        value: 2,
        title: "Су-шеф",
        desc: "Правая рука и заместитель шефа",
      },
      {
        value: 3,
        group: 1,
        title: "Повар",
        desc: "Занимается приготовлением блюд в соответствии с технологическими картами",
      },
      {
        value: 4,
        group: 1,
        title: "Помощник повара",
        desc: "Работает на заготовках",
      },
      {
        value: 5,
        group: 1,
        title: "Посудомойщики",
        desc: "Не только моют посуду, но и выполняют множество других обязанностей по кухне",
      },
    ],
  },
  {
    value: 2,
    title: "Обслуживание",
    desc: "",
    color: "#3366ff",
    data: [
      {
        value: 6,
        group: 2,
        title: "Администратор зала",
        desc: "",
      },
      {
        value: 7,
        group: 2,
        title: "Официант",
        desc: "",
      },
      {
        value: 8,
        group: 2,
        title: "Бариста",
        desc: "",
      },
      {
        value: 9,
        group: 2,
        title: "Кассир",
        desc: "",
      },
      {
        value: 10,
        group: 2,
        title: "Уборщик",
        desc: "",
      },
    ],
  },
  {
    value: 3,
    title: "Администрация",
    desc: "",
    color: "#ff5630",
    data: [
      {
        value: 11,
        group: 3,
        title: "Управляющий",
        desc: "Отвечает за работу ресторана в целом, контролирует все подразделения и финансовые показатели",
      },
      {
        value: 12,
        group: 3,
        title: "Менеджер смены",
        desc: "Заместитель управляющего",
      },
      {
        value: 13,
        group: 3,
        title: "Бухгалтер",
        desc: "Ведет учет доходов/расходов, работает с налоговой",
      },
      {
        value: 14,
        group: 3,
        title: "Бухгалтер-калькулятор",
        desc: "Определяет необходимое количество продуктов, рассчитывает стоимость позиций меню",
      },
      {
        value: 15,
        group: 3,
        title: "Менеджер по снабжению, закупщик",
        desc: "Приобретает продукты, полуфабрикаты и сырье",
      },
    ],
  },
  {
    value: 4,
    title: "Менеджмент",
    desc: "",
    color: "#ffb230",
    data: [
      {
        value: 16,
        group: 4,
        title: "Руководитель Отдела Продаж B2C",
        desc: null,
      },
      {
        value: 17,
        group: 4,
        title: "Офис менеджер",
        desc: null,
      },
      {
        value: 18,
        group: 4,
        title: "Директор",
        desc: null,
      },
    ],
  },
];

const isRole = ({ groupRole, role }) => {
  let groupInfo = roles.find((e) => e.value === groupRole);
  if (!groupInfo) {
    return "Группа не найдена";
  }
  let roleInfo = groupInfo.data.find((e) => e.value === role);
  if (!roleInfo) {
    return "Роль не найдена";
  }
  return { group: groupInfo, role: roleInfo };
};
const getRoleData = (groupRole) => {
  let groupInfo = roles.find((e) => e.value === groupRole);
  if (!groupInfo) {
    return "Группа не найдена";
  }

  return groupInfo.data;
};

export { roles, isRole, getRoleData };
