import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getStatuses = async (data) => {
  const response = await $authApi.get(apiRoutes.STATUSES, {
    params: data,
  });
  return response?.data;
};
const getStatus = async (id) => {
  const response = await $authApi.get(apiRoutes.STATUS, {
    params: {
      id,
    },
  });
  return response?.data;
};
const editStatus = async (data) => {
  const response = await $authApi.put(apiRoutes.STATUSES, data);
  return response?.data;
};
const deleteStatus = async (id) => {
  const response = await $authApi.delete(apiRoutes.STATUSES, {
    data: { id },
  });
  return response?.data;
};
const createStatus = async (data) => {
  const response = await $authApi.post(apiRoutes.STATUSES, data);
  return response?.data;
};
export { getStatuses, getStatus, editStatus, deleteStatus, createStatus };
