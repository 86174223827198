import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import AppLayout from "../layouts/AppLayout";
import { AuthRoute } from "../layouts/AuthRoute";
import AdminRouter from "./AdminRouter";
import Login from "../pages/auth/Login";
import DocsView from "../pages/DocsView";
import RedirectPay from "../pages/RedirectPay";
import Reg from "../pages/auth/Reg";
import Recovery from "../pages/auth/Recovery";
import NewPassword from "../pages/auth/NewPassword";
import Orders from "../pages/order";
import { useSelector } from "react-redux";
import NotFound from "../pages/NotFound";

const auth = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route index element={<Login />} />
      <Route path="reg" element={<Reg />} />
      <Route path="recovery" element={<Recovery />} />
      <Route path="new-password" element={<NewPassword />} />
      <Route path="docs/:brandId/:action" element={<DocsView />} />
      <Route path="redirect/:brandId/:action" element={<RedirectPay />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

const admin = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<AppLayout />}>
      <Route index element={<Orders />} />
      <Route
        path="*"
        element={
          <AuthRoute>
            <AdminRouter />
          </AuthRoute>
        }
      />
    </Route>
  )
);

const AppRouter = () => {
  const isAuth = useSelector((state) => state.auth?.user?.id);

  return isAuth ? (
    <RouterProvider router={admin} />
  ) : (
    <RouterProvider router={auth} />
  );
};

export default AppRouter;
