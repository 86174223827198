import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getStories = async (data) => {
  const response = await $authApi.get(apiRoutes.STORIES, {
    params: data,
  });

  return response?.data;
};

const getStory = async (id) => {
  const response = await $authApi.get(apiRoutes.STORY, {
    params: {
      id,
    },
  });

  return response?.data;
};

const createStory = async (data) => {
  const response = await $authApi.post(apiRoutes.STORY_CREATE, data);
  return response?.data;
};

const editStory = async (data) => {
  const response = await $authApi.post(apiRoutes.STORY_EDIT, data);
  return response?.data;
};

const deleteStory = async (ids) => {
  const response = await $authApi.delete(apiRoutes.STORIES, {
    data: { ids },
  });
  return response?.data;
};

export { getStories, getStory, createStory, editStory, deleteStory };
