import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getLanguages = async (data) => {
  const response = await $authApi.get(apiRoutes.LANGUAGES, {
    params: data,
  });
  return response?.data;
};

const getLanguage = async (id) => {
  const response = await $authApi.get(apiRoutes.LANGUAGE, {
    params: {
      id,
    },
  });

  return response?.data;
};

const updateLanguage = async (data) => {
  const response = await $authApi.post(apiRoutes.LANGUAGES, data);
  return response?.data;
};

const deleteLanguage = async (ids) => {
  const response = await $authApi.delete(apiRoutes.LANGUAGES, {
    data: { ids },
  });
  return response?.data;
};

export {
  getLanguages,
  getLanguage,
  updateLanguage,
  deleteLanguage,
};
