import React, { createRef, useEffect, useState } from "react";
// import Cropper from "react-easy-crop";
import "cropperjs/dist/cropper.css";
import CustomModal from "./utils/CustomModal";
// import getCroppedImg from "./CropperCreateImage";
import Cropper from "react-cropper";
import Button from "./UI/Button";
const ImageCropper = ({
  file,
  onComplete,
  maxAspect = null,
  aspect = 1,
  setShow,
  show,
}) => {
  const [image, setImage] = useState();
  const cropperRef = createRef();

  useEffect(() => {
    if (file?.file) {
      // let files;
      // if (file?.dataTransfer) {
      //   files = file.dataTransfer.files;
      // } else if (file?.target) {
      //   files = file.target.files;
      // }
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file.file);
    }
  }, [file]);

  const convertDataURLToFile = (dataURL, fileName) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
  };
  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      onComplete({
        index: file.index,
        file: convertDataURLToFile(
          cropperRef.current?.cropper.getCroppedCanvas().toDataURL(),
          "name.png"
        ),
        blob: cropperRef.current?.cropper.getCroppedCanvas().toDataURL(),
      });
      setShow(false);
    }
  };
  return (
    <CustomModal
      size="xl"
      full
      title="Редактор изображения"
      show={show}
      setShow={(e) => setShow && setShow(e)}
      classNameBody="p-0"
      footer={
        <>
          <Button className="me-3" onClick={() => setShow && setShow(false)}>
            Отмена
          </Button>
          <Button
            className="btn-primary"
            onClick={() => onComplete && getCropData()}
          >
            Сохранить изображение
          </Button>
        </>
      }
    >
      <div className="editor-image">
        <Cropper
          ref={cropperRef}
          style={{ height: "100%", width: "100%" }}
          zoomTo={0.5}
          initialAspectRatio={aspect}
          aspectRatio={maxAspect}
          preview=".img-preview"
          src={image}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          guides={true}
        />
      </div>
    </CustomModal>
  );
};

export default ImageCropper;
