import React, { useCallback, useRef } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Textarea from "../../components/UI/Textarea";
import { createModifier } from "../../services/modifier";
import Select from "../../components/UI/Select";

const ModifierCreate = () => {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {
      type: "modifier",
    },
  });

  const data = useWatch({ control });

  const kkalData = useRef();

  const onSubmit = useCallback((data) => {
    createModifier(data)
      .then(() => NotificationManager.success("Модификатор успешно создан"))
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      });
  }, []);

  const FormIngredient = ({ data, onChange }) => {
    return (
      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Input
              defaultValue={data.weight ?? 0}
              label="Вес, г"
              name="weight"
              type="number"
              onChange={(e) => onChange && onChange("weight", e)}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Input
              defaultValue={data.price ?? 0}
              label="Цена"
              type="number"
              name="price"
              onChange={(e) => onChange && onChange("price", e)}
            />
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Meta title="Создать модификатор" />
      <div>
        <Link
          to="/catalog/modifiers"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
      </div>
      <h3 className="mb-4">Создать модификатор</h3>
      <Row>
        <Col md={8}>
          <Card>
            <Card.Body>
              <Row>
                <Col md={8}>
                  <div className="mb-3">
                    <Input
                      label="Название"
                      name="title"
                      errors={errors}
                      register={register}
                      validation={{
                        required: "Обязательное поле",
                      }}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Input
                      label="Артикул"
                      name="code"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Input
                      defaultValue={0}
                      label="Порядок"
                      name="priority"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Select
                      classNameContainer="w-100"
                      label="Тип"
                      data={[
                        { title: "Модификатор", value: "modifier" },
                        { title: "Добавка", value: "addition" },
                        { title: "Пожелание", value: "wish" },
                      ]}
                      value={data.type ?? null}
                      onClick={(e) => {
                        setValue("type", e.value);
                      }}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Select
                      classNameContainer="w-100"
                      label="Тип отправки"
                      data={[
                        { title: "Модификатор", value: "modifier" },
                        { title: "Добавка", value: "addition" },
                      ]}
                      value={data.options?.typeSend ?? "modifier"}
                      onClick={(e) => {
                        setValue("options.typeSend", e.value);
                      }}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <Textarea
                      defaultValue={data.description}
                      label="Описание"
                      name="description"
                      errors={errors}
                      rows={6}
                      register={register}
                    />
                  </div>
                </Col>
                {data.apiId && (
                  <Col md={12}>
                    <Input
                      defaultValue={data.apiId}
                      label="API ID"
                      name="apiId"
                      readOnly={false}
                    />
                  </Col>
                )}
                <Col md={12}>
                  <Form.Check className="d-inline-block">
                    <Form.Check.Input
                      type="checkbox"
                      id="optional"
                      defaultChecked={!!data?.options?.optional}
                      {...register("options.optional")}
                    />
                    <Form.Check.Label htmlFor="optional" className="ms-2">
                      Необязательный модификатор
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="position-relative">
          <div className="position-sticky top-1">
            {/* <Card className="mb-3" body>
              <DragDropFile
                file={data.file}
                media={data.medias}
                onChange={(e) =>
                  setEditImageProduct((info) => ({
                    show: !info.show,
                    data: e,
                  }))
                }
              />
              <DragDropFile
                col={12}
                maxAspect={1}
                main
                multiple
                file={data.file}
                onMain={(e) => setValue("main", e)}
                onChange={(e) => setValue("file", e)}
                onDelete={(e) => setValue("file", e.file)}
              />
            </Card> */}
            <Card body className="mb-3">
              <p className="fs-08">
                Энергетическая ценность в 100г -{" "}
                <span className="text-success">
                  {kkalData?.current?.kkal ?? 0}
                </span>{" "}
                ккал
              </p>
              <Row className="gx-2">
                <Col lg={4}>
                  <div className="mt-4">
                    <Input
                      value={kkalData?.current?.protein ?? 0}
                      label="Белки, г"
                      readOnly={false}
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mt-4">
                    <Input
                      value={kkalData?.current?.fat ?? 0}
                      label="Жиры, г"
                      readOnly={false}
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mt-4">
                    <Input
                      value={kkalData?.current?.carbohydrate ?? 0}
                      label="Углеводы, г"
                      readOnly={false}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="gx-2">
                <Col lg={6}>
                  <div className="mt-4">
                    <Input
                      value={kkalData?.current?.kkalAll ?? 0}
                      label="Всего ккал, г"
                      readOnly={false}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mt-4">
                    <Input
                      defaultValue={kkalData?.current?.weight ?? 0}
                      label="Вес, г"
                      name="energy.weight"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
            <Card body className="mb-3">
              <p className="fs-08">Цена</p>
              <Row className="gx-2">
                <Col lg={6}>
                  <div className="mt-4">
                    <Input
                      defaultValue={0}
                      label="Цена"
                      name="price"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mt-4">
                    <Input
                      defaultValue={0}
                      label="Скидка"
                      name="discount"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
            <Button
              className="btn-primary align-self-end w-100 mt-3"
              onClick={handleSubmit(onSubmit)}
            >
              Сохранить
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ModifierCreate;
