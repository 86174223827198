import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getWishes = async (data) => {
  const response = await $authApi.get(apiRoutes.WISHES, {
    params: data,
  });

  return response?.data;
};

const getWish = async (id) => {
  const response = await $authApi.get(apiRoutes.WISH, {
    params: {
      id,
    },
  });

  return response?.data;
};

const createWish = async (data) => {
  const response = await $authApi.post(apiRoutes.WISHES, data);
  return response?.data;
};

const editWish = async (data) => {
  const response = await $authApi.put(apiRoutes.WISHES, data);
  return response?.data;
};

const deleteWish = async (ids) => {
  const response = await $authApi.delete(apiRoutes.WISHES, {
    data: { ids },
  });
  return response?.data;
};

export {
  getWishes,
  getWish,
  createWish,
  editWish,
  deleteWish,
};
