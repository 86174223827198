import React, { useCallback, useEffect, useState } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";

const Reg = () => {
  const auth = useSelector((state) => state?.auth);

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (auth.isAuth) {
      return navigate("/");
    }
  }, [auth.isAuth]);

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({ mode: "all", reValidateMode: "onSubmit" });

  const onSubmit = useCallback(
    (data) => {
      setShow(!show);
    },
    [show]
  );

  return (
    <>
      <Meta title="Регистрация" />
      <Row className="gx-0 hv-100-important">
        <Col lg={8} md={7} className="login-info d-none d-md-flex">
          <div className="flex-column d-flex align-self-center justify-content-center align-items-center">
            <img src="/logo.png" height={80} />
            <h2 className="my-4 text-center">
              Управляйте бизнесом ээфективнее <br />
              вместе с Yoo App
            </h2>
            <img src="./images/auth/login.svg" width="80%" />
          </div>
        </Col>
        <Col lg={4} md={5}>
          <div className="login">
            <Form className="login-form" onSubmit={handleSubmit(onSubmit)}>
              <Alert variant="success" show={show}>
                Регистрация прошла успешно, мы свяжемся с вами по данному номеру
                для предоставления закрытого доступа.
              </Alert>
              <h3 className="mb-3 h5 fw-7 w-100">Создайте свой профиль</h3>
              <p className="mb-4 text-muted fs-08">
                Уже есть профиль?{" "}
                <Link className="text-success" to="/login">
                  Войти
                </Link>
              </p>
              <Input
                autoFocus
                label="Имя"
                name="firstName"
                errors={errors}
                register={register}
                validation={{
                  required: "Введите имя",
                  maxLength: {
                    value: 30,
                    message: "Максимально 30 символов",
                  },
                }}
              />
              <div className="mt-4">
                <Input
                  mask="+7(999)999-99-99"
                  label="Номер телефона"
                  name="phone"
                  errors={errors}
                  register={register}
                  validation={{
                    required: "Введите номер телефона",
                    maxLength: {
                      value: 18,
                      message: "Максимально 18 символов",
                    },
                  }}
                />
              </div>
              <div className="mt-4">
                <Input
                  label="Пароль"
                  type="password"
                  name="password"
                  errors={errors}
                  register={register}
                  validation={{
                    required: "Введите пароль",
                    minLength: {
                      value: 4,
                      message:
                        "Минимальный пароль должен состоять из 4-х символов",
                    },
                  }}
                />
              </div>
              <Button
                type="submit"
                className="btn-primary mt-3 w-100"
                disabled={!isValid}
              >
                Создать профиль
              </Button>
              <p className="mt-3 text-muted fs-07">
                Регистрируясь, я соглашаюсь с{" "}
                <Link to="/terms" className="text-dark">
                  Условиями обслуживания
                </Link>{" "}
                и{" "}
                <Link to="/policy" className="text-dark">
                  Политикой конфиденциальности
                </Link>
                .
              </p>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Reg;
