import React from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";
import { Container } from "react-bootstrap";
import Header from "../components/Header";
import Menu from "../components/Menu";

const AppLayout = () => {
  return (
    <>
      <ScrollRestoration />
      <div className="d-flex">
        <div className="left-menu d-none d-lg-flex">
          <Menu />
        </div>
        <div className="right-content">
          <Header />
          <main className="admin">
            <Container fluid="xl">
              <Outlet />
            </Container>
          </main>
        </div>
      </div>
    </>
  );
};

export default AppLayout;
