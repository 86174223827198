import React, {
  createRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Badge, Col, Row } from "react-bootstrap";
import {
  IoAdd,
  IoCloseOutline,
  IoCreateOutline,
  IoSearchOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Select from "../../components/UI/Select";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import { getImageURL } from "../../helpers/image";
import {
  deleteCategory,
  deleteCategoryAll,
  getCategories,
} from "../../services/category";
import Input from "../../components/UI/Input";
import { useTranslation } from "react-i18next";

const Categories = () => {
  const { t } = useTranslation();
  const brand = useSelector((state) => state.brand.active);
  const inputRef = createRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [categories, setCategories] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });
  const [modalDeleteAll, setModalDeleteAll] = useState(false);

  const categoryColumns = [
    {
      name: "Фото",
      width: "60px",
      selector: "media",
      cell: (row) => (
        <img
          src={getImageURL({ path: row.media, type: "category" })}
          width={30}
          height={30}
          className="mini-img"
        />
      ),
    },
    {
      name: "Название",
      selector: "title",
      cell: (row) =>
        row?.id && row?.title ? (
          <Link to={`/catalog/category/${row.id}`}>{row.title}</Link>
        ) : (
          "-"
        ),
    },
    {
      name: "Категория",
      selector: "parentTitle",
      align: "center",
      cell: (row) =>
        row?.parent && row?.parentTitle ? (
          <Link to={`/catalog/category/${row.parent}`}>{row.parentTitle}</Link>
        ) : (
          "-"
        ),
    },
    {
      name: "Товары",
      selector: "productCount",
      align: "center",
    },
    {
      name: "Тип",
      align: "center",
      cell: (row) =>
        row?.type == "modifier"
          ? "Модификатор"
          : row?.type == "addition"
          ? "Добавка"
          : "Обычный",
      size: 2,
    },
    {
      width: "100px",
      name: "Статус",
      align: "center",
      selector: "status",
      cell: (row) =>
        row.status === 0 ? (
          <Badge bg="secondary">{t("Архив")}</Badge>
        ) : (
          <Badge bg="success">{t("Активно")}</Badge>
        ),
    },
    {
      width: "80px",
      selector: "action",
      align: "right",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <Link
            disabled={selected.length > 0}
            to={"/catalog/category/" + row.id}
            className="me-3"
          >
            <IoCreateOutline size={22} />
          </Link>
          <a
            disabled={selected.length > 0}
            onClick={() =>
              setModalDelete({ show: !modalDelete.show, id: row.id })
            }
            className="me-2"
          >
            <IoTrashOutline size={20} className="text-danger" />
          </a>
        </div>
      ),
    },
  ];

  const header = useMemo(() => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h5 className="fw-7">
              {selected.length > 0
                ? `${t("Выбрано")} ${selected.length}`
                : t("Категории")}
            </h5>
          </div>
          <div className="d-flex align-items-center">
            <Link
              to="/catalog/categories/create"
              className="btn-primary-outline me-3"
            >
              <IoAdd size={18} />
            </Link>
            <Button
              className="btn-light"
              onClick={() =>
                selected.length > 0
                  ? setModalDelete({ show: true, id: false })
                  : setModalDeleteAll(true)
              }
            >
              <IoTrashOutline size={18} />
            </Button>
          </div>
        </div>
        <div>
          <Row>
            <Col md={4}>
              <Select
                classNameContainer="w-100"
                label="Статус"
                data={[
                  { title: "Все", value: "" },
                  { title: "Скрытые", value: "noview" },
                  { title: "Без фото", value: "nomedia" },
                ]}
                value={searchParams.get("status") ?? ""}
                onClick={(e) => {
                  searchParams.set("status", e.value);
                  setSearchParams(searchParams);
                  onSearch();
                }}
              />
            </Col>
            <Col md={4}>
              <Select
                classNameContainer="w-100"
                label="Сортировка"
                data={[
                  { title: "По дате добавления: новые", value: "createnew" },
                  { title: "По дате добавления: старые", value: "createold" },
                  { title: "По дате обновления: новые", value: "updatenew" },
                  { title: "По дате обновления: старые", value: "updateold" },
                ]}
                value={searchParams.get("sort") ?? "createnew"}
                onClick={(e) => {
                  searchParams.set("sort", e.value);
                  setSearchParams(searchParams);
                  onSearch();
                }}
              />
            </Col>
            <Col md={4}>
              <Select
                classNameContainer="w-100"
                label="Показать"
                data={[
                  { title: "25", value: "" },
                  { title: "50", value: 50 },
                  { title: "Все", value: 1000 },
                ]}
                value={
                  searchParams.get("size")
                    ? Number(searchParams.get("size"))
                    : ""
                }
                onClick={(e) => {
                  searchParams.set("size", e.value);
                  setSearchParams(searchParams);
                  onSearch();
                }}
              />
            </Col>
            <Col md={12}>
              <Input
                ref={inputRef}
                placeholder={t("Найти")}
                className="w-100"
                onChange={(e) => {
                  searchParams.set("text", e);
                  setSearchParams(searchParams);
                }}
                rightIcon={() => <IoSearchOutline size={22} />}
                value={searchParams.get("text") ?? ""}
                rightIconClick={() => onSearch()}
                onKeyDown={(e) => e === "Enter" && onSearch()}
              />
              {searchParams.get("text")?.length > 0 && (
                <Button
                  className="btn-light ms-3"
                  onClick={() => {
                    searchParams.delete("text");
                    setSearchParams(searchParams);
                    onSearch();
                    if (inputRef.current) {
                      inputRef.current.value = "";
                    }
                  }}
                >
                  <IoCloseOutline size={22} />
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  }, [selected, searchParams, modalDelete, t]);

  const getData = useCallback(async () => {
    getCategories(searchParams)
      .then((res) =>
        setCategories((prev) => ({
          ...prev,
          loading: false,
          ...res,
        }))
      )
      .finally(() => setCategories((prev) => ({ ...prev, loading: false })));
  }, [searchParams, brand]);

  const onDelete = useCallback((id) => {
    deleteCategory(id)
      .then(() => {
        getData();
        NotificationManager.success(t("Категория успешно удалена"));
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error(t("Ошибка при запросе")));
  }, []);

  const onDeleteAll = useCallback(() => {
    deleteCategoryAll()
      .then(() => {
        getData();
        NotificationManager.success(t("Категории успешно удалены"));
        setModalDeleteAll(false);
      })
      .catch(() => NotificationManager.error(t("Ошибка при запросе")));
  }, []);

  const onDeleteSelected = useCallback(() => {
    deleteCategory(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        getData();
        NotificationManager.success(t("Выбранные категории успешно удалены"));
        setModalDelete({ show: false, id: false });
      })
      .catch((err) =>
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : t("Неизвестная ошибка")
        )
      );
  }, [selected]);

  const onSearch = useCallback(() => {
    getData();
  }, [searchParams.get("text")]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page"), brand]);

  if (categories.loading) {
    return <Loader full />;
  }

  return (
    <>
      <Meta title={t("Категории")} />
      <DataTable
        columns={categoryColumns}
        onChange={(items) => setSelected(items)}
        data={categories.items}
        header={header}
        selectable
        pagination={categories.pagination}
      />
      <CustomModal
        title={
          selected.length > 0
            ? `Удаление ${selected.length} элементов`
            : t("Удаление элемента")
        }
        show={modalDelete.show}
        setShow={(e) => setModalDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() =>
                setModalDelete({ show: !modalDelete.show, id: false })
              }
            >
              {t("Отмена")}
            </Button>
            <Button
              className="btn-primary"
              onClick={() =>
                selected.length > 0
                  ? onDeleteSelected()
                  : modalDelete.id && onDelete(modalDelete.id)
              }
            >
              {t("Удалить")}
            </Button>
          </>
        }
      >
        {t("Вы точно хотите удалить категорию(-ии)?")}
      </CustomModal>
      <CustomModal
        title={t("Удалить все категории")}
        show={modalDeleteAll}
        setShow={(e) => setModalDeleteAll(e)}
        footer={
          <>
            <Button className=" me-3" onClick={() => setModalDeleteAll(false)}>
              {t("Отмена")}
            </Button>
            <Button className="btn-primary" onClick={() => onDeleteAll()}>
              {t("Удалить все")}
            </Button>
          </>
        }
      >
        {t("Вы точно хотите удалить все категории?")}
      </CustomModal>
    </>
  );
};

export default Categories;
