import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getPromos = async (data) => {
    const response = await $authApi.get(apiRoutes.PROMOS, {
        params: data,
    });

    return response?.data;
};

const getPromo = async (id) => {
    const response = await $authApi.get(apiRoutes.PROMO, {
        params: {
            id,
        },
    });

    return response?.data;
};

const createPromo = async (data) => {
    const response = await $authApi.post(apiRoutes.PROMOS, data);
    return response?.data;
};

const editPromo = async (data) => {
    const response = await $authApi.put(apiRoutes.PROMOS, data);
    return response?.data;
};

const deletePromo = async (ids) => {
    const response = await $authApi.delete(apiRoutes.PROMOS, {
        data: { ids },
    });
    return response?.data;
};
const checkPromo = async (data) => {
    const response = await $authApi.post(apiRoutes.PROMO_CHECK, data);
    return response?.data;
};
export { getPromos, getPromo, createPromo, editPromo, deletePromo, checkPromo };
