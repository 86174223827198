import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IoAdd, IoCreateOutline, IoTrashOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import { getImageURL } from "../../helpers/image";
import { deleteParam, deleteParamAll, getParams } from "../../services/param";

const Params = () => {
  const { t } = useTranslation();
  const brand = useSelector((state) => state.brand.active);
  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });
  const [modalDeleteAll, setModalDeleteAll] = useState(false);

  const paramColumns = [
    {
      name: "Фото",
      width: "60px",
      selector: "media",
      cell: (row) => (
        <img
          src={getImageURL({ path: row.media, type: "param" })}
          width={30}
          height={30}
          className="mini-img"
        />
      ),
    },
    {
      name: "Название",
      selector: "title",
    },
    {
      name: "Описание",
      selector: "desc",
    },
    {
      name: "Тип",
      align: "center",
      cell: (row) =>
        !row.type || row.type === "checkbox"
          ? "Флажок"
          : row.type === "select"
          ? "Выпадающий список"
          : row.type === "select" && "Переключатель",
      size: 2,
    },
    {
      name: "Опции",
      align: "center",
      cell: (row) => row.childCount,
      size: 2,
    },
    {
      width: "100px",
      name: "Статус",
      align: "center",
      selector: "status",
      cell: (row) =>
        row.status === 0 ? (
          <Badge bg="secondary">Архив</Badge>
        ) : (
          <Badge bg="success">Активно</Badge>
        ),
    },
    {
      width: "80px",
      selector: "action",
      align: "right",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <Link
            disabled={selected.length > 0}
            to={"/catalog/param/" + row.id}
            className="me-3"
          >
            <IoCreateOutline size={22} />
          </Link>
          <a
            disabled={selected.length > 0}
            onClick={() =>
              setModalDelete({ show: !modalDelete.show, id: row.id })
            }
            className="me-2"
          >
            <IoTrashOutline size={20} className="text-danger" />
          </a>
        </div>
      ),
    },
  ];

  const header = useMemo(() => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <h5 className="fw-7">
            {selected.length > 0
              ? `${t("Выбрано")} ${selected.length}`
              : t("Параметры")}
          </h5>
        </div>
        <div className="d-flex align-items-center">
          <Link to="create" className="btn-primary-outline me-3">
            <IoAdd size={18} />
          </Link>
          <Button
            className="btn-light"
            onClick={() =>
              selected.length > 0
                ? setModalDelete({ show: true, id: false })
                : setModalDeleteAll(true)
            }
          >
            <IoTrashOutline size={18} />
          </Button>
        </div>
      </div>
    );
  }, [selected, searchParams, modalDelete, t]);

  const getData = useCallback(async () => {
    getParams(searchParams)
      .then((res) =>
        setParams((prev) => ({
          ...prev,
          loading: false,
          ...res,
        }))
      )
      .finally(() => setParams((prev) => ({ ...prev, loading: false })));
  }, [searchParams, brand]);

  const onDelete = useCallback((id) => {
    deleteParam(id)
      .then(() => {
        getData();
        NotificationManager.success(t("Параметр успешно удален"));
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, []);

  const onDeleteAll = useCallback(() => {
    deleteParamAll()
      .then(() => {
        getData();
        NotificationManager.success(t("Параметры успешно удалены"));
        setModalDeleteAll(false);
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, []);

  const onDeleteSelected = useCallback(() => {
    deleteParam(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        getData();
        NotificationManager.success(t("Выбранные параметры успешно удалены"));
        setModalDelete({ show: false, id: false });
      })
      .catch((err) =>
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        )
      );
  }, [selected]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page"), brand]);

  if (params.loading) {
    return <Loader full />;
  }

  return (
    <>
      <Meta title={t("Параметры")} />
      <DataTable
        columns={paramColumns}
        onChange={(items) => setSelected(items)}
        data={params.items}
        header={header}
        selectable
        pagination={params.pagination}
      />
      <CustomModal
        title={
          selected.length > 0
            ? `Удаление ${selected.length} элементов`
            : t("Удаление элемента")
        }
        show={modalDelete.show}
        setShow={(e) => setModalDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() =>
                setModalDelete({ show: !modalDelete.show, id: false })
              }
            >
              {t("Отмена")}
            </Button>
            <Button
              className="btn-primary"
              onClick={() =>
                selected.length > 0
                  ? onDeleteSelected()
                  : modalDelete.id && onDelete(modalDelete.id)
              }
            >
              {t("Удалить")}
            </Button>
          </>
        }
      >
        {t("Вы точно хотите удалить параметр(-ы)?")}
      </CustomModal>
      <CustomModal
        title={t("Удалить все параметры")}
        show={modalDeleteAll}
        setShow={(e) => setModalDeleteAll(e)}
        footer={
          <>
            <Button className=" me-3" onClick={() => setModalDeleteAll(false)}>
              {t("Отмена")}
            </Button>
            <Button className="btn-primary" onClick={() => onDeleteAll()}>
              {t("Удалить все")}
            </Button>
          </>
        }
      >
        {t("Вы точно хотите удалить все параметры?")}
      </CustomModal>
    </>
  );
};

export default Params;
