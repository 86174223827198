import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AuthRoute = ({ children }) => {
  const isAuth = useSelector((state) => state.auth?.user?.id);
  return isAuth ? children : <Navigate to="/" />;
};
const NoAuthRoute = ({ children }) => {
  const isAuth = useSelector((state) => state.auth?.user?.id);
  return !isAuth ? children : null;
};
export { AuthRoute, NoAuthRoute };
