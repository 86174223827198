import React from "react";
import { Route, Routes } from "react-router-dom";

import NotFound from "../pages/NotFound";

import Orders from "../pages/order";
import OrderEdit from "../pages/order/Edit";

import Notifications from "../pages/notification";
import NotificationCreate from "../pages/notification/Create";

import Affiliates from "../pages/affiliate";
import AffiliateCreate from "../pages/affiliate/Create";
import AffiliateEdit from "../pages/affiliate/Edit";

import Zones from "../pages/zone";
import ZoneCreate from "../pages/zone/Create";
import ZoneEdit from "../pages/zone/Edit";

import Categories from "../pages/category";
import CategoryCreate from "../pages/category/Create";
import CategoryEdit from "../pages/category/Edit";

import Params from "../pages/param";
import ParamCreate from "../pages/param/Create";
import ParamEdit from "../pages/param/Edit";

import Modifiers from "../pages/modifier";

import Products from "../pages/product";
import ProductCreate from "../pages/product/Create";
import ProductEdit from "../pages/product/Edit";

import Users from "../pages/user";
import UserEdit from "../pages/user/Edit";

import Sales from "../pages/sale";
import SaleCreate from "../pages/sale/Create";
import SaleEdit from "../pages/sale/Edit";

import Banners from "../pages/banner";
import BannerCreate from "../pages/banner/Create";
import BannerEdit from "../pages/banner/Edit";

import Statuses from "../pages/status";
import StatusEdit from "../pages/status/Edit";

import Documents from "../pages/document";
import DocumentCreate from "../pages/document/Create";
import DocumentEdit from "../pages/document/Edit";

import Brands from "../pages/brand";

import Additions from "../pages/addition";
import AdditionCreate from "../pages/addition/Create";
import AdditionEdit from "../pages/addition/Edit";

import Members from "../pages/member";
import MemberEdit from "../pages/member/Edit";
import MemberCreate from "../pages/member/Create";

import Storages from "../pages/storage";

import ContentRoute from "../layouts/ContentRoute";
import CatalogRoute from "../layouts/CatalogRoute";
import OptionRoute from "../layouts/OptionRoute";

import Account from "../pages/account";
import Modules from "../pages/module";
import ModuleEdit from "../pages/module/Edit";

import Payment from "../pages/invoice";
import Invoices from "../pages/invoice/Invoices";
import Invoice from "../pages/invoice/View";
import Dialogs from "../pages/dialog";
import SaleRoute from "../layouts/SaleRoute";
import Promos from "../pages/promo";
import PromoCreate from "../pages/promo/Create";
import PromoEdit from "../pages/promo/Edit";
import UserCreate from "../pages/user/Create";
import Stories from "../pages/story";
import StoryEdit from "../pages/story/Edit";
import StoryCreate from "../pages/story/Create";
import Blogs from "../pages/blog";
import Portfolio from "../pages/portfolio";
import BlogCreate from "../pages/blog/Create";
import BlogEdit from "../pages/blog/Edit";
import PortfolioEdit from "../pages/portfolio/Edit";
import PortfolioCreate from "../pages/portfolio/Create";
import ModifierEdit from "../pages/modifier/Edit";
import ModifierCreate from "../pages/modifier/Create";
import Wishes from "../pages/wish";
import WishEdit from "../pages/wish/Edit";
import WishCreate from "../pages/wish/Create";
import Cities from "../pages/city";
import CityCreate from "../pages/city/Create";
import CityEdit from "../pages/city/Edit";
import Tables from "../pages/table";
import TableEdit from "../pages/table/Edit";
import TableCreate from "../pages/table/Create";

const AdminRouter = () => {
  return (
    <Routes>
      <Route path="/">
        <Route path="account" element={<Account />} />

        <Route path="modules" element={<Modules />} />
        <Route path="modules/:moduleId" element={<ModuleEdit />} />

        <Route index path="orders" element={<Orders />} />
        <Route path="order/:orderId" element={<OrderEdit />} />

        <Route path="dialogs" element={<Dialogs />} />
        <Route path="dialogs/:adminId" element={<Dialogs />} />

        <Route path="payment" element={<Payment />} />
        <Route path="invoices" element={<Invoices />} />
        <Route path="invoice/:invoiceId" element={<Invoice />} />

        <Route path="notifications" element={<Notifications />} />
        <Route path="notifications/create" element={<NotificationCreate />} />

        <Route path="users" element={<Users />} />
        <Route path="users/create" element={<UserCreate />} />
        <Route path="user/:userId" element={<UserEdit />} />

        <Route path="/content" element={<ContentRoute />}>
          <Route path="slides" element={<Banners />} />
          <Route path="slide/:bannerId" element={<BannerEdit />} />
          <Route path="slides/create" element={<BannerCreate />} />

          <Route path="stories" element={<Stories />} />
          <Route path="story/:storyId" element={<StoryEdit />} />
          <Route path="stories/create" element={<StoryCreate />} />

          <Route path="blogs" element={<Blogs />} />
          <Route path="blog/:blogId" element={<BlogEdit />} />
          <Route path="blogs/create" element={<BlogCreate />} />

          <Route path="portfolio" element={<Portfolio />} />
          <Route path="portfolio/:portfolioId" element={<PortfolioEdit />} />
          <Route path="portfolio/create" element={<PortfolioCreate />} />
        </Route>
        <Route path="/sales" element={<SaleRoute />}>
          <Route path="all" element={<Sales />} />
          <Route path="sale/:saleId" element={<SaleEdit />} />
          <Route path="all/create" element={<SaleCreate />} />

          <Route path="promos" element={<Promos />} />
          <Route path="promo/:promoId" element={<PromoEdit />} />
          <Route path="promos/create" element={<PromoCreate />} />
        </Route>
        <Route path="/catalog" element={<CatalogRoute />}>
          <Route path="products" element={<Products />} />
          <Route path="product/:productId" element={<ProductEdit />} />
          <Route path="products/create" element={<ProductCreate />} />

          <Route path="modifiers" element={<Modifiers />} />
          <Route path="modifier/:modifierId" element={<ModifierEdit />} />
          <Route path="modifiers/create" element={<ModifierCreate />} />

          <Route path="categories" element={<Categories />} />
          <Route path="category/:categoryId" element={<CategoryEdit />} />
          <Route path="categories/create" element={<CategoryCreate />} />

          <Route path="params" element={<Params />} />
          <Route path="param/:paramId" element={<ParamEdit />} />
          <Route path="params/create" element={<ParamCreate />} />

          <Route path="additions" element={<Additions />} />
          <Route path="addition/:additionId" element={<AdditionEdit />} />
          <Route path="additions/create" element={<AdditionCreate />} />

          <Route path="wishes" element={<Wishes />} />
          <Route path="wish/:wishId" element={<WishEdit />} />
          <Route path="wishes/create" element={<WishCreate />} />

          <Route path="storages" element={<Storages />} />
          {/* <Route path="ingredient/:saleId" element={<IngredientEdit />} />
        <Route path="ingredient/create" element={<IngredientCreate />} /> */}
        </Route>
        <Route path="/options" element={<OptionRoute />}>
          <Route path="documents" element={<Documents />} />
          <Route path="document/:documentId" element={<DocumentEdit />} />
          <Route path="documents/create" element={<DocumentCreate />} />

          <Route path="brands" element={<Brands />} />

          <Route path="members" element={<Members />} />
          <Route path="member/:memberId" element={<MemberEdit />} />
          <Route path="members/create" element={<MemberCreate />} />

          <Route path="affiliates" element={<Affiliates />} />
          <Route
            path="affiliates/affiliate/:affiliateId"
            element={<AffiliateEdit />}
          />
          <Route path="affiliates/create" element={<AffiliateCreate />} />

          <Route path="affiliates/zones" element={<Zones />} />
          <Route path="affiliates/zone/:zoneId" element={<ZoneEdit />} />
          <Route path="affiliates/zones/create" element={<ZoneCreate />} />

          <Route path="affiliates/tables" element={<Tables />} />
          <Route path="affiliates/table/:tableId" element={<TableEdit />} />
          <Route path="affiliates/tables/create" element={<TableCreate />} />

          <Route path="affiliates/cities" element={<Cities />} />
          <Route path="affiliates/city/:cityId" element={<CityEdit />} />
          <Route path="affiliates/cities/create" element={<CityCreate />} />

          <Route path="statuses" element={<Statuses />} />
          <Route path="status/:statusId" element={<StatusEdit />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AdminRouter;
