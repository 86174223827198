import moment from "moment";
import React, { useLayoutEffect, useState } from "react";
import { Badge, Card, Col, Row } from "react-bootstrap";
import { IoAlert, IoCheckmark } from "react-icons/io5";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Meta from "../../components/Meta";
import Info from "../../components/UI/Info";
import Loader from "../../components/UI/Loader";
import { moduleBgColor, moduleRatePrice } from "../../helpers/module";
import { getModules } from "../../services/module";
import { useTranslation } from "react-i18next";
import { customPrice } from "../../helpers/product";

const Modules = () => {
  const { t } = useTranslation();
  const brand = useSelector((state) => state.brand.active);
  const affiliate = useSelector((state) => state.affiliate.active);

  const [modules, setModules] = useState({
    loading: true,
    items: [],
  });

  useLayoutEffect(() => {
    getModules({ affiliateId: affiliate?.id ?? null })
      .then(
        (res) =>
          res &&
          setModules((prev) => ({
            ...prev,
            loading: false,
            items: res,
          }))
      )
      .finally(() => setModules((prev) => ({ ...prev, loading: false })));
  }, [brand, affiliate]);

  if (modules.loading) {
    return <Loader full />;
  }

  if (!modules.items || modules.items.length === 0) {
    return (
      <>
        <Meta title="Маркет" />
        <Info>
          <svg
            className="mb-3"
            width="60"
            height="60"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.32"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
              fill="#999"
            />
            <path
              d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
              fill="#999"
            />
          </svg>
          <h3>{t("Маркет пока пуст")}</h3>
        </Info>
      </>
    );
  }

  return (
    <>
      <Meta title={t("Маркет")} />
      <Card className="py-4 mb-4">
        <div className="px-3 pb-3">
          <h5 className="align-items-center d-flex fw-7">
            {t("Маркет")}
            {affiliate && affiliate?.id && (
              <span className="ms-2 badge bg-success badge-small">
                {affiliate.full}
              </span>
            )}
          </h5>
        </div>
        {modules.items.map((e) => {
          return (
            <>
              <p className="px-3 fw-6 fs-09 text-gray module-category">
                {t(e.title)}
              </p>
              <div className="p-3">
                {e.modules.length > 0 ? (
                  <Row className="gx-0">
                    {e.modules.map((item) => {
                      let price = moduleRatePrice({
                        module: item,
                        relationModule: item.relationModule,
                        brand,
                      });

                      return (
                        <Col xxl={3} lg={4} md={4} sm={6}>
                          <Link to={"/modules/" + item.id}>
                            <div
                              className={
                                "module-card p-3" +
                                (price?.status ? " active" : "")
                              }
                            >
                              <Row className="gx-3 align-items-center">
                                <Col md="auto" sm="auto" xs="auto">
                                  {item?.relationModule?.options?.balance ===
                                    0 ||
                                  item?.relationModule?.options?.balance ===
                                    "0" ||
                                  item?.relationModule?.status === 0 ||
                                  (item?.relationModule?.end &&
                                    moment(
                                      item.relationModule.end
                                    ).isBefore()) ||
                                  item?.relationModule?.options?.error ? (
                                    <IoAlert className="danger" />
                                  ) : (
                                    price?.status && (
                                      <>
                                        {item?.relationModule?.options
                                          ?.balance &&
                                        item?.relationModule?.options?.balance >
                                          0 ? (
                                          <span className="success-text">
                                            {customPrice(
                                              Math.floor(
                                                item.relationModule.options
                                                  .balance
                                              )
                                            )}
                                          </span>
                                        ) : (
                                          <IoCheckmark className="success" />
                                        )}
                                      </>
                                    )
                                  )}

                                  <img
                                    width={50}
                                    height={50}
                                    className="module-image"
                                    src={
                                      item?.media
                                        ? "/images/modules/mini/" + item.media
                                        : "/images/empty-product-image.png"
                                    }
                                  />
                                </Col>
                                <Col>
                                  <p className="d-flex align-items-center mb-1">
                                    <span className="fw-6 fs-08">
                                      {t(item?.title ?? "Название")}
                                    </span>
                                  </p>
                                  <Badge
                                    bg={
                                      price?.status
                                        ? "success"
                                        : moduleBgColor[item.type]
                                    }
                                  >
                                    {t(price.text)}
                                  </Badge>
                                </Col>
                              </Row>
                            </div>
                          </Link>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <div className="py-5 text-center text-muted">
                    {t("Категория пуста")}
                  </div>
                )}
              </div>
            </>
          );
        })}
      </Card>
    </>
  );
};

export default Modules;
