import React, { useCallback } from "react";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate } from "react-router-dom";
import DragDropFile from "../../components/DragDropFile";
import CustomEditor from "../../components/editor/CustomEditor";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import { createPortfolio } from "../../services/portfolio";

const PortfolioCreate = () => {
  const navigate = useNavigate();

  const {
    control,
    register,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const data = useWatch({ control });

  const onSubmit = useCallback(() => {
    var formData = new FormData();

    formData.append("title", data.title);
    formData.append("content", data.content);

    if (data?.file) {
      for (let file of data?.file) {
        formData.append("file", file);
      }
    }
    createPortfolio(formData)
      .then(() => {
        NotificationManager.success("Портфолио успешно добавлено");
        navigate(-1);
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      });
  }, [data]);

  return (
    <>
      <Meta title="Добавить портфолио" />
      <div className="d-flex justify-content-between align-items-center">
        <Link
          to="/content/portfolio"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
        <Button disabled={!isValid} onClick={() => onSubmit()}>
          Сохранить изменения
        </Button>
      </div>
      <h3 className="mb-4">Добавить портфолио</h3>
      <div className="mb-3">
        <DragDropFile
          main
          multiple
          file={data.file}
          onMain={(e) => setValue("main", e)}
          onChange={(e) => setValue("file", e)}
          onDelete={(e) => setValue("file", e.file)}
        />
      </div>
      <div className="mb-3">
        <Input
          autoFocus={true}
          label="Заголовок"
          name="title"
          placeholder="Введите заголовок"
          errors={errors}
          register={register}
          validation={{
            required: "Обязательное поле",
          }}
        />
      </div>
      <CustomEditor
        content={data.content}
        onChange={(e) => setValue("content", e)}
      />
    </>
  );
};

export default PortfolioCreate;
