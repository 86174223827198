import React, { useCallback, useLayoutEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import { getRoleData, roles } from "../../helpers/member";
import { editMember, getMember } from "../../services/member";

const MemberEdit = () => {
  const { memberId } = useParams();
  const affiliate = useSelector((state) => state.affiliate);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {
      groupRole: 1,
      role: 1,
      affiliateId: "",
    },
  });
  const form = useWatch({ control });

  useLayoutEffect(() => {
    getMember(memberId)
      .then((res) => reset(res))
      .finally(() => setLoading(false));
  }, []);

  const onSubmit = useCallback((data) => {
    setBtnLoading(true);
    editMember(data)
      .then(() => NotificationManager.success("Данные успешно обновлены"))
      .catch((error) =>
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        )
      )
      .finally(() => setBtnLoading(false));
  }, []);

  if (loading) {
    return <Loader full />;
  }

  if (!form?.id) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Нет информации о сотруднике</h3>
      </Info>
    );
  }

  return (
    <>
      <Meta title="Редактирование сотрудника" />
      <h3 className="mb-4">Редактирование сотрудника</h3>
      <Card body>
        <Row>
          <Col md={4}>
            <div className="mb-4">
              <Input
                autocomplete="off"
                label="Номер телефона"
                name="phone"
                placeholder="+7(000)000-00-00"
                mask="+7(999)999-99-99"
                errors={errors}
                register={register}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Input
                autocomplete="off"
                type="date"
                label="День рождения"
                placeholder="Введите день рождения (Необязательно)"
                name="brithday"
                errors={errors}
                register={register}
              />
            </div>
          </Col>
          <Col md={4}>
            <Select
              className="mb-4"
              label="Пол"
              onClick={(e) => setValue("sex", e.value)}
              value={form.sex ?? ""}
              data={[
                { title: "Не указано", value: "" },
                { title: "Мужской", value: "man" },
                { title: "Женский", value: "woman" },
              ]}
            />
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Input
                autocomplete="off"
                label="Фамилия"
                name="lastName"
                placeholder="Введите фамилию (Необязательно)"
                errors={errors}
                register={register}
                validation={{
                  maxLength: {
                    value: 50,
                    message: "Максимально 50 символов",
                  },
                }}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Input
                autocomplete="off"
                label="Имя"
                name="firstName"
                errors={errors}
                register={register}
                placeholder="Введите имя"
                validation={{
                  required: "Введите имя",
                  maxLength: {
                    value: 20,
                    message: "Максимально 20 символов",
                  },
                }}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Input
                label="Отчество"
                name="patronymic"
                autocomplete="off"
                errors={errors}
                register={register}
                placeholder="Введите отчество (Необязательно)"
                validation={{
                  maxLength: {
                    value: 50,
                    message: "Максимально 50 символов",
                  },
                }}
              />
            </div>
          </Col>
        </Row>
        <h5 className="mb-4">Настройки</h5>
        <Row>
          <Col md={4}>
            <Select
              className="mb-4"
              label="Роль"
              onClick={(e) => setValue("groupRole", e.value)}
              value={form.groupRole}
              data={roles}
            />
          </Col>
          <Col md={4}>
            <Select
              className="mb-4"
              label="Должность"
              onClick={(e) => setValue("role", e.value)}
              value={form.role}
              data={getRoleData(form.groupRole)}
            />
          </Col>
          <Col md={4}>
            <Select
              className="mb-4"
              label="Филиал"
              onClick={(e) => setValue("affiliateId", e.value)}
              value={form.affiliateId}
              data={affiliate.data.map((e) => ({
                title: e.title ? e.title : e.full,
                subTitle: e.title != e.full && e.full ? e.full : null,
                value: e.id,
                main: e.main,
              }))}
            />
          </Col>
        </Row>
        <h5 className="mb-4">Данные для входа</h5>
        <Row>
          <Col md={4}>
            <div className="mb-4">
              <Input
                autocomplete="off"
                label="Email"
                name="email"
                placeholder="Введите email"
                errors={errors}
                register={register}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Input
                autocomplete="off"
                label="Пароль"
                name="password"
                type="password"
                errors={errors}
                placeholder="Введите пароль"
                register={register}
                validation={{
                  maxLength: {
                    value: 200,
                    message: "Максимально 200 символов",
                  },
                }}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Input
                autocomplete="off"
                label="Повторный пароль"
                name="passwordConfirm"
                type="password"
                errors={errors}
                placeholder="Введите повторно пароль"
                register={register}
                validation={{
                  maxLength: {
                    value: 200,
                    message: "Максимально 200 символов",
                  },
                }}
              />
            </div>
          </Col>
        </Row>
        <h5 className="mb-4">Паспортные данные</h5>
        <Row>
          <Col md={4}>
            <div className="mb-4">
              <Input
                autocomplete="off"
                label="Серия и номер"
                name="passport.number"
                errors={errors}
                register={register}
                placeholder="Введите серию и номер"
                validation={{
                  maxLength: {
                    value: 20,
                    message: "Максимально 20 символов",
                  },
                }}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Input
                autocomplete="off"
                type="date"
                label="Дата регистрации"
                placeholder="Введите дату регистрации"
                name="passport.reg"
                errors={errors}
                register={register}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Input
                autocomplete="off"
                label="Место регистрации"
                name="passport.place"
                errors={errors}
                register={register}
                placeholder="Введите место регистрации"
                validation={{
                  maxLength: {
                    value: 250,
                    message: "Максимально 250 символов",
                  },
                }}
              />
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <Button
            isValid={isValid}
            isLoading={btnLoading}
            disabled={btnLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Сохранить изменения
          </Button>
        </div>
      </Card>
    </>
  );
};

export default MemberEdit;
