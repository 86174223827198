import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isConnected: true,
    ip: '0.0.0.0',
    brand: false,
    audio: true
}

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        updateConnect: (state, action) => {
            state.isConnected = action.payload
        },
        updateOptions: (state, action) => {
            state.options = action.payload
        },
        updateIp: (state, action) => {
            state.ip = action.payload
        },
        updateBrand: (state, action) => {
            state.brand = action.payload
        },
        updateAudio: (state, action) => {
            state.audio = action.payload
        },
    },
})

export const { updateConnect, updateAudio, updateOptions, updateIp, updateBrand } = settingsSlice.actions

export default settingsSlice.reducer
