import React, { useCallback, useLayoutEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import { createTable, editTable, getTable } from "../../services/table";

const TableCreate = () => {
  const { tableId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      status: 1,
    },
  });
  const data = useWatch({ control });

  const onSubmit = useCallback((data) => {
    createTable(data)
      .then((res) => {
        NotificationManager.success("Стол успешно добавлен");
        navigate(-1);
      })
      .catch((err) => {
        NotificationManager.error(
          err.response.data.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  return (
    <>
      <Meta title="Создать стол" />
      <div className="d-flex justify-content-between align-items-center">
        <Link
          to="/options/affiliates/tables"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
        <Button onClick={handleSubmit(onSubmit)}>Сохранить</Button>
      </div>
      <h3 className="mb-4">Создать стол</h3>
      <Row>
        <Col md={6}>
          <div className="mb-4 position-relative">
            <Input
              errors={errors}
              label="Название"
              autoComplete="off"
              name="title"
              placeholder="Введите название"
              register={register}
              validation={{
                required: "Обязательное поле",
                maxLength: { value: 50, message: "Максимум 50 символов" },
              }}
            />
          </div>
          <Form.Check className="mb-4">
            <Form.Check.Input
              type="checkbox"
              name="status"
              id="status"
              {...register("status")}
            />
            <Form.Check.Label htmlFor="status" className="ms-2">
              Стол Работает\Не работает
            </Form.Check.Label>
          </Form.Check>
        </Col>
      </Row>
    </>
  );
};

export default TableCreate;
