import moment from "moment";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { Badge, Card, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoAdd, IoChevronBackOutline, IoRefreshOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import Chat from "../../components/chat";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import socket from "../../config/socket";
import { getImageURL } from "../../helpers/image";
import {
  createMessage,
  getMessages,
  viewMessages,
} from "../../services/message";
import {
  blockedUser,
  getUser,
  createPoint,
  deleteUser,
  updatePoint,
} from "../../services/user";

const EditUser = () => {
  const brand = useSelector((state) => state.brand.active);
  const { userId } = useParams();
  const [loading, setLoading] = useState(true);
  const [blockedShow, setBlockedShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [pointShow, setPointShow] = useState(false);
  const navigate = useNavigate();
  const [statistic, setStatistic] = useState(false);
  const [points, setPoints] = useState(false);
  const [print, setPrint] = useState(false);
  const timer = useRef(0);
  const [messages, setMessages] = useState({
    loading: true,
    items: [],
  });

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });
  const form = useWatch({ control });

  const pointColumns = [
    {
      name: "Действие",
      selector: "desc",
    },
    {
      name: "Сумма",
      selector: "amount",
      width: "130px",
    },
    {
      name: "Заказ",
      selector: "orderId",
      width: "130px",
    },
    {
      width: "120px",
      name: "Статус",
      align: "center",
      selector: "status",
      cell: (row) =>
        row.status === 0 ? (
          <Badge bg="secondary">Архив</Badge>
        ) : (
          <Badge bg="success">Выполнен</Badge>
        ),
    },
  ];

  const {
    control: controlChat,
    reset: resetChat,
    setValue: setValueChat,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      adminId: userId,
    },
  });

  const chat = useWatch({ control: controlChat });

  const {
    register: registerBlocked,
    formState: { errors: errorsBlocked, isValid: isValidBlocked },
    handleSubmit: handleSubmitBlocked,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: { id: userId },
  });

  const {
    control: controlPoint,
    register: registerPoint,
    formState: { errors: errorsPoint, isValid: isValidPoint },
    handleSubmit: handleSubmitPoint,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: { id: userId },
  });

  const pointData = useWatch({ control: controlPoint });

  useEffect(() => {
    if (chat?.adminId) {
      viewMessages(chat);
      getMessages(chat)
        .then((res) =>
          setMessages((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }))
        )
        .catch(() => setMessages((prev) => ({ ...prev, loading: false })));
    }
  }, [chat?.adminId, brand]);

  useEffect(() => {
    if (chat.adminId) {
      socket.on("message/user/" + chat.adminId, (data) => {
        if (data) {
          setPrint(false);
          setMessages((prev) => ({
            ...prev,
            loading: false,
            items: [
              data,
              ...prev.items.map((e) => {
                if (e?.memberId) {
                  e.view = true;
                }
                return e;
              }),
            ],
          }));
        }
      });

      socket.on("message/view/" + chat.adminId, (data) => {
        setMessages((prev) => ({
          ...prev,
          loading: false,
          items: prev.items.map((e) => {
            if (e?.memberId && data == "client") {
              e.view = true;
            }
            return e;
          }),
        }));
      });

      socket.on("message/online/" + chat.adminId, (online) => {
        setMessages((prev) => ({
          ...prev,
          user: {
            ...prev.user,
            online,
          },
        }));
        onLoadDialogs();
      });

      socket.on("message/print/admin/" + chat.adminId, () => {
        setPrint(true);
        if (timer.current === 0) {
          timer.current = 1;
          setTimeout(() => {
            timer.current = 0;
            setPrint(false);
          }, 5000);
        }
      });

      return () => {
        socket.off("message/user/" + chat.adminId);
        socket.off("message/view/" + chat.adminId);
        socket.off("message/print/admin/" + chat.adminId);
      };
    }
  }, [chat.adminId, brand]);

  useEffect(() => {
    if (timer.current === 0 && chat?.text?.length > 0) {
      timer.current = 1;
      socket.emit("message/print", { adminId: chat.adminId });
      setTimeout(() => {
        timer.current = 0;
      }, 3000);
    }
  }, [chat?.text]);

  const onNewMessage = useCallback(
    (text) => {
      createMessage({ ...chat, text });
      resetChat({ adminId: chat.adminId });
    },
    [chat, userId]
  );

  const onSubmitDelete = useCallback((id) => {
    deleteUser(id).then(() => navigate(-1));
  }, []);

  const getData = () => {
    getUser(userId)
      .then((res) => {
        reset(res.user);
        setStatistic(res.statistic);
        setPoints(res.points);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => getData(), [userId, brand]);

  // const onSubmit = useCallback((data) => {
  //   editUser(data)
  //     .then(() => NotificationManager.success("Данные успешно обновлены"))
  //     .catch((error) =>
  //       NotificationManager.error(
  //         typeof error?.response?.data?.error == "string"
  //           ? error.response.data.error
  //           : "Неизвестная ошибка"
  //       )
  //     );
  // }, []);

  const onSubmitBlocked = useCallback(() => {
    blockedUser({ ...form, status: form.status === 0 ? 1 : 0 })
      .then((res) => {
        NotificationManager.success(
          form.status === 0
            ? "Пользователь разблокирован"
            : "Пользователь заблокирован"
        );
        reset(res);
        form.status !== 0 && setBlockedShow(false);
      })
      .catch((error) =>
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        )
      );
  }, [form]);

  const onSubmitPoint = useCallback(() => {
    createPoint(pointData)
      .then(() => {
        NotificationManager.success(
          pointData?.minus ? "Баллы успешно списаны" : "Баллы успешно начислены"
        );
        getData();
        setPointShow(false);
      })
      .catch((error) =>
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        )
      );
  }, [pointData]);

  const onSubmitPointUpdate = useCallback(() => {
    updatePoint(pointData)
      .then(() => {
        NotificationManager.success("Баллы успешно обновлены");
        getData();
      })
      .catch((error) =>
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        )
      );
  }, []);

  if (loading) {
    return <Loader full />;
  }

  if (!form?.id) {
    return (
      <>
        <Meta title="Редактировать пользователя" />
        <Info>
          <svg
            className="mb-3"
            width="60"
            height="60"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.32"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
              fill="#999"
            />
            <path
              d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
              fill="#999"
            />
          </svg>
          <h3>Такого пользователя не существует</h3>
        </Info>
      </>
    );
  }

  return (
    <>
      <Meta title="Редактировать пользователя" />
      <div>
        <Link
          to="/users"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к
          пользователям
        </Link>
      </div>
      <h3 className="mb-4">Редактировать пользователя</h3>
      <Row className="mb-2 align-items-justify">
        <Col md={4}>
          <Card className="mb-3" body>
            <div className="d-flex align-items-start mb-3">
              <div className="pe-3 mt-1">
                <div className="position-relative">
                  {(form.status === 0 || form.blockedEnd) && (
                    <div className="blocked-avatar">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.32"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          fill="#FF0000"
                          d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
                        />
                        <path
                          fill="#FF0000"
                          d="M18.6038 3.01136C19.2189 2.39628 20.2161 2.39628 20.8312 3.01136C21.4463 3.62643 21.4463 4.62367 20.8312 5.23874L5.23947 20.8304C4.6244 21.4455 3.62716 21.4455 3.01209 20.8304C2.39701 20.2154 2.39701 19.2181 3.01209 18.6031L18.6038 3.01136Z"
                        />
                      </svg>
                    </div>
                  )}
                  <img
                    src={getImageURL({ path: form.media, type: "user" })}
                    width={45}
                    height={45}
                  />
                </div>
              </div>
              <div className="w-100 d-flex justify-content-between">
                <div>
                  <p className="fw-6">{form?.firstName ?? "Не указано"}</p>
                  <p className="text-muted fs-08">
                    Зарегистирован{" "}
                    {moment(form?.createdAt).format("DD MMMM YYYY kk:mm") ??
                      "Email не указан"}
                  </p>
                  <p className="text-primary fs-08">ID - {form?.id}</p>
                </div>
                <div className="d-flex flex-column justify-content-between align-items-end">
                  <span className="fw-6 d-flex align-items-center fs-09">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M15.5237 19.0041C15.3637 19.0048 15.206 18.967 15.0637 18.8941L9.96366 16.2241L4.86366 18.8941C4.52579 19.0718 4.11625 19.0416 3.80808 18.8163C3.49992 18.591 3.34689 18.21 3.41366 17.8341L4.41366 12.2041L0.293656 8.20411C0.0317118 7.94271 -0.0644601 7.55802 0.0436555 7.20411C0.1619 6.84153 0.476078 6.57778 0.853656 6.52411L6.55366 5.69411L9.06366 0.56411C9.23074 0.21912 9.58033 0 9.96366 0C10.347 0 10.6966 0.21912 10.8637 0.56411L13.4037 5.68411L19.1037 6.51411C19.4812 6.56778 19.7954 6.83153 19.9137 7.19411C20.0218 7.54802 19.9256 7.93271 19.6637 8.19411L15.5437 12.1941L16.5437 17.8241C16.6165 18.2069 16.4604 18.5972 16.1437 18.8241C15.9626 18.951 15.7445 19.0143 15.5237 19.0041Z"
                        fill="#ffc107"
                      />
                    </svg>
                    &nbsp;{form?.options?.rating ?? 0}
                  </span>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <Input
                readOnly={false}
                label="Имя"
                name="firstName"
                defaultValue={form.firstName}
                errors={errors}
                register={register}
                validation={{
                  required: "Введите имя",
                  minLength: {
                    value: 2,
                    message: "Минимально 2 символа",
                  },
                  maxLength: {
                    value: 250,
                    message: "Максимально 250 символов",
                  },
                }}
              />
            </div>
            <div className="mb-3">
              <Input
                readOnly={false}
                label="Email"
                name="email"
                defaultValue={form.email}
                errors={errors}
                register={register}
                validation={{
                  required: "Введите Email",
                  minLength: {
                    value: 3,
                    message: "Минимально 3 символа",
                  },
                  maxLength: {
                    value: 250,
                    message: "Максимально 250 символов",
                  },
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Неверный формат Email",
                  },
                }}
              />
            </div>
            <Row>
              <Col md={12}>
                <div className="mb-3">
                  <Input
                    readOnly={false}
                    label="Номер телефона"
                    name="phone"
                    // mask="+7(999)999-99-99"
                    errors={errors}
                    defaultValue={form.phone}
                    register={register}
                  />
                </div>
              </Col>
            </Row>

            {form?.blockedEnd && (
              <p className="text-danger mb-3 fs-09">
                Блокировка до {moment(form.blockedEnd).format("DD.MM.YYYY")}
              </p>
            )}

            {form.status === 0 || form.blockedEnd ? (
              <Button
                className="btn btn-primary w-100"
                onClick={handleSubmitBlocked(onSubmitBlocked)}
              >
                Разблокировать
              </Button>
            ) : (
              <Button
                className="btn btn-danger w-100"
                onClick={() => setBlockedShow(true)}
              >
                Заблокировать
              </Button>
            )}
            <Button
              className="btn mt-3 btn-danger w-100"
              onClick={() => setDeleteShow(true)}
            >
              Удалить
            </Button>
          </Card>
        </Col>
        <Col md={8}>
          <Row md={3}>
            <Col md={3} className="pb-3">
              <Card className="d-flex justify-content-center p-3">
                <h3 className="fw-6 mb-0">{statistic?.order ?? 0}</h3>
                <p className="fs-08 text-muted">Заказов</p>
              </Card>
            </Col>
            <Col md={3} className="pb-3">
              <Card className="d-flex justify-content-center p-3">
                <h3 className="fw-6 mb-0">{statistic?.total ?? 0}</h3>
                <p className="fs-07 text-muted">Общая сумма</p>
              </Card>
            </Col>
            <Col md={3} className="pb-3">
              <Card className="d-flex justify-content-center p-3">
                <h3 className="fw-6 mb-0">{statistic?.average ?? 0}</h3>
                <p className="fs-07 text-muted">Средний чек</p>
              </Card>
            </Col>
            <Col md={3} className="pb-3">
              <Card className="d-flex justify-content-center p-3">
                <h3 className="fw-6 mb-0">{form.point ?? 0}</h3>
                <p className="fs-08 text-muted">Баллы</p>
              </Card>
            </Col>
          </Row>
          <Card className="mb-3">
            <Tabs defaultActiveKey={0} fill>
              <Tab eventKey={0} title="Чат">
                <Card.Header>
                  <h5 className="fw-7 mb-0">Чат</h5>
                  {messages?.user && (
                    <p className="text-muted fs-07">
                      {print ? (
                        "Печатает сообщение..."
                      ) : messages?.user?.online?.status ? (
                        <span className="text-success">Онлайн</span>
                      ) : messages?.user?.online?.end ? (
                        "Был(-а) в сети " +
                        moment(messages?.user?.online?.end).fromNow()
                      ) : (
                        "Оффлайн"
                      )}
                    </p>
                  )}
                </Card.Header>
                <Card.Body>
                  {!chat?.adminId ? (
                    <div className="chat d-flex align-items-center justify-content-center flex-column">
                      <h2 className="mb-3">Выберите диалог</h2>
                      <p className="text-gray">
                        В данный момент нет диалогов. Возможно вы не выбрали
                        конкретный диалог.
                      </p>
                    </div>
                  ) : messages.loading ? (
                    <div className="chat d-flex align-items-center justify-content-center flex-column">
                      <Loader />
                    </div>
                  ) : (
                    <Chat
                      data={messages.items}
                      emptyText="Нет сообщений"
                      onSubmit={(e) => onNewMessage(e)}
                      onChange={(e) => setValueChat("text", e)}
                    />
                  )}
                </Card.Body>
              </Tab>
              <Tab eventKey={1} title="Баллы">
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <div>
                    <h5>Всего {form.point} баллов</h5>
                  </div>
                  <div className="d-flex align-items-center">
                    <Button
                      className="btn-primary-outline me-3"
                      onClick={() => onSubmitPointUpdate()}
                    >
                      <IoRefreshOutline size={18} />
                    </Button>
                    <Button
                      className="btn-primary-outline"
                      onClick={() => setPointShow(!pointShow)}
                    >
                      <IoAdd size={18} />
                    </Button>
                  </div>
                </Card.Header>
                <DataTable columns={pointColumns} data={points} />
              </Tab>
            </Tabs>
          </Card>
        </Col>
      </Row>
      <CustomModal
        title="Удаление пользователя"
        show={deleteShow}
        setShow={(e) => setDeleteShow(e)}
        footer={
          <>
            <Button className=" me-3" onClick={() => setDeleteShow(false)}>
              Отмена
            </Button>
            <Button
              className="btn-danger"
              onClick={() => onSubmitDelete(form.id)}
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить данного пользователя?
      </CustomModal>
      <CustomModal
        title="Блокировка пользователя"
        show={blockedShow}
        setShow={(e) => setBlockedShow(e)}
        footer={
          <>
            <Button className=" me-3" onClick={() => setBlockedShow(false)}>
              Отмена
            </Button>
            <Button
              className="btn-danger"
              onClick={handleSubmitBlocked(onSubmitBlocked)}
            >
              Заблокировать
            </Button>
          </>
        }
      >
        <Input
          label="Окончание блокировки"
          type="datetime-local"
          name="blockedEnd"
          errors={errorsBlocked}
          defaultValue={form.nickname}
          register={registerBlocked}
        />
      </CustomModal>
      <CustomModal
        title="Начисление\Списание баллов"
        show={pointShow}
        setShow={(e) => setPointShow(e)}
        footer={
          <>
            <Button className=" me-3" onClick={() => setPointShow(false)}>
              Отмена
            </Button>
            <Button onClick={handleSubmitPoint(onSubmitPoint)}>
              {pointData?.minus ? "Списать" : "Начислить"}
            </Button>
          </>
        }
      >
        <Input
          label="Кол-во баллов"
          type="numeric"
          name="amount"
          errors={errorsPoint}
          register={registerPoint}
        />
        <Form.Check className="mt-3">
          <Form.Check.Input
            type="checkbox"
            name="minus"
            id="minus"
            {...registerPoint("minus")}
          />
          <Form.Check.Label htmlFor="minus" className="ms-2">
            Списать баллы
          </Form.Check.Label>
        </Form.Check>
      </CustomModal>
    </>
  );
};

export default EditUser;
