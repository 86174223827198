import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import Loader from "./Loader";

const Button = memo(
  ({ isLoading, children, className = "btn-primary", ...props }) => {
    const { t } = useTranslation();
    return (
      <button className={"btn " + className} {...props}>
        {isLoading ? (
          <Loader
            size={20}
            color={
              className == "btn-primary" ||
              className == "btn-blue" ||
              className == "btn-danger"
                ? "#FFFFFF"
                : false
            }
          />
        ) : typeof children === "string" ? (
          t(children)
        ) : (
          children
        )}
      </button>
    );
  }
);

export default Button;
