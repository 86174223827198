import React, { useCallback, useLayoutEffect } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import InputCode from "../../components/UI/InputCode";
import { IoChevronBackSharp } from "react-icons/io5";

const NewPassword = () => {
  const location = useLocation();
  let { email = "" } = location.state;

  const auth = useSelector((state) => state?.auth);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (auth.isAuth) {
      return navigate("/");
    }
  }, [auth.isAuth]);

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({ mode: "all", reValidateMode: "onSubmit" });

  const dispatch = useDispatch();

  const onSubmit = useCallback((data) => {}, []);

  return (
    <>
      <Meta title="Смена пароля" />
      <div className="login">
        <svg
          height="70"
          className="mb-4"
          fill="none"
          viewBox="0 0 96 97"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#007867"
            d="M80.476 16.01c-2-2-48.544 35.833-52.269 43.652 0 0-.155 29.249 5.21 30.093 2.904 0 8.228-15.41 9.35-18.755.143-.428.339-.805.612-1.163 11.697-15.343 39.074-51.85 37.097-53.827z"
          ></path>
          <path
            fill="#00A76F"
            d="M11.167 39.109c-2.51 1.716-2.606 4.943-.319 6.946 3.06 2.68 8.372 7.018 17.359 13.62v-.013c3.724-7.82 50.269-45.652 52.269-43.652 1.977 1.977-25.4 38.484-37.097 53.826-.273.36-.47.736-.613 1.163-1.082 3.227-6.071 17.672-9.03 18.699 6.326-1.22 12.623-6.279 18.458-13.265 7.403 4.887 12.643 8.034 15.829 9.86 2.228 1.279 4.962.804 6.134-1.482 6.764-13.202 14.387-52.323 15.821-72.819.231-3.305-2.447-5.951-5.707-5.355-20.295 3.714-59.214 22.977-73.104 32.472zM33.417 89.755z"
          ></path>
          <g
            fill="#fff"
            fill-opacity="0.04"
            filter="url(#filter0_i_1870_134242)"
          >
            <path d="M11.167 39.109c-2.51 1.716-2.606 4.943-.319 6.946 3.06 2.68 8.372 7.018 17.359 13.62v-.013c3.724-7.82 50.269-45.652 52.269-43.652 1.977 1.977-25.4 38.484-37.097 53.826-.273.36-.47.736-.613 1.163-1.082 3.227-6.071 17.672-9.03 18.699 6.326-1.22 12.623-6.279 18.458-13.265 7.403 4.887 12.643 8.034 15.829 9.86 2.228 1.279 4.962.804 6.134-1.482 6.764-13.202 14.387-52.323 15.821-72.819.231-3.305-2.447-5.951-5.707-5.355-20.295 3.714-59.214 22.977-73.104 32.472zM33.417 89.755z"></path>
          </g>
          <defs>
            <filter
              id="filter0_i_1870_134242"
              width="82.787"
              height="85.202"
              x="7.205"
              y="4.553"
              color-interpolation-filters="sRGB"
              filterUnits="userSpaceOnUse"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
              <feBlend
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              ></feBlend>
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              ></feColorMatrix>
              <feOffset dx="-2" dy="-2"></feOffset>
              <feGaussianBlur stdDeviation="2"></feGaussianBlur>
              <feComposite
                in2="hardAlpha"
                k2="-1"
                k3="1"
                operator="arithmetic"
              ></feComposite>
              <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"></feColorMatrix>
              <feBlend
                in2="shape"
                result="effect1_innerShadow_1870_134242"
              ></feBlend>
            </filter>
          </defs>
        </svg>
        <h2 className="mb-3 text-center">Запрос успешно отправлен!</h2>
        <p className="mb-4 text-center text-gray fs-08">
          Мы отправили 6-значное письмо с подтверждением на вашу электронную
          почту. <br />
          Пожалуйста, введите код в поле ниже, чтобы подтвердить свой адрес
          электронной почты.
        </p>
        <Form
          className="login-form text-center d-flex flex-column justify-content-center"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="mb-4">
            <Input
              readOnly={false}
              label="Email"
              type="email"
              name="email"
              defaultValue={email}
              errors={errors}
              register={register}
              validation={{
                required: "Введите email",
                maxLength: {
                  value: 100,
                  message: "Максимально 100 символов",
                },
              }}
            />
          </div>
          <div className="mb-4">
            <InputCode />
          </div>
          <div className="mb-4">
            <Input
              label="Пароль"
              type="password"
              name="password"
              errors={errors}
              register={register}
              validation={{
                required: "Введите пароль",
                maxLength: {
                  value: 200,
                  message: "Максимально 200 символов",
                },
              }}
            />
          </div>
          <div className="mb-4">
            <Input
              label="Повторите пароль"
              type="password"
              name="confirmPassword"
              errors={errors}
              register={register}
              validation={{
                required: "Введите повторный пароль",
                maxLength: {
                  value: 200,
                  message: "Максимально 200 символов",
                },
              }}
            />
          </div>
          <Button
            type="submit"
            className="btn-primary w-100"
            disabled={!isValid}
          >
            Обновить пароль
          </Button>
          <p className="mt-4 text-muted fs-08">
            Не пришел код?{" "}
            <Link className="text-success" to="/login">
              Отправить повторно
            </Link>
          </p>
          <Link
            className="mt-4 fw-7 fs-08 text-dark d-flex align-items-center align-self-center "
            to="/login"
          >
            <IoChevronBackSharp className="me-1" /> Вернутся, чтобы войти
          </Link>
        </Form>
      </div>
    </>
  );
};

export default NewPassword;
