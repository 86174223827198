import { memo, useCallback, useLayoutEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import Input from "../../../components/UI/Input";
import Button from "../../../components/UI/Button";
import { Card, Col, Form, Row } from "react-bootstrap";
import { IoRefreshOutline, IoSwapHorizontalOutline } from "react-icons/io5";
import { getStatistics } from "../../../services/statistic";
import {
  getEprCategory,
  getEprModifier,
  getEprOrganizations,
  getEprProduct,
  getEprTerminal,
  getEprTypesDelivery,
  getEprTypesPayment,
} from "../../../services/upload";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const RKeeperForm = memo(({ data, onSubmit }) => {
  const brand = useSelector((state) => state?.brand?.active);

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    // reset,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: data,
  });

  const form = useWatch({ control });

  const [product, setProduct] = useState(false);

  const [modifier, setModifier] = useState(false);

  const [category, setCategory] = useState(false);

  const [organizations, setOrganizations] = useState(false);
  const [typesDelivery, setTypesDelivery] = useState(false);
  const [typesPayment, setTypesPayment] = useState(false);
  const [terminal, setTerminal] = useState(false);

  const [statistic, setStatistic] = useState({
    loading: true,

    uploadCategories: 0,
    uploadProducts: 0,
    uploadModifiers: 0,

    categories: 0,
    products: 0,
    modifiers: 0,

    organizations: 0,
    typesDelivery: 0,
    typesPayment: 0,
    terminal: 0,
  });

  useLayoutEffect(() => {
    getStatistics({ sync: true, category: true, product: true, modifier: true })
      .then((res) => setStatistic({ ...statistic, ...res, loading: false }))
      .catch(() => setStatistic({ ...statistic, loading: false }));
  }, [product, modifier, category]);

  const clickGetErpProduct = useCallback(() => {
    setProduct(true);
    getEprProduct(form).finally(() => setProduct(false));
  }, [form]);

  const clickGetErpModifier = useCallback(() => {
    setModifier(true);
    getEprModifier(form).finally(() => setModifier(false));
  }, [form]);

  const clickGetErpCategory = useCallback(() => {
    setCategory(true);
    getEprCategory(form).finally(() => setCategory(false));
  }, [form]);

  const clickGetErpOrganization = useCallback(() => {
    setOrganizations(true);
    getEprOrganizations(form)
      .then((res) => setValue("relationModule.options", res))
      .finally(() => setOrganizations(false));
  }, [form]);

  const clickGetErpTypesDelivery = useCallback(() => {
    setTypesDelivery(true);
    getEprTypesDelivery(form)
      .then((res) => setValue("relationModule.options", res))
      .finally(() => setTypesDelivery(false));
  }, [form]);

  const clickGetErpTypesPayment = useCallback(() => {
    setTypesPayment(true);
    getEprTypesPayment(form)
      .then((res) => setValue("relationModule.options", res))
      .finally(() => setTypesPayment(false));
  }, [form]);

  const clickGetErpTerminal = useCallback(() => {
    setTerminal(true);
    getEprTerminal(form)
      .then((res) => setValue("relationModule.options", res))
      .finally(() => setTerminal(false));
  }, [form]);

  return (
    <>
      <Card body className="mb-4">
        <h5 className="mb-4">Настройки</h5>
        <Row>
          <Col lg={6}>
            <div className="mb-3">
              <Input
                label="Клиент ID"
                placeholder="Введите client id"
                name="relationModule.options.clientId"
                errors={errors}
                register={register}
                validation={{ required: "Обязательное поле" }}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Input
                label="Секрет ID"
                placeholder="Введите secret id"
                name="relationModule.options.secretId"
                errors={errors}
                register={register}
                validation={{ required: "Обязательное поле" }}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Input
                label="Email (Получение заказа)"
                placeholder="Почта на которую придет заказ"
                name="relationModule.options.email"
                errors={errors}
                register={register}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Input
                label="Ссылка на webhook"
                readOnly={false}
                defaultValue={`${
                  process.env.REACT_APP_WEBHOOK_URL
                }/accounting/${brand?.id ?? ""}/`}
              />
              <p className="text-muted fs-07 mt-1">
                Данную ссылку нужно указать в настройках вашего системы учета,
                куда будут приходить уведомления.
              </p>
            </div>
          </Col>
          {/* <Col lg={6}>
            <Form.Check className="mb-4">
              <Form.Check.Input
                type="checkbox"
                name="relationModule.options.callCenter"
                id="callCenter"
                defaultChecked={form?.relationModule?.options?.callCenter}
                {...register("relationModule.options.callCenter")}
              />
              <Form.Check.Label htmlFor="callCenter" className="ms-2">
                Получать заказы через колл-центр
              </Form.Check.Label>
            </Form.Check>
          </Col> */}
        </Row>
        <div className="d-flex justify-content-end">
          <Button isValid={isValid} onClick={handleSubmit(onSubmit)}>
            Сохранить изменения
          </Button>
        </div>
      </Card>

      <h5 className="mb-4 fw-7">Синхронизация</h5>
      <Row>
        <Col md={4}>
          <Card body className="mb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-2 fw-6 h6">Категории</h5>
              <Button
                isLoading={category}
                disabled={
                  category ||
                  !form?.relationModule?.options?.clientId ||
                  !form?.relationModule?.options?.secretId
                }
                onClick={() => clickGetErpCategory()}
                className="btn btn-sm btn-default"
              >
                <IoRefreshOutline size={22} className="text-gray" />
              </Button>
            </div>
            <h2 className="m-0 d-flex align-items-center">
              {statistic.uploadCategories}
              <IoSwapHorizontalOutline className="text-muted fs-07 mx-2" />
              <span className="text-success">{statistic.categories}</span>
            </h2>
            <div className="mt-2">
              <Link
                to="/upload/categories"
                disabled={
                  category ||
                  !form?.relationModule?.options?.clientId ||
                  !form?.relationModule?.options?.secretId
                }
                className="btn-sm btn-primary-outline"
              >
                Перейти к списку
              </Link>
            </div>
          </Card>
        </Col>
        <Col md={4}>
          <Card body className="mb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-2 fw-6 h6">Товары</h5>
              <Button
                isLoading={product}
                disabled={
                  product ||
                  !form?.relationModule?.options?.clientId ||
                  !form?.relationModule?.options?.secretId
                }
                onClick={() => clickGetErpProduct()}
                className="btn btn-sm btn-default"
              >
                <IoRefreshOutline size={22} className="text-gray" />
              </Button>
            </div>
            <h2 className="m-0 d-flex align-items-center">
              {statistic.uploadProducts}
              <IoSwapHorizontalOutline className="text-muted fs-07 mx-2" />
              <span className="text-success">{statistic.products}</span>
            </h2>
            <div className="mt-2">
              <Link
                to="/upload/products"
                disabled={product || !form?.relationModule?.options?.login}
                className="btn-sm btn-primary-outline"
              >
                Перейти к списку
              </Link>
            </div>
          </Card>
        </Col>
        <Col md={4}>
          <Card body className="mb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-2 fw-6 h6">Модификаторы</h5>
              <Button
                isLoading={modifier}
                disabled={modifier || !form?.relationModule?.options?.login}
                onClick={() => clickGetErpModifier()}
                className="btn btn-sm btn-default"
              >
                <IoRefreshOutline size={22} className="text-gray" />
              </Button>
            </div>
            <h2 className="m-0 d-flex align-items-center">
              {statistic.uploadModifiers}
              <IoSwapHorizontalOutline className="text-muted fs-07 mx-2" />
              <span className="text-success">{statistic.modifiers}</span>
            </h2>
            <div className="mt-2">
              <Link
                to="/upload/modifiers"
                disabled={modifier || !form?.relationModule?.options?.login}
                className="btn-sm btn-primary-outline"
              >
                Перейти к списку
              </Link>
            </div>
          </Card>
        </Col>
      </Row>

      {/* <h5 className="my-4 fw-7">Данные для заказа</h5>
      <Row>
        <Col md={6}>
          <Card body className="mb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-2 fw-6 h6">Организации</h5>
              <Button
                isLoading={organizations}
                disabled={
                  organizations || !form?.relationModule?.options?.login
                }
                onClick={() => clickGetErpOrganization()}
                className="btn btn-sm btn-default"
              >
                <IoRefreshOutline size={22} className="text-gray" />
              </Button>
            </div>
            <h2 className="m-0 d-flex align-items-center">
              {form?.relationModule &&
              Array.isArray(form?.relationModule?.options?.organizations)
                ? form.relationModule.options.organizations.length
                : 0}
            </h2>
            {form?.relationModule &&
              form?.relationModule?.options?.organizations[0]?.name && (
                <p className="mt-1 fs-09 text-muted">
                  {form.relationModule.options.organizations[0].name}
                </p>
              )}
          </Card>
        </Col>
        <Col md={6}>
          <Card body className="mb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-2 fw-6 h6">Типы доставки</h5>
              <Button
                isLoading={typesDelivery}
                disabled={
                  typesDelivery || !form?.relationModule?.options?.login
                }
                onClick={() => clickGetErpTypesDelivery()}
                className="btn btn-sm btn-default"
              >
                <IoRefreshOutline size={22} className="text-gray" />
              </Button>
            </div>
            <h2 className="m-0 d-flex align-items-center">
              {form?.relationModule?.options?.typesDelivery[0]?.items?.length ??
                0}
            </h2>
            {form?.relationModule?.options?.typesDelivery[0]?.items &&
              form?.relationModule?.options?.typesDelivery[0]?.items.map(
                (item) => (
                  <p className="mt-1 fs-09 text-muted d-flex justify-content-between">
                    <span>{item.name}</span>
                    <span>{item.orderServiceType}</span>
                  </p>
                )
              )}
          </Card>
        </Col>
        <Col md={6}>
          <Card body className="mb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-2 fw-6 h6">Типы оплаты</h5>
              <Button
                isLoading={typesPayment}
                disabled={typesPayment || !form?.relationModule?.options?.login}
                onClick={() => clickGetErpTypesPayment()}
                className="btn btn-sm btn-default"
              >
                <IoRefreshOutline size={22} className="text-gray" />
              </Button>
            </div>
            <h2 className="m-0 d-flex align-items-center">
              {form?.relationModule?.options?.typesPayment?.length ?? 0}
            </h2>
            {form?.relationModule?.options?.typesPayment &&
              form?.relationModule?.options?.typesPayment.map((item) => (
                <p className="mt-1 fs-09 text-muted d-flex justify-content-between">
                  <span>{item.name}</span>
                  <span>{item.code}</span>
                </p>
              ))}
          </Card>
        </Col>
        <Col md={6}>
          <Card body className="mb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-2 fw-6 h6">Терминалы</h5>
              <Button
                isLoading={terminal}
                disabled={terminal || !form?.relationModule?.options?.login}
                onClick={() => clickGetErpTerminal()}
                className="btn btn-sm btn-default"
              >
                <IoRefreshOutline size={22} className="text-gray" />
              </Button>
            </div>
            <h2 className="m-0 d-flex align-items-center">
              {form?.relationModule?.options?.terminals[0]?.items?.length ?? 0}
            </h2>
            {form?.relationModule?.options?.terminals[0]?.items &&
              form.relationModule.options.terminals[0].items.map((item) => (
                <p className="mt-1 fs-09 text-muted d-flex justify-content-between">
                  <span>{item.name}</span>
                  <span>{item.address}</span>
                </p>
              ))}
          </Card>
        </Col>
      </Row> */}
    </>
  );
});
export default RKeeperForm;
