import { memo } from "react";
import { tagsData } from "../helpers/all";

const Tags = memo(({ data, count = 6 }) => {
  return data.map((item) => {
    let img = tagsData.find((e) => e.name === item)?.value ?? false;
    return img ? (
      <a className="tag-edit">
        <img src={img} />
      </a>
    ) : null;
  });
});
export default Tags;
