import React, { useCallback, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Textarea from "../../components/UI/Textarea";
import Meta from "../../components/Meta";
import { createNotification } from "../../services/notification";
import { Link } from "react-router-dom";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";

const СreateNotification = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {
      title: "",
      desc: "",
      login: "",
      sex: "",
      order: "",
      birthday: "",
    },
  });

  const data = useWatch({ control });

  const onSubmit = useCallback(async (data) => {
    setLoading(true);
    createNotification(data)
      .then((res) => {
        NotificationManager.success(t("Уведомления успешно отправлены"));
        reset({
          title: "",
          desc: "",
          login: "",
          sex: "",
          order: "",
          birthday: "",
        });
      })
      .catch((err) => {
        NotificationManager.error(t("Ошибка при отправке уведомлений"));
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <Meta title="Создать уведомление" />

      <div>
        <Link
          to="/notifications"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h3>Создать уведомление</h3>
        <Button isLoading={loading} disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          Отправить
        </Button>
      </div>

      <Row>
        <Col md={6}>
          <Card className="h-100" body>
            <Row>
              <Col md={12}>
                <div className="mb-3">
                  <Input
                    label="Заголовок"
                    type="text"
                    placeholder="Введите заголовок"
                    name="title"
                    errors={errors}
                    register={register}
                    validation={{
                      required: "Обязательное поле",
                    }}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <Textarea
                    label="Текст"
                    name="desc"
                    placeholder="Введите текст"
                    rows={4}
                    onChange={(e) => setValue("desc", e)}
                    register={register}
                  />
                </div>
              </Col>
              <Col md={12}>
                <Input
                  label="Email \ Номер телефона"
                  type="text"
                  name="login"
                  placeholder="Введите email или номер телефона"
                  errors={errors}
                  register={register}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="h-100" body>
            <Form.Label className="fw-7 px-1">Пол</Form.Label>
            <div className="d-flex">
              <Form.Check className="me-3">
                <Form.Check.Input
                  type="radio"
                  id="sex-all"
                  value=""
                  className="btn-sm"
                  {...register("sex")}
                />
                <Form.Check.Label htmlFor="sex-all" className="ms-1 fs-09">
                  Все
                </Form.Check.Label>
              </Form.Check>
              <Form.Check className="me-3">
                <Form.Check.Input
                  type="radio"
                  value="man"
                  id="sex-man"
                  className="btn-sm"
                  {...register("sex")}
                />
                <Form.Check.Label htmlFor="sex-man" className="ms-1 fs-09">
                  Мужской
                </Form.Check.Label>
              </Form.Check>
              <Form.Check className="me-3">
                <Form.Check.Input
                  type="radio"
                  id="sex-woman"
                  value="woman"
                  className="btn-sm"
                  {...register("sex")}
                />
                <Form.Check.Label htmlFor="sex-woman" className="ms-1 fs-09">
                  Женский
                </Form.Check.Label>
              </Form.Check>
            </div>
            <Form.Label className="fw-7 px-1 mt-3">Заказ</Form.Label>
            <div className="d-flex">
              <Form.Check className="me-3">
                <Form.Check.Input
                  type="radio"
                  id="order-all"
                  value=""
                  className="btn-sm"
                  {...register("order")}
                />
                <Form.Check.Label htmlFor="order-all" className="ms-1 fs-09">
                  Все
                </Form.Check.Label>
              </Form.Check>
              <Form.Check className="me-3">
                <Form.Check.Input
                  type="radio"
                  id="order-1"
                  className="btn-sm"
                  value="yes"
                  {...register("order")}
                />
                <Form.Check.Label htmlFor="order-1" className="ms-1 fs-09">
                  Заказали
                </Form.Check.Label>
              </Form.Check>
              <Form.Check className="me-3">
                <Form.Check.Input
                  type="radio"
                  id="order-2"
                  value="no"
                  className="btn-sm"
                  {...register("order")}
                />
                <Form.Check.Label htmlFor="order-2" className="ms-1 fs-09">
                  Не заказывали
                </Form.Check.Label>
              </Form.Check>
            </div>
            <Form.Label className="fw-7 px-1 mt-3">День рождение</Form.Label>
            <div className="d-flex">
              <Form.Check className="me-3">
                <Form.Check.Input
                  type="checkbox"
                  id="birthday"
                  className="btn-sm"
                  {...register("birthday")}
                />
                <Form.Check.Label htmlFor="birthday" className="ms-1 fs-09">
                  В день рождения
                </Form.Check.Label>
              </Form.Check>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default СreateNotification;
