import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getCategories = async (data) => {
  const response = await $authApi.get(apiRoutes.CATEGORIES, {
    params: data,
  });
  return response?.data;
};

const getCategory = async (id) => {
  const response = await $authApi.get(apiRoutes.CATEGORY, {
    params: {
      id,
    },
  });

  return response?.data;
};

const createCategory = async (data) => {
  const response = await $authApi.post(apiRoutes.CATEGORIES, data);
  return response?.data;
};

const editCategory = async (data) => {
  const response = await $authApi.post(apiRoutes.CATEGORY_EDIT, data);
  return response?.data;
};

const deleteCategory = async (ids) => {
  const response = await $authApi.delete(apiRoutes.CATEGORIES, {
    data: { ids },
  });
  return response?.data;
};

const deleteCategoryAll = async () => {
  const response = await $authApi.delete(apiRoutes.CATEGORIES_DELETE_ALL);
  return response?.data;
};

const addCategoryParam = async (data) => {
  const response = await $authApi.post(apiRoutes.CATEGORY_ADD_PARAM, data);
  return response?.data;
};

const deleteCategoryParam = async (id) => {
  const response = await $authApi.delete(apiRoutes.CATEGORY_DELETE_PARAM, {
    data: { id },
  });
  return response?.data;
};


export {
  addCategoryParam,
  deleteCategoryParam,
  getCategories,
  getCategory,
  createCategory,
  editCategory,
  deleteCategory,
  deleteCategoryAll
};
