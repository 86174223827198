import React, { memo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { IoTrashOutline } from "react-icons/io5";
import { getImageURL } from "../helpers/image";

const PreviewImages = memo(
  ({
    col = 6,
    medias,
    onDelete,
    deleteFile = true,
    type = "blog",
    main = false,
    onMain,
  }) => {
    const onButtonClickDelete = (id) => {
      if (Array.isArray(medias) && medias.length > 0) {
        let newMedias = medias.filter((e) => e.id != id);
        let newDelete = medias.find((e) => e.id === id);
        onDelete && onDelete({ medias: newMedias, delete: newDelete });
      } else {
        onDelete && onDelete({ medias: [], delete: medias });
      }
    };

    return medias && Array.isArray(medias) && medias.length > 0 ? (
      <div>
        <Row>
          {medias.length > 0 &&
            medias.map((e, index) => (
              <Col key={e.id} xs={col} md={col} className="my-2">
                <div className="position-relative">
                  <img
                    src={getImageURL({
                      path: e.media,
                      size: "full",
                      type,
                    })}
                    className={"upload-box-img multiple"}
                  />
                  <div className="d-flex position-absolute flex-row justify-content-between tool-image">
                    <div>
                      {main && (
                        <Form.Check>
                          <Form.Check.Input
                            type="radio"
                            name="main"
                            defaultChecked={e.main || index === 0}
                            onChange={() => onMain(e.id)}
                          />
                        </Form.Check>
                      )}
                    </div>
                    {deleteFile && (
                      <a className="btn btn-sm btn-danger tool-btn">
                        <IoTrashOutline
                          onClick={() => onButtonClickDelete(e.id)}
                        />
                      </a>
                    )}
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      </div>
    ) : medias && medias.length > 0 ? (
      <div>
        <Row>
          <Col xs={col} md={col} className="my-2">
            <div className="position-relative">
              <img
                src={getImageURL({
                  path: medias,
                  size: "full",
                  type,
                })}
                className={"upload-box-img multiple"}
              />
              {deleteFile && (
                <div className="d-flex position-absolute flex-row justify-content-end tool-image">
                  <a className="btn btn-sm btn-danger tool-btn">
                    <IoTrashOutline onClick={() => onButtonClickDelete(0)} />
                  </a>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    ) : null;
  }
);

export default PreviewImages;
