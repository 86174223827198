const modulePrice = (value, type = true) => {
  if (!value && !type) {
    return 0 + "\u00A0₽";
  }
  value = parseInt(value).toLocaleString() + "\u00A0₽";
  if (type == "month") {
    value = value + "/мес";
  } else if (type == "free") {
    value = "Бесплатно";
  } else if (type == "soon") {
    value = "Скоро";
  }
  return value;
};

// const moduleRatePrice = ({ module, relationModule, brand }) => {
//   let prices =
//     module?.price instanceof Object && brand?.relationRate?.rate?.name
//       ? module.price[brand.relationRate.rate.name]
//       : module.price;

//   let month = Array.isArray(prices)
//     ? prices.find((e) => e.type == "month")?.price ?? 0
//     : false;
//   let one = Array.isArray(prices)
//     ? prices.find((e) => e.type == "one")?.price ?? 0
//     : false;

//   let status = !!relationModule?.end;
//   let value =
//     relationModule?.price > 0
//       ? relationModule.price
//       : relationModule?.type && Array.isArray(prices)
//         ? prices.find((e) => e.type == relationModule.type)?.price
//         : 0;

//   let free = false;

//   if (!month && !one) {
//     free = true;
//   }

//   if (month > 0) {
//     month = "от " + modulePrice(month, "month");
//   } else {
//     month = modulePrice(0, "free");
//   }

//   if (one > 0) {
//     one = modulePrice(one, "one");
//   } else {
//     one = modulePrice(0, "free");
//   }

//   let text = status ? "Подключено" : month ?? one ?? 0;

//   return { month, one, status, value, text, free };
// };

const moduleRatePrice = ({ module, relationModule, brand }) => {
  // Получаем настройки для данного модуля
  const moduleSettings = brand?.relationRate?.rate?.name ? brand.relationRate.rate.name : null;
  const settings = moduleSettings && module?.price instanceof Object ? module.price[moduleSettings] : module.price;

  // Определяем цены для разового и ежемесячного доступа
  let one = 0;
  let month = 0;

  // Если settings - массив, то это настройки для конкретного модуля
  if (Array.isArray(settings)) {
    one = settings.find(item => item.type === 'one')?.price ?? 0;
    month = settings.find(item => item.type === 'month')?.price ?? 0;
  } else if (typeof settings === 'number') {
    // Если settings - число, то это цена для разового доступа
    one = settings;
  }

  // Определяем статус модуля
  const status = !!relationModule?.end;

  // Определяем цену модуля
  let value = 0;
  if (relationModule?.price > 0) {
    value = relationModule.price;
  } else if (relationModule?.type && Array.isArray(settings)) {
    value = settings.find(item => item.type === relationModule.type)?.price ?? 0;
  }

  // Определяем, является ли модуль бесплатным
  const free = !month && !one;

  // Форматируем цены
  if (month > 0) {
    month = "от " + modulePrice(month, "month");
  } else {
    month = modulePrice(0, "free");
  }

  if (one > 0) {
    one = modulePrice(one, "one");
  } else {
    one = modulePrice(0, "free");
  }

  // Определяем текст для отображения
  const text = status ? "Подключено" : month ?? one ?? 0;

  return { month, one, status, value, text, free };
};

const moduleBgColor = {
  month: "primary",
  one: "primary",
  soon: "danger",
  free: "secondary",
};

export { modulePrice, moduleRatePrice, moduleBgColor };
