import React, { useState } from "react";
import { Card, Col, Row, Badge } from "react-bootstrap";
import { IoReloadOutline } from "react-icons/io5";
import Button from "../../../components/UI/Button";
import Input from "../../../components/UI/Input";
// import Select from "../../../components/UI/Select";
import CustomModal from "../../../components/utils/CustomModal";
import { generateKey } from "../../../helpers/all";
import { useForm, useWatch } from "react-hook-form";

const ApiForm = ({ data, onSubmit }) => {
  const [modalUpdate, setModalUpdate] = useState({
    show: false,
    data: false,
  });

  const {
    control,
    setValue,
    formState: { isValid },
    handleSubmit,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: data,
  });

  const form = useWatch({ control });

  return (
    <Card body className="mb-4">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <span>
          <h5>Настройки</h5>
        </span>
        <span>
          {data?.relationModule?.options?.status ? (
            <Badge bg="success">Активен</Badge>
          ) : (
            <Badge bg="secondary">Отключен</Badge>
          )}
        </span>
      </div>
      {/* <div className="mb-4">
        Для подключения, нужно: <br />
        1.{" "}
        <a
          className="fw-6"
          href="https://yooapp.ru/wordpress"
          target="_blank"
          download
        >
          Скачать плагин
        </a>
        <br />
        2. Активируйте плагин и установите ключ в настройках плагина.
        <br />
        3. Запустите выгрузку категорий и товаров.
      </div> */}
      <Row>
        <Col lg={12}>
          <div className="mb-3">
            <Input
              readOnly={false}
              label="Ключ"
              placeholder="Здесь будет указан ключ"
              value={form?.relationModule?.options?.token}
              rightIcon={() => <IoReloadOutline size={22} />}
              rightIconClick={() => setModalUpdate({ index: 0, show: true })}
            />
          </div>
        </Col>
      </Row>

      <div className="d-flex justify-content-end">
        <Button isValid={isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить изменения
        </Button>
      </div>

      <CustomModal
        title="Подтвердите действие"
        show={modalUpdate.show}
        setShow={(e) => setModalUpdate({ index: false, show: e, data: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() =>
                setModalUpdate({
                  index: false,
                  show: !modalUpdate.show,
                  data: false,
                })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              onClick={() => {
                setValue("relationModule.options.token", generateKey(50));
                setModalUpdate({
                  index: false,
                  show: !modalUpdate.show,
                  data: false,
                });
              }}
            >
              Обновить
            </Button>
          </>
        }
      >
        Вы точно хотите обновить ключ? Группа или чат будут отключены от
        системы.
      </CustomModal>
    </Card>
  );
};
export default ApiForm;
