import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getStorages = async (data) => {
  const response = await $authApi.get(apiRoutes.STORAGES, {
    params: data,
  });

  return response?.data;
};

const getStorage = async (id) => {
  const response = await $authApi.get(apiRoutes.STORAGE, {
    params: {
      id,
    },
  });

  return response?.data;
};

const createStorage = async (data) => {
  const response = await $authApi.post(apiRoutes.STORAGE_CREATE, data);
  return response?.data;
};

const editStorage = async (data) => {
  const response = await $authApi.put(apiRoutes.STORAGES, data);
  return response?.data;
};

const deleteStorage = async (ids) => {
  const response = await $authApi.delete(apiRoutes.STORAGES, {
    data: { ids },
  });
  return response?.data;
};

export { getStorages, getStorage, createStorage, editStorage, deleteStorage };
