import { useCallback, useLayoutEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoRefreshOutline, IoSwapHorizontalOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import Button from "../../../components/UI/Button";
import Input from "../../../components/UI/Input";
import { getStatistics } from "../../../services/statistic";
import { getEprProduct } from "../../../services/upload";
import Select from "../../../components/UI/Select";
import { useSelector } from "react-redux";

const FrontpadForm = ({ data, onSubmit }) => {
  const brand = useSelector((state) => state?.brand?.active);

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: data,
  });

  const form = useWatch({ control });

  const [product, setProduct] = useState(false);

  const [statistic, setStatistic] = useState({
    loading: true,

    uploadProducts: 0,
    products: 0,
  });

  useLayoutEffect(() => {
    getStatistics({ sync: true, product: true })
      .then((res) => setStatistic({ ...statistic, ...res, loading: false }))
      .catch(() => setStatistic({ ...statistic, loading: false }));
  }, [product]);

  const clickGetErpProduct = useCallback(() => {
    setProduct(true);
    getEprProduct(form).finally(() => setProduct(false));
  }, [form]);

  return (
    <>
      <Card body className="mb-4">
        <h5 className="mb-4">Настройки</h5>
        <Row>
          <Col md={5}>
            <div className="mb-3">
              <Input
                label="Токен"
                placeholder="Введите токен"
                name="relationModule.options.token"
                errors={errors}
                defaultValue={form?.relationModule?.options?.token}
                register={register}
                validation={{ required: "Обязательное поле" }}
              />
              <small className="text-muted fs-08">
                Скопируйте токен в настройках api FrontPad.
              </small>
            </div>
          </Col>
          <Col md={5}>
            <div className="mb-3">
              <Input
                label="Идентификатор доставки"
                placeholder="Введите id"
                name="relationModule.options.deliveryApiId"
                errors={errors}
                defaultValue={form?.relationModule?.options?.deliveryApiId}
                register={register}
              />
              <small className="text-muted fs-08">
                Создайте товар во FrontPad с изменяемой суммой для доставки и
                укажите идентификатор.
              </small>
            </div>
          </Col>
          <Col md={2}>
            <div className="mb-3">
              <Select
                label="Статус"
                onClick={(e) => setValue("status", e.value)}
                value={form?.relationModule?.status}
                data={[
                  { title: "Активно", value: 1 },
                  { title: "Отключено", value: 0 },
                ]}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <h5 className="mt-3">Уведомления (callback)</h5>
          <Col lg={12}>
            <div className="mb-4 mt-4">
              <Input
                label="Ссылка для уведомлений (callback)"
                readOnly={false}
                defaultValue={`${
                  process.env.REACT_APP_WEBHOOK_URL
                }/accounting/${brand?.id ?? ""}/`}
              />
              <p className="text-muted fs-07 mt-1">
                Данную ссылку нужно указать в настройках интеграции.
              </p>
            </div>
          </Col>
        </Row>
        <h5 className="mt-3 mb-4">Типы оплат</h5>
        <Row>
          <Col md={3}>
            <div className="mb-3">
              <Input
                label="Банковской картой"
                placeholder="Введите id"
                name="relationModule.options.payment.card"
                errors={errors}
                defaultValue={form?.relationModule?.options?.payment?.card}
                register={register}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-3">
              <Input
                label="Наличными"
                placeholder="Введите id"
                name="relationModule.options.payment.cash"
                errors={errors}
                defaultValue={form?.relationModule?.options?.payment?.cash}
                register={register}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-3">
              <Input
                label="Онлайн"
                placeholder="Введите id"
                name="relationModule.options.payment.online"
                errors={errors}
                defaultValue={form?.relationModule?.options?.payment?.online}
                register={register}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-3">
              <Input
                label="Баллы"
                placeholder="Введите id"
                name="relationModule.options.payment.point"
                errors={errors}
                defaultValue={form?.relationModule?.options?.payment?.point}
                register={register}
              />
            </div>
          </Col>
        </Row>
        <h5 className="mt-3 mb-4">Каналы продаж</h5>
        <Row>
          <Col md={3}>
            <div className="mb-3">
              <Input
                label="Сайт"
                placeholder="Введите id"
                name="relationModule.options.channel.site"
                errors={errors}
                defaultValue={form?.relationModule?.options?.channel?.site}
                register={register}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="mb-3">
              <Input
                label="Приложение"
                placeholder="Введите id"
                name="relationModule.options.channel.app"
                errors={errors}
                defaultValue={form?.relationModule?.options?.channel?.app}
                register={register}
              />
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <Button isValid={isValid} onClick={handleSubmit(onSubmit)}>
            Сохранить изменения
          </Button>
        </div>
      </Card>

      <h5 className="mb-4 fw-7">Синхронизация</h5>
      <Row>
        <Col md={4}>
          <Card body className="mb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-2 fw-6 h6">Товары</h5>
              <Button
                isLoading={product}
                disabled={product || !form?.relationModule?.options?.token}
                onClick={() => clickGetErpProduct()}
                className="btn btn-sm btn-default"
              >
                <IoRefreshOutline size={22} className="text-gray" />
              </Button>
            </div>
            <h2 className="m-0 d-flex align-items-center">
              {statistic.products}
            </h2>
            <div className="mt-2">
              <Link
                to="/upload/products"
                disabled={product || !form?.relationModule?.options?.token}
                className="btn-sm btn-primary-outline"
              >
                Перейти к списку
              </Link>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default FrontpadForm;
