import React, { memo, useCallback, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IoChevronDownOutline } from "react-icons/io5";
import Input from "./Input";

const Select = memo(
  ({
    value,
    title,
    search,
    data,
    label,
    className,
    classNameContainer,
    classNameContainerMenu,
    onClick,
    placeholder,
    disabled,
  }) => {
    const [searchData, setSearchData] = useState([]);
    const { t } = useTranslation();
    const onSearch = useCallback(
      (text) => {
        if (text.length > 0) {
          let newArray = [];
          let newText = text.toLocaleLowerCase().trim();

          data.map((item) => {
            if (item.title.toLocaleLowerCase().trim().includes(newText)) {
              newArray.push(item);
            }
          });

          setSearchData(newArray);
        } else {
          setSearchData([]);
        }
      },
      [data]
    );

    const onClickFunc = useCallback((e) => {
      if (e) {
        onClick && onClick(e);
      }
    }, []);

    const CustomToggle = React.forwardRef(({ onClick }, ref) => {
      let item = data
        ? data.find(
            (e) =>
              (e.value ?? "") === (value ?? "") ||
              (e.title ?? "") === (value ?? "")
          )
        : false;
      let titleFind = item?.title ?? title ?? placeholder ?? "Выберите элемент";

      return (
        <a
          disabled={disabled}
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
          className={
            "select d-flex align-items-center justify-content-between " +
            className
          }
        >
          {label && <span className="select-label">{t(label)}</span>}
          <span
            className={
              "text-transfer d-flex align-items-center flex-row " +
              (data &&
              !data.find(
                (e) =>
                  (e.value ?? "") === (value ?? "") ||
                  (e.title ?? "") === (value ?? "")
              )
                ? "text-muted"
                : "")
            }
          >
            {item?.image && (
              <img src={item.image} height={20} width={20} className="me-2" />
            )}
            {t(titleFind)}
          </span>
          <span className="ms-2">
            <IoChevronDownOutline size={18} />
          </span>
        </a>
      );
    });

    return (
      <Dropdown className={classNameContainer}>
        <Dropdown.Toggle as={CustomToggle} />
        <Dropdown.Menu className={classNameContainerMenu}>
          {data && search && (
            <div className="mb-2 bg-body position-sticky top-0">
              <Input
                autofocus
                placeholder={t("Поиск...")}
                onChange={(e) => onSearch(e)}
              />
            </div>
          )}
          {searchData?.length > 0
            ? searchData.map((e, index) => (
                <Dropdown.Item
                  disabled={e.disabled}
                  key={index}
                  active={
                    (e.value ?? "") === (value ?? "") ??
                    (e.title ?? "") === (value ?? "") ??
                    e.main
                  }
                  onClick={() => onClickFunc(e)}
                  className="d-flex align-items-center flex-row"
                >
                  {e?.image && (
                    <img
                      src={e?.image}
                      height={20}
                      width={20}
                      className="me-2"
                    />
                  )}
                  {t(e.title)}
                </Dropdown.Item>
              ))
            : data?.length > 0 &&
              data.map((e, index) => (
                <Dropdown.Item
                  key={index}
                  disabled={e.disabled}
                  active={
                    (e.value ?? "") === (value ?? "") ??
                    (e.title ?? "") === (value ?? "") ??
                    e.main
                  }
                  onClick={() => onClickFunc(e)}
                  className="d-flex align-items-center flex-row"
                >
                  {e?.image && (
                    <img
                      src={e.image}
                      height={20}
                      width={20}
                      className="me-2"
                    />
                  )}
                  <div className="flex-column">
                    <div>{t(e.title)}</div>
                    {e?.subTitle && (
                      <div className="fs-07 text-muted">{e.subTitle}</div>
                    )}
                  </div>
                </Dropdown.Item>
              ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
);

export default Select;
