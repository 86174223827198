import React, { useCallback } from "react";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import CustomEditor from "../../components/editor/CustomEditor";
import { createDocument } from "../../services/document";

const DocumentCreate = () => {
  const navigate = useNavigate();
  const brand = useSelector((state) => state.brand.active);
  const {
    control,
    register,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const data = useWatch({ control });

  const onSubmit = useCallback(() => {
    createDocument(data)
      .then(() => {
        NotificationManager.success("Документ успешно создан");
        navigate(-1);
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      });
  }, [data]);

  return (
    <>
      <Meta title="Создать документ" />
      <div className="d-flex justify-content-between align-items-center">
        <Link
          to="/options/documents"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
        <Button disabled={!isValid} onClick={() => onSubmit()}>
          Сохранить изменения
        </Button>
      </div>
      <h3 className="mb-4">Создать документ</h3>
      <p className="text-muted mb-4 fs-09">
        <a
          href={`https://lk.yooapp.ru/docs/${brand.id}/${data.link}`}
          target="_blank"
        >
          https://lk.yooapp.ru/docs/{brand.id}/{data.link}
        </a>
      </p>
      <div className="mb-3">
        <Input
          label="Название"
          name="title"
          errors={errors}
          register={register}
          validation={{
            required: "Обязательное поле",
          }}
        />
      </div>
      <div className="mb-3">
        <Input
          label="Идентификатор"
          name="link"
          errors={errors}
          register={register}
          validation={{
            required: "Обязательное поле",
          }}
        />
      </div>
      <CustomEditor
        content={data.content}
        onChange={(e) => setValue("content", e)}
      />
    </>
  );
};

export default DocumentCreate;
