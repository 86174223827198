import React, { useCallback } from "react";
import { Badge, Card, Col, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoSaveOutline, IoTrashOutline } from "react-icons/io5";
import DragDropFile from "../../../components/DragDropFile";
import Button from "../../../components/UI/Button";
import Input from "../../../components/UI/Input";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import PreviewImages from "../../../components/PreviewImages";
import { editModuleBrand } from "../../../services/module";

const BrandForm = ({ data }) => {
  const brand = useSelector((state) => state?.brand?.active);

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues:
      data && !Array.isArray(data)
        ? data
        : {
            title: "",
          },
  });

  const form = useWatch({ control });

  const onSubmit = useCallback((data) => {
    var formData = new FormData();

    formData.append("dataParse", JSON.stringify(data));

    if (data?.logo) {
      for (let file of data.logo) {
        formData.append("logo", file);
      }
    }
    if (data?.favicon) {
      for (let file of data.favicon) {
        formData.append("favicon", file);
      }
    }

    editModuleBrand(formData)
      .then(() => {
        NotificationManager.success("Брендирование успешно обновлено");
      })
      .catch(
        (err) => err && NotificationManager.error("Ошибка при сохранении")
      );
  }, []);

  return (
    <Card body className="mb-4">
      <h3 className="mb-4 fw-7">Настройки</h3>
      <Row>
        <Col md={6}>
          <div className="mb-3">
            <DragDropFile
              col={12}
              title="Выберите логотип"
              file={form.logo}
              onChange={(e) => setValue("logo", e)}
              onDelete={(e) => setValue("logo", e.file)}
            />
          </div>
          <div className="mb-3">
            <PreviewImages
              medias={form?.relationModule?.options?.logo}
              type="all/brand/logo"
              col={12}
              onDelete={(e) => {
                setValue("form.relationModule.options.logo", e.media);
                let newArrayDelete =
                  data?.delete?.length > 0
                    ? [...data.delete, e.delete]
                    : [e.delete];
                setValue("delete", newArrayDelete);
              }}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <DragDropFile
              col={12}
              title="Выберите фавикон"
              file={form.favicon}
              maxAspect={1}
              onChange={(e) => setValue("favicon", e)}
              onDelete={(e) => setValue("favicon", e.file)}
            />
          </div>
          <div className="mb-3">
            <PreviewImages
              medias={form?.relationModule?.options?.favicon}
              type="all/brand/favicon"
              col={12}
              onDelete={(e) => {
                setValue("form.relationModule.options.favicon", e.media);
                let newArrayDelete =
                  data?.delete?.length > 0
                    ? [...data.delete, e.delete]
                    : [e.delete];
                setValue("delete", newArrayDelete);
              }}
            />
          </div>
        </Col>
        <Col md={12}>
          <Row>
            <Col md={6}>
              <div className="mb-4">
                <Input
                  className="mb-4"
                  label="Название"
                  name="relationModule.options.title"
                  errors={errors}
                  defaultValue={form?.relationModule?.options?.title}
                  register={register}
                  validation={{ required: "Обязательное поле" }}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-4">
                <Input
                  className="w-100"
                  label="Введите новый домен"
                  name="relationModule.options.domain"
                  defaultValue={form?.relationModule?.options?.domain}
                  errors={errors}
                  register={register}
                  validation={{
                    pattern: {
                      value: /^([a-z\d]+(-[a-z\d]+)*\.)+[a-z]{2,}$/i,
                      message: "Неверный формат домена",
                    },
                  }}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="d-flex justify-content-end">
        <Button isValid={isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить изменения
        </Button>
      </div>
    </Card>
  );
};
export default BrandForm;
